define("goodcity/controllers/review_offer/receive", ["exports", "ember", "goodcity/mixins/async_tasks"], function (exports, _ember, _goodcityMixinsAsync_tasks) {
  exports["default"] = _ember["default"].Controller.extend(_goodcityMixinsAsync_tasks["default"], {
    queryParams: ["state"],
    state: "expecting",
    items: _ember["default"].computed.filterBy("model.items", "state", "accepted"),

    actions: {
      addItem: function addItem() {
        var _this = this;

        var offer = this.get("model");
        var defaultDonorCondition = this.get("store").peekAll("donorCondition").filterBy("name", "Lightly Used").get("firstObject");
        var item = this.get("store").createRecord("item", {
          offer: offer,
          donorCondition: defaultDonorCondition,
          state: "accepted"
        });
        this.runTask(item.save().then(function (item) {
          _this.transitionToRoute("search_label", item.id, {
            queryParams: { isUnplannedPackage: true }
          });
        }));
      }
    }
  });
});