define("goodcity/components/twilio-make-call", ["exports", "ember", "goodcity/utils/ajax-promise", "goodcity/config/environment", "lodash"], function (exports, _ember, _goodcityUtilsAjaxPromise, _goodcityConfigEnvironment, _lodash) {
  exports["default"] = _ember["default"].Component.extend({
    mobile: null,
    offerId: null,
    twilioToken: null,
    activeCall: false,
    donorName: null,
    isCordovaApp: _goodcityConfigEnvironment["default"].cordova.enabled,
    hidden: _ember["default"].computed.empty("mobile"),
    currentUserId: _ember["default"].computed.alias("session.currentUser.id"),
    internetCallStatus: {},
    logger: _ember["default"].inject.service(),

    outputSources: {},

    hasTwilioSupport: _ember["default"].computed("hasTwilioBrowserSupport", "isCordovaApp", function () {
      return this.get("isCordovaApp") || this.get("hasTwilioBrowserSupport");
    }),

    hasTwilioBrowserSupport: _ember["default"].computed(function () {
      var hasWebRtcSupport = !!window.webkitRTCPeerConnection; // twilio js doesn't use mozRTCPeerConnection
      var hasFlashSupport = !!(navigator.plugins["Shockwave Flash"] || window.ActiveXObject && new window.ActiveXObject("ShockwaveFlash.ShockwaveFlash"));

      return hasWebRtcSupport || hasFlashSupport;
    }),

    twilio_device: _ember["default"].computed(function () {
      return this.get("isCordovaApp") ? Twilio.TwilioVoiceClient : Twilio.Device;
    }),

    initTwilioDeviceBindings: function initTwilioDeviceBindings() {
      var twilioToken = this.get("twilioToken");

      if (!twilioToken) {
        console.log("TwilioToken empty. Cannot proceed with initialization.");
        return;
      }
      this.get("isCordovaApp") ? this.initTwilioMobileDevice() : this.initTwilioBrowser();
    },

    initTwilioMobileDevice: function initTwilioMobileDevice() {
      var _this = this;

      var twilioDevice = this.get("twilio_device");
      var twilioToken = this.get("twilioToken");

      // ensure mobile device permissions are granted prior to library initialization
      var permissions = _lodash["default"].get(window, "cordova.plugins.permissions");
      permissions.requestPermission(permissions.RECORD_AUDIO, function (status) {
        if (status.hasPermission) {
          twilioDevice.initialize(twilioToken);
        } else {
          console.log("Audio permissions not granted. Not instantiating Twilio SDK.");
        }
      }, function () {
        console.log("Audio permissions not granted. Not instantiating Twilio SDK.");
      });

      twilioDevice.error(function () {
        if (!_this.get("isDestroying")) {
          _this.set("activeCall", false);
          _this.get("internetCallStatus").set("activeCall", false);
        }
        _this.get("twilio_device").disconnect();
      });

      twilioDevice.calldiddisconnect(function () {
        _this.set("activeCall", false);
        _this.get("internetCallStatus").set("activeCall", false);
      });
    },

    initTwilioBrowser: function initTwilioBrowser() {
      var _this2 = this;

      var twilioDevice = this.get("twilio_device");
      var twilioToken = this.get("twilioToken");

      twilioDevice.setup(twilioToken, { debug: true });
      twilioDevice.on("error", function () {
        if (!_this2.get("isDestroying")) {
          _this2.set("activeCall", false);
          _this2.get("internetCallStatus").set("activeCall", false);
        }
        _this2.get("twilio_device").disconnectAll();
      });

      twilioDevice.on("disconnect", function () {
        _this2.set("activeCall", false);
        _this2.get("internetCallStatus").set("activeCall", false);
      });
    },

    actions: {
      makeCall: function makeCall() {
        var params = this.get("offerId") + "#" + this.get("currentUserId");
        var twilioToken = this.get("twilioToken");
        var twilioDevice = this.get("twilio_device");
        this.set("activeCall", true);
        this.get("internetCallStatus").set("activeCall", true);
        this.get("isCordovaApp") ? twilioDevice.call(twilioToken, { To: params }) : twilioDevice.connect({ To: params });
      },

      hangupCall: function hangupCall() {
        var twilioDevice = this.get("twilio_device");
        this.set("activeCall", false);
        this.get("internetCallStatus").set("activeCall", false);
        this.get("isCordovaApp") ? twilioDevice.disconnect() : twilioDevice.disconnectAll();
      }
    },

    didInsertElement: function didInsertElement() {
      var _this3 = this;

      if (this.get("hasTwilioSupport")) {
        this._super();
        new _goodcityUtilsAjaxPromise["default"]("/twilio_outbound/generate_call_token", "GET", this.get("session.authToken")).then(function (data) {
          _this3.set("twilioToken", data["token"]);
          _this3.initTwilioDeviceBindings();
          _this3.get("internetCallStatus").set("twilio_device", _this3.get("twilio_device"));
          _this3.get("internetCallStatus").set("donorName", _this3.get("donorName"));
        });
      }
    }
  });
});