define("goodcity/routes/review_offer", ["exports", "ember", "goodcity/routes/authorize", "goodcity/utils/ajax-promise", "goodcity/models/shareable"], function (exports, _ember, _goodcityRoutesAuthorize, _goodcityUtilsAjaxPromise, _goodcityModelsShareable) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    backLinkPath: _ember["default"].computed.localStorage(),
    sharingService: _ember["default"].inject.service(),

    beforeModel: function beforeModel() {
      var previousRoutes = this.router.router.currentHandlerInfos;
      var previousRoute = previousRoutes && previousRoutes.pop().name;
      if (previousRoute === "search") {
        this.set("backLinkPath", previousRoute);
      } else {
        this.set("backLinkPath", "dashboard");
      }
    },

    hasLoadedAssociations: function hasLoadedAssociations(offer) {
      var items = offer.get("items");
      return items && items.length > 0;
    },

    loadIfAbsent: function loadIfAbsent(offerId) {
      var _this2 = this;

      var _this = this;
      var offer = this.get("store").peekRecord("offer", offerId);

      if (offer && this.hasLoadedAssociations(offer)) {
        return offer;
      }

      return new _goodcityUtilsAjaxPromise["default"]("/offers/" + offerId, "GET", _this.get("session.authToken"), { exclude_messages: "true" }).then(function (data) {
        _this2.store.pushPayload(data);
        return _this2.store.peekRecord("offer", offerId);
      });
    },

    model: function model() {
      var offerId, _ref, _ref2, offer;

      return regeneratorRuntime.async(function model$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            offerId = this.modelFor("offer").get("id");
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(_ember["default"].RSVP.all([this.loadIfAbsent(offerId), this.get("sharingService").loadShareable(_goodcityModelsShareable.SHAREABLE_TYPES.OFFER, offerId)]));

          case 3:
            _ref = context$1$0.sent;
            _ref2 = _slicedToArray(_ref, 1);
            offer = _ref2[0];
            return context$1$0.abrupt("return", offer);

          case 7:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    afterModel: function afterModel(model) {
      var itemIds;
      return regeneratorRuntime.async(function afterModel$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.store.query("message", {
              messageable_type: "Offer",
              messageable_id: model.get("id")
            }));

          case 2:
            itemIds = model.get("items").getEach("id");

            if (!itemIds.length) {
              context$1$0.next = 6;
              break;
            }

            context$1$0.next = 6;
            return regeneratorRuntime.awrap(this.store.query("message", {
              messageable_type: "Item",
              messageable_id: itemIds
            }));

          case 6:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("displayOfferOptions", false);
      controller.set("displayCompleteReceivePopup", false);
      controller.set("backLinkPath", this.get("backLinkPath"));
    }
  });
});