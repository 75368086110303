define("goodcity/controllers/donors/new", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    offerId: _ember["default"].computed.alias("model.id"),
    selectedDistrict: null,
    firstName: null,
    lastName: null,
    email: null,
    mobilePhone: null,
    otherPhone: null,
    receiveEmail: false,
    districts: _ember["default"].computed("model", function () {
      return this.store.peekAll("district").sortBy("name");
    }),

    userTitle: _ember["default"].computed("selectedTitle", function () {
      var userTitle = this.get("model.createdBy.title");
      var titles = this.get("titles");

      if (userTitle) {
        var filteredUserTitle = titles.filter(function (title) {
          return userTitle === title.id;
        });
        return {
          name: filteredUserTitle[0].name.string,
          id: userTitle
        };
      }
      return {
        name: titles.get("firstObject.name").string,
        id: "Mr"
      };
    }),

    selectedTitle: _ember["default"].computed("userTitle", function () {
      return {
        name: this.get("userTitle.name"),
        id: this.get("userTitle.id")
      };
    }),

    titles: _ember["default"].computed(function () {
      return [{
        name: "Mr",
        id: "Mr"
      }, {
        name: "Mrs",
        id: "Mrs"
      }, {
        name: "Miss",
        id: "Miss"
      }, {
        name: "Ms",
        id: "Ms"
      }];
    }),

    actions: {
      toggleReceiveEmail: function toggleReceiveEmail() {
        this.toggleProperty("receiveEmail");
      },

      resetInputs: function resetInputs() {
        this.set("firstName", null);
        this.set("lastName", null);
        this.set("email", null);
        this.set("mobilePhone", null);
        this.set("receiveEmail", null);
        this.set("otherPhone", null);
      },

      saveDonorAndOffer: function saveDonorAndOffer() {
        var offer = this.get("model");
        var firstName = this.get("firstName");
        var lastName = this.get("lastName");
        var email = this.get("email");
        var mobile = this.get("mobilePhone") ? "+852" + this.get("mobilePhone") : null;
        var receiveEmail = this.get("receiveEmail");
        var otherPhone = this.get("otherPhone") ? "+852" + this.get("otherPhone") : null;
        var title = this.get("selectedTitle").id;

        var self = this;
        var donor = this.store.createRecord("user", {
          firstName: firstName,
          lastName: lastName,
          email: email,
          mobile: mobile,
          receiveEmail: receiveEmail,
          otherPhone: otherPhone,
          title: title
        });
        donor.save().then(function () {
          offer.set("createdBy", donor);
          offer.save().then(function () {
            self.transitionToRoute("review_offer.donor_details", offer.get("id"));
          });
        });
      }
    }
  });
});