define("goodcity/components/cancel-offer", ["exports", "ember"], function (exports, _ember) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Component.extend({
    hidden: true,
    packageId: null,
    store: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    selectedReason: null,
    invalidReason: false,
    displayUserPrompt: false,

    displayCustomReason: _ember["default"].computed("selectedReason", function () {
      var translation = this.get("i18n");
      return this.get("selectedReason.name") == translation.t("other");
    }),

    cancellationOptions: _ember["default"].computed(function () {
      return this.get("store").peekAll("cancellation_reason").sortBy("id");
    }),

    actions: {
      closeConfirmDialog: function closeConfirmDialog() {
        this.set("displayUserPrompt", false);
      },

      openConfirmDialog: function openConfirmDialog() {
        this.set("displayUserPrompt", true);
      },

      confirmCancelOffer: function confirmCancelOffer() {
        var _this = this;

        var cancelReason = this.get("offerCancelReason") || "";
        var selectedReason = this.get("selectedReason") || this.get("cancellationOptions.firstObject");

        if (this.get("displayCustomReason") && cancelReason.trim().length == 0) {
          this.set("invalidReason", true);
          return false;
        }

        var loadingView = getOwner(this).lookup("component:loading").append();
        var offer = this.get("offer");
        offer.set("cancelReason", cancelReason);
        offer.set("cancellationReason", selectedReason);
        offer.set("state_event", "cancel");
        this.set("offerCancelReason", "");
        offer.set("sharing_expires_at", this.get("stopSharingAt"));

        offer.save()["finally"](function () {
          _this.sendAction("toggleAction");
          loadingView.destroy();
        });
      }
    }
  });
});