define("goodcity/routes/review_item/donor_messages", ["exports", "goodcity/routes/message_base"], function (exports, _goodcityRoutesMessage_base) {
  exports["default"] = _goodcityRoutesMessage_base["default"].extend({
    renderTemplate: function renderTemplate() {
      this.render("message_template", {
        controller: "review_item.donor_messages"
      });
    },

    afterModel: function afterModel() {
      var itemId = this.modelFor("review_item").get("id");
      this.store.query("version", { item_id: itemId, for_item: true });
    }
  });
});