define("goodcity/helpers/date-format", ["exports", "ember"], function (exports, _ember) {

  // Date Format used in App:
  // "12 Feb '16" => "DD MMM 'YY"

  exports["default"] = _ember["default"].Helper.helper(function (value, params) {
    var parseDate = Date.parse(value);

    if (parseDate) {
      return moment(parseDate).format(params.format);
    } else {
      return "";
    }
  });
});