define("goodcity/routes/receive_package", ["exports", "goodcity/routes/authorize"], function (exports, _goodcityRoutesAuthorize) {
  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    printerService: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    packageService: Ember.inject.service(),

    model: function model(params) {
      return this.store.findRecord("package", params.package_id);
    },

    setupPrinterId: function setupPrinterId(controller) {
      var defaultPrinter = this.get("printerService").getDefaultPrinter();
      if (defaultPrinter) {
        controller.set("selectedPrinterId", defaultPrinter.id);
      } else {
        var allAvailablePrinters = this.get("printerService").allAvailablePrinters();
        if (allAvailablePrinters.length) {
          var firstPrinterId = allAvailablePrinters[0].id;
          this.get("printerService").addDefaultPrinter(firstPrinterId);
          controller.set("selectedPrinterId", firstPrinterId);
        }
      }
    },

    setupController: function setupController(controller, model) {
      var valueHkDollar;
      return regeneratorRuntime.async(function setupController$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this._super(controller, model);
            controller.set("package", model);
            controller.resetInputs();
            controller.set("displayInventoryOptions", false);
            controller.set("autoGenerateInventory", true);
            controller.set("inputInventory", false);

            this.setupPrinterId(controller);

            if (!(model.get("isReceived") && model.get("inventoryNumber"))) {
              context$1$0.next = 9;
              break;
            }

            return context$1$0.abrupt("return", controller.redirectToReceiveOffer());

          case 9:
            if (model.get("inventoryNumber")) {
              context$1$0.next = 14;
              break;
            }

            context$1$0.next = 12;
            return regeneratorRuntime.awrap(controller.generateInventoryNumber());

          case 12:
            context$1$0.next = 15;
            break;

          case 14:
            controller.set("inventoryNumber", model.get("inventoryNumber"));

          case 15:
            context$1$0.next = 17;
            return regeneratorRuntime.awrap(this.get("packageService").getItemValuation({
              donorConditionId: model.get("donorCondition.id"),
              grade: model.get("grade"),
              packageTypeId: model.get("packageType.id")
            }));

          case 17:
            valueHkDollar = context$1$0.sent;

            controller.set("valueHkDollar", valueHkDollar.value_hk_dollar);

          case 19:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    resetController: function resetController(controller, isExiting, transition) {
      if (controller.get("package.item") && transition.targetName === "offer.search_label") {
        controller.deleteItem();
      }
    }
  });
});