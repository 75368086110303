define('goodcity/components/districts-territory', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
  exports['default'] = _ember['default'].Component.extend({
    attributeBindings: ['selected_id'],
    classNames: ['district-selection'],
    currentSelected: { id: null },
    selected_id: null,
    i18n: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    currentSelectedObserver: _ember['default'].observer('currentSelected', function () {
      var selectedDistrictId = this.getWithDefault('currentSelected.id');
      if (selectedDistrictId) {
        this.set('selected_id', selectedDistrictId);
      }
    }),

    districtsByTerritory: _ember['default'].computed({
      get: function get() {
        return this.get('store').peekAll('district').sortBy('name');
      },
      set: function set(key, value) {
        return value !== '' ? value : this.get('store').peekAll('district').sortBy('name');
      }
    }),

    allTerritory: _ember['default'].computed(function () {
      return this.get('store').peekAll('territory').sortBy('name');
    }),

    selectDistrictLabel: (0, _emberI18n.translationMacro)("select_district"),

    actions: {
      findDistrictbyTerritory: function findDistrictbyTerritory(territory) {
        var districts = territory ? territory.get('districts').sortBy('name') : '';
        this.set('districtsByTerritory', districts);
      }
    },

    didInsertElement: function didInsertElement() {
      this._super();

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        _ember['default'].$(".radio").click(function () {
          _ember['default'].$(".radio").removeClass('active');
          _ember['default'].$(this).addClass('active');
        });
      });
    }
  });
});