define("goodcity/components/offer-filters-button", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {
  exports["default"] = _ember["default"].Component.extend({
    filterService: _ember["default"].inject.service(),

    offerStateFilters: _ember["default"].computed.alias("filterService.offerStateFilters"),
    hasStateFilters: _ember["default"].computed("offerStateFilters", function () {
      return this.get("offerStateFilters").length > 0;
    }),

    offerTimeRange: _ember["default"].computed.alias("filterService.offerTimeRange"),

    selfReviewFilter: _ember["default"].computed.alias("filterService.selfReviewFilter"),

    hasTimeFilters: _ember["default"].computed("offerTimeRange", function () {
      var _get = this.get("offerTimeRange");

      var preset = _get.preset;
      var after = _get.after;
      var before = _get.before;

      return preset || after || before;
    }),

    presetTimeKeys: _ember["default"].computed(function () {
      return _lodash["default"].keys(this.get("filterService.offerTimeRangePresets"));
    }),

    actions: {
      redirectTofilters: function redirectTofilters(queryParam) {
        var offerFilter = {};
        offerFilter[queryParam] = true;
        this.get("router").transitionTo("offers_filters", {
          queryParams: offerFilter
        });
      }
    }
  });
});