define("goodcity/routes/search_label", ["exports", "goodcity/routes/authorize", "ember"], function (exports, _goodcityRoutesAuthorize, _ember) {
  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    backLinkPath: _ember["default"].computed.localStorage(),

    afterModel: function afterModel(model, transition) {
      var pkgs = model.get("packages");
      if (pkgs && pkgs.length > 0 && (pkgs.get("firstObject.hasAllPackagesDesignated") || pkgs.get("firstObject.hasAllPackagesDispatched"))) {
        transition.abort();
        this.get("messageBox").alert(this.get("i18n").t("designated_dispatched_error"));
      }
    },

    beforeModel: function beforeModel() {
      var previousRoutes = this.router.router.currentHandlerInfos;
      var previousRoute = previousRoutes && previousRoutes.pop().name;
      this.set("backLinkPath", previousRoute);
    },

    model: function model(params) {
      var store = this.store;
      var itemId = params["item_id"];
      return store.peekRecord("item", itemId) || store.findRecord("item", itemId);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("previousRoute", this.get("backLinkPath"));
    }
  });
});