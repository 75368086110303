define('goodcity/models/contact', ['exports', 'ember-data', 'goodcity/models/addressable'], function (exports, _emberData, _goodcityModelsAddressable) {

  var attr = _emberData['default'].attr,
      belongsTo = _emberData['default'].belongsTo;

  exports['default'] = _goodcityModelsAddressable['default'].extend({
    name: attr('string'),
    mobile: attr('string'),

    delivery: belongsTo('delivery', { async: false })
  });
});