define("goodcity/models/offer_response", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports["default"] = _emberDataModel["default"].extend({
    userId: (0, _emberDataAttr["default"])("string"),
    users: (0, _emberDataRelationships.belongsTo)("user", {
      async: false
    }),
    offerId: (0, _emberDataAttr["default"])("string"),
    offer: (0, _emberDataRelationships.belongsTo)("offer", { async: false }),
    messages: (0, _emberDataRelationships.hasMany)("message", {
      async: false
    })
  });
});