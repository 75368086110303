define("goodcity/models/message", ["exports", "ember", "ember-data"], function (exports, _ember, _emberData) {
  var getOwner = _ember["default"].getOwner;

  var attr = _emberData["default"].attr,
      belongsTo = _emberData["default"].belongsTo;

  exports["default"] = _emberData["default"].Model.extend({
    body: attr("string"),
    isPrivate: attr("boolean"),
    createdAt: attr("date"),
    updatedAt: attr("date"),
    offerId: attr("string"),
    itemId: attr("string"),
    recipientId: attr("string"),
    state: attr("string", { defaultValue: "read" }),
    senderId: attr("string"),
    sender: belongsTo("user", { async: false }),
    item: belongsTo("item", { async: false }),
    offer: belongsTo("offer", { async: false }),
    offerResponse: belongsTo("offerResponse", { async: false }),

    messageableType: attr("string"),
    messageableId: attr("string"),
    unreadCount: attr("string"),

    parsedBody: _ember["default"].computed("body", function () {
      var body = this.get("body");
      body = body.replace(/(<br>)/gm, "\n");
      body = body.replace(/(<)/g, "&lt;");

      var hrefExpressionMatch = body.match(/\&lt;a href=(.*?)\>(.*?)\&lt;\/a\s*?\>/);
      if (hrefExpressionMatch) {
        body = this.sanitizingAnchorLinks(body, hrefExpressionMatch);
      }
      return body;
    }),

    sanitizingAnchorLinks: function sanitizingAnchorLinks(body, hrefExpressionMatch) {
      var originalLink = hrefExpressionMatch[0];
      var anchorLink = hrefExpressionMatch[1];
      var text = hrefExpressionMatch[2];
      if (anchorLink.includes("/plan_delivery") || anchorLink.includes("crossroads-foundation.formstack.com/forms/goods_donor_survey?field")) {
        body = body.replace(originalLink, "<a href=" + anchorLink + ">" + text + "</a>");
      }
      return body;
    },

    myMessage: _ember["default"].computed(function () {
      var session = getOwner(this).lookup("service:session");
      return this.get("sender.id") === session.get("currentUser.id");
    }),

    isMessage: _ember["default"].computed("this", function () {
      return true;
    }),

    createdDate: _ember["default"].computed(function () {
      return new Date(this.get("createdAt")).toDateString();
    }),

    itemImageUrl: _ember["default"].computed.alias("item.displayImageUrl"),
    isRead: _ember["default"].computed.equal("state", "read"),
    isUnread: _ember["default"].computed.equal("state", "unread")
  });
});