define("goodcity/controllers/image_editor", ["exports", "ember", "ember-i18n", "goodcity/config/environment", "goodcity/mixins/async_tasks", "goodcity/utils/utility-methods"], function (exports, _ember, _emberI18n, _goodcityConfigEnvironment, _goodcityMixinsAsync_tasks, _goodcityUtilsUtilityMethods) {

  /**
   * Image Editor
   *
   * This is a modified version of the old `edit_images` controller (shared.goodcity)
   * This version supports any time of imageable record. In our case Item and Package
   *
   */
  exports["default"] = _ember["default"].Controller.extend(_goodcityMixinsAsync_tasks["default"], {
    queryParams: ["forwardRoute"],
    session: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    cordova: _ember["default"].inject.service(),
    imageService: _ember["default"].inject.service(),
    previewImage: null,
    addPhotoLabel: (0, _emberI18n.translationMacro)("edit_images.add_photo"),
    isReady: false,
    isExpanded: false,
    backBtnVisible: true,
    loadingPercentage: (0, _emberI18n.translationMacro)("edit_images.image_uploading"),
    uploadedFileDate: null,

    record: _ember["default"].computed.alias("model"),

    initActionSheet: function initActionSheet(onSuccess) {
      return window.plugins.actionsheet.show({
        buttonLabels: [this.locale("edit_images.upload").string, this.locale("edit_images.camera").string, this.locale("edit_images.cancel").string]
      }, function (buttonIndex) {
        if (buttonIndex === 1) {
          navigator.camera.getPicture(onSuccess, null, {
            quality: 40,
            destinationType: navigator.camera.DestinationType.DATA_URL,
            sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY
          });
        }
        if (buttonIndex === 2) {
          navigator.camera.getPicture(onSuccess, null, {
            correctOrientation: true,
            quality: 40,
            destinationType: navigator.camera.DestinationType.DATA_URL,
            sourceType: navigator.camera.PictureSourceType.CAMERA
          });
        }
        if (buttonIndex === 3) {
          window.plugins.actionsheet.hide();
        }
      });
    },

    previewMatchesFavourite: _ember["default"].computed("previewImage", "favouriteImage", function () {
      return this.get("previewImage") === this.get("favouriteImage");
    }),

    noImage: _ember["default"].computed.empty("images"),

    images: _ember["default"].computed("record.images.length", function () {
      //The reason for sorting is because by default it's ordered by favourite
      //then id order. If another image is made favourite then deleted the first image
      //by id order is made favourite which can be second image in list which seems random.

      //Sort by id ascending except place new images id = 0 at end
      return (this.getWithDefault("record.images", []) || _ember["default"].A()).toArray().sort(function (a, b) {
        a = parseInt(a.get("id"), 10);
        b = parseInt(b.get("id"), 10);
        if (a === 0) {
          return 1;
        }
        if (b === 0) {
          return -1;
        }
        return a - b;
      });
    }),

    supportsFavouriteImage: _ember["default"].computed("record", function () {
      var record = this.get("record");

      return _goodcityUtilsUtilityMethods["default"].supportsField(record, "favouriteImageId");
    }),

    favouriteImage: _ember["default"].computed("supportsFavouriteImage", "record.favouriteImageId", "images.@each.favourite", "images.[]", function () {
      if (this.get("supportsFavouriteImage")) {
        var id = this.get("record.favouriteImageId");
        var im = id && this.get("images").findBy("id", id);

        if (im) {
          return im;
        }
      }
      return this.get("images").findBy("favourite");
    }),

    initPreviewImage: _ember["default"].on("init", _ember["default"].observer("record", "images.[]", function () {
      if (this.get("images").includes(this.get("previewImage"))) {
        return;
      }

      var image = this.get("images.firstObject");
      if (image) {
        this.send("setPreview", image);
      }
    })),

    isImageOnLongTermStorage: _ember["default"].computed("previewImage", function () {
      return this.get("previewImage.isImageOnLongTermStorage");
    }),

    //css related
    previewImageBgCss: _ember["default"].computed("previewImage", "isExpanded", "previewImage.angle", {
      get: function get() {
        var _this2 = this;

        var css = this.get("instructionBoxCss");
        if (!this.get("previewImage")) {
          return css;
        }

        var imgTag = new Image();
        imgTag.onload = function () {
          var newCSS = new _ember["default"].Handlebars.SafeString(css + "background-image:url(" + _this2.get("previewImage.imageUrl") + ");" + "background-size: " + (_this2.get("isExpanded") ? "contain" : "cover") + ";");
          _this2.set("previewImageBgCss", newCSS);
        };

        imgTag.src = this.get("previewImage.imageUrl");

        return new _ember["default"].Handlebars.SafeString(css + "background-image:url('assets/images/image_loading.gif');" + "background-size: 'inherit';");
      },

      set: function set(key, value) {
        return value;
      }
    }),

    instructionBoxCss: _ember["default"].computed("previewImage", "isExpanded", function () {
      var height = _ember["default"].$(window).height() * 0.6;
      return new _ember["default"].Handlebars.SafeString("min-height:" + height + "px;");
    }),

    thumbImageCss: _ember["default"].computed(function () {
      var imgWidth = Math.min(120, _ember["default"].$(window).width() / 4 - 14);
      return new _ember["default"].Handlebars.SafeString("width:" + imgWidth + "px; height:" + imgWidth + "px;");
    }),

    locale: function locale(str) {
      return this.get("i18n").t(str);
    },

    confirmRemoveImage: function confirmRemoveImage() {
      var deferred = _ember["default"].RSVP.defer();

      this.get("messageBox").custom(this.locale("edit_images.delete_confirm"), this.locale("receive.receiving.not_now"), function () {
        return deferred.resolve(false);
      }, this.locale("edit_images.remove_image"), function () {
        return deferred.resolve(true);
      });

      return deferred.promise;
    },

    actions: {
      done: function done() {
        if (this.get("forwardRoute")) {
          this.replaceRoute(this.get("forwardRoute"), this.get("record"));
        } else {
          window.history.back();
        }
      },

      setPreview: function setPreview(image) {
        this.set("previewImage", image);
      },

      setFavourite: function setFavourite() {
        var _this3 = this;

        var record = this.get("record");
        var img = this.get("previewImage");

        if (this.get("supportsFavouriteImage")) {
          record.set("favouriteImageId", img.get("id"));
          record.save()["catch"](function (error) {
            record.rollbackAttributes();
            throw error;
          });
        } else {
          this.get("images").setEach("favourite", false);
          this.get("previewImage").set("favourite", true);
          this.get("previewImage").save()["catch"](function (error) {
            _this3.get("images").forEach(function (i) {
              return i.rollbackAttributes();
            });
            throw error;
          });
        }
      },

      deleteImage: function deleteImage(img) {
        var _this4 = this;

        this.confirmRemoveImage().then(function (allowed) {
          if (!allowed) return;

          _this4.runTask(function () {
            img.deleteRecord();
            return img.save().then(function (i) {
              i.unloadRecord();
              _this4.initPreviewImage();
              if (!_this4.get("favouriteImage")) {
                _this4.send("setFavourite");
              }
            });
          }, _this4.ERROR_STRATEGIES.MODAL)["finally"](function () {
            _this4.set("isExpanded", false);
          });
        });
      },

      expandImage: function expandImage() {
        this.toggleProperty("isExpanded");
      },

      //file upload
      triggerUpload: function triggerUpload() {
        // For Cordova application
        if (_goodcityConfigEnvironment["default"].cordova.enabled) {
          var onSuccess = (function (_this) {
            return function (path) {
              console.log(path);
              var dataURL = "data:image/jpg;base64," + path;

              $("input[type='file']").fileupload("option", "formData").file = dataURL;
              $("input[type='file']").fileupload("add", { files: [dataURL] });
            };
          })(this);

          this.initActionSheet(onSuccess);
        } else {
          // Trigger the file selection
          _ember["default"].$("#photo-list input[type='file']").trigger("click");
        }
      },

      uploadReady: function uploadReady() {
        this.set("isReady", true);
      },

      uploadStart: function uploadStart(e, data) {
        this.set("uploadedFileDate", data);
        _ember["default"].$(".loading-image-indicator").show();
      },

      cancelUpload: function cancelUpload() {
        if (this.get("uploadedFileDate")) {
          this.get("uploadedFileDate").abort();
        }
      },

      uploadProgress: function uploadProgress(e, data) {
        e.target.disabled = true; // disable image-selection
        var progress = parseInt(data.loaded / data.total * 100, 10) || 0;
        this.set("addPhotoLabel", progress + "%");
        this.set("loadingPercentage", this.get("i18n").t("edit_images.image_uploading") + progress + "%");
      },

      uploadComplete: function uploadComplete(e) {
        e.target.disabled = false; // enable image-selection
        this.set("uploadedFileDate", null);
        _ember["default"].$(".loading-image-indicator.hide_image_loading").hide();
        this.set("addPhotoLabel", this.get("i18n").t("edit_images.add_photo"));
        this.set("loadingPercentage", this.get("i18n").t("edit_images.image_uploading"));
      },

      uploadSuccess: function uploadSuccess(e, data) {
        var _this5 = this;

        var identifier = data.result.version + "/" + data.result.public_id + "." + data.result.format;
        var record = this.get("record");
        var favourite = this.get("images.length") === 0;

        this.runTask(function () {
          return _this5.get("imageService").createImage(record, identifier, {
            favourite: favourite
          });
        }, this.ERROR_STRATEGIES.MODAL);
      },

      rotateImageRight: function rotateImageRight() {
        var angle = this.get("previewImage.angle");
        angle = (angle + 90) % 360;
        this.send("rotateImage", angle);
      },

      rotateImageLeft: function rotateImageLeft() {
        var angle = this.get("previewImage.angle");
        angle = (angle ? angle - 90 : 270) % 360;
        this.send("rotateImage", angle);
      },

      rotateImage: function rotateImage(angle) {
        var image = this.get("previewImage");
        image.set("angle", angle);
        _ember["default"].run.debounce(this, this.saveImageRotation, image, 400);
      }
    },

    saveImageRotation: function saveImageRotation(image) {
      image.save();
    }
  });
});