define('goodcity/components/lightgallery-preview', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    lightGallery: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super();

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var lightGallery = _ember['default'].$("#lightGallery, .lightGallery").lightGallery({
          thumbnail: false,
          hideControlOnEnd: true,
          closable: false,
          counter: true,
          swipeThreshold: 50,
          enableTouch: true
        });

        _this.set("lightGallery", lightGallery);
      });
    },

    willDestroyElement: function willDestroyElement() {
      this.get("lightGallery").destroy();
    }

  });
});