define("goodcity/instance-initializers/custom_subscriptions", ["exports", "lodash"], function (exports, _lodash) {
  exports["default"] = {
    name: "custom_subscriptions",

    initialize: function initialize(application) {
      var store = application.lookup("service:store");
      var subscriptions = application.lookup("controller:subscriptions");

      subscriptions.addHandler("shareable", function (_ref) {
        var item = _ref.item;
        var operation = _ref.operation;

        if (operation === "delete") {
          var id = _lodash["default"].get(item, "data.id");
          var record = id && store.peekRecord("shareable", id);

          if (record) {
            store.unloadRecord(record);
          }
        } else {
          store.pushPayload("shareable", item);
        }
      });
    }
  };
});