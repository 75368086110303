define("goodcity/controllers/my_account", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    user: _ember["default"].computed.alias("model.user"),

    actions: {
      deleteAccount: function deleteAccount() {
        this.transitionToRoute("delete_account");
      }
    }
  });
});