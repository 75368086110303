define("goodcity/components/donor-conditions", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    store: _ember["default"].inject.service(),

    donorConditions: _ember["default"].computed(function () {
      return this.get("store").peekAll("donor_condition").filterBy("visibleToDonor", true);
    }),

    sortedDonorConditions: _ember["default"].computed("donorConditions.[]", function () {
      return this.get("donorConditions").sortBy("id");
    })
  });
});