define("goodcity/components/package-image", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    store: _ember["default"].inject.service(),
    displayUserPrompt: false,
    selectedImage: null,

    // images: Ember.computed.alias("package.item.images"),

    currentPackage: _ember["default"].computed("package", function () {
      return this.get("store").peekRecord("package", this.get("package.id")) || this.get("package");
    }),

    images: _ember["default"].computed("currentPackage.item.images.[]", "currentPackage.images.[]", function () {
      var pkgImages = this.getWithDefault("currentPackage.images", []).toArray();

      return this.getWithDefault("currentPackage.item.images", []).toArray().filter(function (im) {
        return !pkgImages.findBy("cloudinaryId", im.get("cloudinaryId"));
      }).concat(pkgImages);
    }),

    actions: {
      selectImage: function selectImage(image) {
        this.set("selectedImage", image);
      },

      setPackageImage: function setPackageImage() {
        var image = this.get("selectedImage");
        this.get("package").favouriteImage = image;
        this.sendAction("setPackageImage", this.get("currentPackage"), image);
      },

      displayImagesListOverlay: function displayImagesListOverlay() {
        if (this.get("images").length > 0) {
          this.set("displayUserPrompt", true);
          var favouriteImage = this.get("package.favouriteImage");
          if (favouriteImage) {
            this.send("selectImage", favouriteImage);
          }
        }
      }
    }
  });
});