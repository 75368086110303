define('goodcity/models/orders_package', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports['default'] = _emberDataModel['default'].extend({
    packageId: (0, _emberDataAttr['default'])('number'),
    order_id: (0, _emberDataAttr['default'])('number'),
    itemId: (0, _emberDataAttr['default'])('number'),
    designationId: (0, _emberDataAttr['default'])('number'),
    quantity: (0, _emberDataAttr['default'])('number'),
    sentOn: (0, _emberDataAttr['default'])('date'),
    state: (0, _emberDataAttr['default'])('string'),

    'package': (0, _emberDataRelationships.belongsTo)('package', { async: true }),
    designation: (0, _emberDataRelationships.belongsTo)('designation', { async: true }),

    availableQty: _ember['default'].computed("quantity", function () {
      return this.get('quantity');
    })
  });
});