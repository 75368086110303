define('goodcity/app', ['exports', 'ember', 'goodcity/resolver', 'ember-load-initializers', 'goodcity/config/environment'], function (exports, _ember, _goodcityResolver, _emberLoadInitializers, _goodcityConfigEnvironment) {

  var App = undefined;

  window.EmberENV = window.EmberENV || {};
  window.EmberENV.ENABLE_DS_FILTER = true;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _goodcityConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _goodcityConfigEnvironment['default'].podModulePrefix,
    Resolver: _goodcityResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _goodcityConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});