define("goodcity/components/cloudinary-image-tag", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    loading: true,

    changedSrc: (function () {
      if (!this.is_cached(this.get("src"))) {
        this.set("loading", true);
      }
    }).observes("src"),

    onLoad: function onLoad() {
      this.set("loading", false);
    },

    is_cached: function is_cached(src) {
      var image = new Image();
      image.src = src;
      return image.complete;
    },

    didInsertElement: function didInsertElement() {
      var updateScreen = _ember["default"].run.bind(this, this.onLoad);
      this.$(".cl-item-image").on("load", updateScreen);
    },

    willDestroyElement: function willDestroyElement() {
      this.$(".cl-item-image").off("load");
    }
  });
});