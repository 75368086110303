define("goodcity/controllers/search_label", ["exports", "ember", "ember-i18n", "goodcity/mixins/async_tasks"], function (exports, _ember, _emberI18n, _goodcityMixinsAsync_tasks) {
  exports["default"] = _ember["default"].Controller.extend(_goodcityMixinsAsync_tasks["default"], {
    filter: "",
    searchText: "",
    fetchMoreResult: true,
    searchPlaceholder: (0, _emberI18n.translationMacro)("search.placeholder"),
    i18n: _ember["default"].inject.service(),
    previousRoute: "",

    allPackageTypes: _ember["default"].computed("fetchMoreResult", function () {
      return this.store.peekAll("package_type").filterBy("visibleInSelects", true);
    }),

    hasSearchText: _ember["default"].computed("searchText", function () {
      return _ember["default"].$.trim(this.get("searchText")).length;
    }),

    hasFilter: _ember["default"].computed("filter", function () {
      return _ember["default"].$.trim(this.get("filter")).length;
    }),

    onSearchTextChange: _ember["default"].observer("searchText", function () {
      // wait before applying the filter
      _ember["default"].run.debounce(this, this.applyFilter, 500);
    }),

    applyFilter: function applyFilter() {
      this.set("filter", this.get("searchText"));
      this.set("fetchMoreResult", true);
    },

    filteredResults: _ember["default"].computed("filter", "fetchMoreResult", "allPackageTypes.[]", function () {
      var filter = _ember["default"].$.trim(this.get("filter").toLowerCase());
      var types = [];
      var matchFilter = function matchFilter(value) {
        return (value || "").toLowerCase().indexOf(filter) !== -1;
      };

      if (filter.length > 0) {
        this.get("allPackageTypes").forEach(function (type) {
          if (matchFilter(type.get("name")) || matchFilter(type.get("otherTerms"))) {
            types.push(type);
          }
        });
        _ember["default"].run.later(this, this.highlight);
      } else {
        types = types.concat(this.get("allPackageTypes").toArray());
        this.clearHiglight();
      }

      return types.sortBy("name").uniq();
    }),

    highlight: function highlight() {
      var string = _ember["default"].$.trim(this.get("filter").toLowerCase());
      this.clearHiglight();
      _ember["default"].$(".item_types_result li div").each(function () {
        var text = _ember["default"].$(this).text();
        if (text.toLowerCase().indexOf(string.toLowerCase()) > -1) {
          var matchStart = text.toLowerCase().indexOf("" + string.toLowerCase() + "");
          var matchEnd = matchStart + string.length - 1;
          var beforeMatch = text.slice(0, matchStart);
          var matchText = text.slice(matchStart, matchEnd + 1);
          var afterMatch = text.slice(matchEnd + 1);
          _ember["default"].$(this).html(beforeMatch + "<em>" + matchText + "</em>" + afterMatch);
        }
      });
    },

    clearHiglight: function clearHiglight() {
      _ember["default"].$("em").replaceWith(function () {
        return this.innerHTML;
      });
    },

    actions: {
      clearSearch: function clearSearch(isCancelled) {
        this.set("filter", "");
        this.set("searchText", "");
        this.set("fetchMoreResult", true);
        if (!isCancelled) {
          _ember["default"].$("#searchText").focus();
        }
      }
    }
  });
});