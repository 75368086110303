define("goodcity/components/phone-number", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    tagName: "span",
    number: null,
    hidden: _ember["default"].computed.empty("number"),

    displayNumber: _ember["default"].computed('number', function () {
      if (this.get("hidden")) {
        return "";
      }
      var num = this.get("number").replace(/\+852/, "");
      return num.length > 4 ? num.substr(0, 4) + " " + num.substr(4) : num;
    }),

    linkNumber: _ember["default"].computed('number', function () {
      if (this.get("hidden")) {
        return "";
      }
      var prefix = this.get("number").indexOf("+852") === -1 ? "+852" : "";
      return prefix + this.get("number").replace(/ /g, "");
    })
  });
});