define('goodcity/routes/authorize', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    messageBox: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    beforeModel: function beforeModel(transition) {
      if (!this.session.get('isLoggedIn')) {
        transition.abort();
        var loginController = this.controllerFor('login');
        loginController.set('attemptedTransition', transition);
        this.transitionTo('login');
        return false;
      }
      return true;
    }
  });
});