define('goodcity/models/schedule', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

  var attr = _emberData['default'].attr,
      hasMany = _emberData['default'].hasMany;

  exports['default'] = _emberData['default'].Model.extend({
    resource: attr('string'),
    slot: attr('number'),
    slotName: attr('string'),
    zone: attr('string'),
    scheduledAt: attr('date'),

    deliveries: hasMany('delivery', { async: false }),

    i18n: _ember['default'].inject.service(),

    dayTime: _ember['default'].computed('slotName', function () {
      var slot = (this.get('slotName') || '').match(/\d+/);
      var day_time = '';
      if (slot) {
        slot = parseInt(slot, 10);
        day_time = this.get("i18n").t("day." + (slot > 8 && slot < 12 ? "morning" : "afternoon"));
      }
      return day_time;
    })
  });
});