define("goodcity/controllers/review_offer/items", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    sortProperties: ["latestUpdatedTime:desc"],
    sortedItems: _ember["default"].computed.sort("offerAndItems", "sortProperties"),
    items: _ember["default"].computed.alias("model.items"),
    offer: _ember["default"].computed.alias("model"),
    reviewOffer: _ember["default"].inject.controller(),

    lastMessage: _ember["default"].computed("offer.messages", "offer.messages.[]", function () {
      return this.get("offer.messages").filterBy("item", null).filter(function (m) {
        return !m.get("isCharityConversation");
      }).sortBy("createdAt").get("lastObject");
    }),

    unreadOfferMessagesCount: _ember["default"].computed("offer.messages", "offer.messages.[]", "offer.messages.@each.state", function () {
      return this.get("offer.messages").filter(function (m) {
        return !m.get("isCharityConversation");
      }).filterBy("isUnread").get("length");
    }),

    offerAndItems: _ember["default"].computed("items.@each.state", function () {
      // avoid deleted-items which are not persisted yet.
      var elements = this.get("items").rejectBy("state", "draft").rejectBy("isDeleted", true).toArray();

      // add offer to array for general messages display
      elements.push(this.get("model"));
      return elements;
    }),

    actions: {
      handleBrokenImage: function handleBrokenImage() {
        this.get("model.reviewedBy").set("hasImage", null);
      },
      addItem: function addItem() {
        this.get("reviewOffer").send("addItem");
      }
    }
  });
});