define('goodcity/routes/ggv_orders', ['exports', 'goodcity/utils/ajax-promise', 'goodcity/routes/authorize'], function (exports, _goodcityUtilsAjaxPromise, _goodcityRoutesAuthorize) {
  exports['default'] = _goodcityRoutesAuthorize['default'].extend({
    model: function model(params) {
      var _this = this;
      return new _goodcityUtilsAjaxPromise['default']("/gogovan_orders/driver_details", "GET", this.get('session.authToken'), { id: params.ggv_id }).then(function (data) {
        _this.store.pushPayload(data);
        return _this.store.peekRecord('offer', data.offer.id);
      });
    }
  });
});