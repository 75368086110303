define("goodcity/mixins/preload_data", ["exports", "ember", "goodcity/utils/ajax-promise", "goodcity/config/environment"], function (exports, _ember, _goodcityUtilsAjaxPromise, _goodcityConfigEnvironment) {
  exports["default"] = _ember["default"].Mixin.create({
    messages: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    preloadData: function preloadData(includePublicTypes) {
      var _this = this;

      var promises = [];
      var isDonorApp = this.get("session.isDonorApp");
      var isAdminApp = this.get("session.isAdminApp");

      var retrieve = function retrieve(types) {
        return types.map(function (type) {
          return _this.store.findAll(type, { reload: true });
        });
      };

      if (includePublicTypes && isDonorApp) {
        promises = retrieve(_goodcityConfigEnvironment["default"].APP.PRELOAD_TYPES);
      }

      if (this.get("session.authToken")) {
        promises.push(new _goodcityUtilsAjaxPromise["default"]("/auth/current_user_profile", "GET", this.session.get("authToken"), null, null, this.get("i18n").get("locale")).then(function (data) {
          _this.store.pushPayload(data);
          _this.store.pushPayload({ user: data.user_profile });
          _this.notifyPropertyChange("session.currentUser");
        }));

        var offer_params = this.session.get("isAdminApp") ? { states: ["nondraft"], summarize: true } : { states: ["for_donor"] };

        if (isDonorApp) {
          promises = promises.concat(retrieve(_goodcityConfigEnvironment["default"].APP.PRELOAD_AUTHORIZED_TYPES));
        } else if (isAdminApp) {
          promises.push(this.get("messages").fetchUnreadMessageCount());
        }
      }

      return _ember["default"].RSVP.all(promises);
    },

    loadStaticData: function loadStaticData(includePublicTypes) {
      var _this2 = this;

      var promises = [];
      var retrieve = function retrieve(types) {
        return types.map(function (type) {
          return _this2.store.findAll(type, { reload: true });
        });
      };
      if (includePublicTypes) {
        promises = retrieve(_goodcityConfigEnvironment["default"].APP.PRELOAD_TYPES);
      }
      if (this.get("session.authToken")) {
        promises = promises.concat(retrieve(_goodcityConfigEnvironment["default"].APP.PRELOAD_AUTHORIZED_TYPES));
      }
      return _ember["default"].RSVP.all(promises);
    }
  });
});