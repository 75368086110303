define('goodcity/routes/post_login', ['exports', 'ember', 'goodcity/mixins/preload_data'], function (exports, _ember, _goodcityMixinsPreload_data) {
  exports['default'] = _ember['default'].Route.extend(_goodcityMixinsPreload_data['default'], {
    cordova: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      var _this = this;

      _ember['default'].run(function () {
        return _this.controllerFor('application').send('logMeIn');
      });
      return this.preloadData()['catch'](function (error) {
        if (error.status === 0) {
          _this.transitionTo("offline");
        } else {
          throw error;
        }
      })['finally'](function () {
        return _this.get("cordova").appLoad();
      });
    },

    afterModel: function afterModel() {
      var _this2 = this;

      if (this.get("session.isAdminApp")) {
        this.loadStaticData()['catch'](function (error) {
          if (error.status === 0) {
            _this2.transitionTo("offline");
          } else {
            throw error;
          }
        });
      }

      // After everthying has been loaded, redirect user to requested url
      var attemptedTransition = this.controllerFor('login').get('attemptedTransition');
      if (attemptedTransition) {
        attemptedTransition.retry();
        this.set('attemptedTransition', null);
      } else {
        var currentUser = this.get('session.currentUser');
        if (this.get('session.isAdminApp')) {
          this.transitionTo('dashboard');
        } else {
          this.transitionTo('/offers');
        }
      }
    }

  });
});