define('goodcity/components/book-van', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    didInsertElement: function didInsertElement() {
      this._super();

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        validateInputs();
        validateForm();
      });

      function validateForm() {
        _ember['default'].$('.book_van').click(function () {
          _ember['default'].$.each(['.pickadate', '.timepicker'], function (i, input) {
            checkInput(_ember['default'].$(input));
          });
          if (_ember['default'].$('.has-error').length > 0) {
            return false;
          }
        });
      }

      function validateInputs() {
        _ember['default'].$('.pickadate, .timepicker').focusout(function () {
          return checkInput(this);
        });
        _ember['default'].$('.pickadate, .timepicker').focus(function () {
          return removeHighlight(this);
        });
      }

      function checkInput(element) {
        var parent = _ember['default'].$(element).parent();
        var value = _ember['default'].$(element).val();

        if (value === undefined || value.length === 0) {
          parent.addClass('has-error');
        } else {
          parent.removeClass('has-error');
        }
      }

      function removeHighlight(element) {
        var parent = _ember['default'].$(element).parent();
        parent.removeClass('has-error');
      }
    }
  });
});