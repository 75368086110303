define("goodcity/routes/session", ["exports", "ember"], function (exports, _ember) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Route.extend({
    beforeModel: function beforeModel() {
      if (this.session.get("isLoggedIn")) {
        var currentUser = this.get("session.currentUser");

        if (currentUser) {
          this.transitionTo("dashboard");
        } else {
          getOwner(this).lookup("route:application")._loadDataStore();
        }
      } else {
        this.transitionTo("login");
      }
    }
  });
});