define("goodcity/components/online-button", ["exports", "ember"], function (exports, _ember) {

  /* {{#"online-button" classNames="btn" action="submit" actionArgs=true}}
   *   {{t "btn_label"}}
   * {{/online-button}}
   *
   * You can pass multiple arguments for actionArgs like this: actionArgs="[\"test\",true]"
   * Note actionArgs="['test']" causes json parse error, but this works actionArgs='["test"]'
   */

  exports["default"] = _ember["default"].Component.extend(_ember["default"].TargetActionSupport, {
    attributeBindings: ["disabled"],
    disabled: false,
    offer: null,

    updateDisabled: null,
    disabledOverride: false,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.updateDisabled = _ember["default"].run.bind(this, function () {
        var online = navigator.connection ? navigator.connection.type !== "none" : navigator.onLine;
        _this.set("disabled", !online || _this.get("disabledOverride"));
      });
      this.updateDisabled();
      if (this.get("offer")) {
        this.get("offer.state") === "received" ? this.set("disabled", true) : this.set("disabled", false);
      }
      window.addEventListener("online", this.updateDisabled);
      window.addEventListener("offline", this.updateDisabled);
    },

    willDestroyElement: function willDestroyElement() {
      if (this.updateDisabled) {
        window.removeEventListener("online", this.updateDisabled);
        window.removeEventListener("offline", this.updateDisabled);
      }
    },

    click: function click() {
      var args = this.get("actionArgs");
      if (typeof args === "string" && args.indexOf("[") === 0) {
        args = JSON.parse(args);
      }
      this.triggerAction({ actionContext: args });
    }
  });
});