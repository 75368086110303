define("goodcity/routes/search", ["exports", "goodcity/routes/authorize"], function (exports, _goodcityRoutesAuthorize) {
  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    setupController: function setupController(controller) {
      controller.on();
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.off();
      }
    }
  });
});