define("goodcity/helpers/state-filter-icon", ["exports", "ember", "goodcity/services/filter-service"], function (exports, _ember, _goodcityServicesFilterService) {
  var _STATE_ICONS;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var PRIORITY = _goodcityServicesFilterService.STATE_FILTERS.PRIORITY;
  var PUBLISHED = _goodcityServicesFilterService.STATE_FILTERS.PUBLISHED;
  var NEW = _goodcityServicesFilterService.STATE_FILTERS.NEW;
  var REVIEWING = _goodcityServicesFilterService.STATE_FILTERS.REVIEWING;
  var REVIEWED = _goodcityServicesFilterService.STATE_FILTERS.REVIEWED;
  var SCHEDULED = _goodcityServicesFilterService.STATE_FILTERS.SCHEDULED;
  var RECEIVING = _goodcityServicesFilterService.STATE_FILTERS.RECEIVING;
  var RECEIVED = _goodcityServicesFilterService.STATE_FILTERS.RECEIVED;
  var CANCELLED = _goodcityServicesFilterService.STATE_FILTERS.CANCELLED;
  var INACTIVE = _goodcityServicesFilterService.STATE_FILTERS.INACTIVE;

  var STATE_ICONS = (_STATE_ICONS = {}, _defineProperty(_STATE_ICONS, NEW, "shopping-basket"), _defineProperty(_STATE_ICONS, REVIEWING, "list-ol"), _defineProperty(_STATE_ICONS, REVIEWED, "hourglass-half"), _defineProperty(_STATE_ICONS, SCHEDULED, "clock-o"), _defineProperty(_STATE_ICONS, RECEIVING, "shopping-cart"), _defineProperty(_STATE_ICONS, RECEIVED, "thumbs-up"), _defineProperty(_STATE_ICONS, CANCELLED, "thumbs-down"), _defineProperty(_STATE_ICONS, INACTIVE, "bed"), _defineProperty(_STATE_ICONS, PRIORITY, "warning"), _defineProperty(_STATE_ICONS, PUBLISHED, "eye"), _STATE_ICONS);

  exports["default"] = _ember["default"].Helper.helper(function (state) {
    return STATE_ICONS[state[0]] || "";
  });
});