define('goodcity/routes/offers', ['exports', 'goodcity/routes/authorize'], function (exports, _goodcityRoutesAuthorize) {
  exports['default'] = _goodcityRoutesAuthorize['default'].extend({
    renderTemplate: function renderTemplate() {
      this.render(); // default template
      this.render('appMenuList', {
        into: 'offers',
        outlet: 'appMenuList',
        controller: 'offers'
      });
    }
  });
});