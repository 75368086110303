define("goodcity/controllers/notification_link", ["exports", "ember", "goodcity/mixins/back_navigator"], function (exports, _ember, _goodcityMixinsBack_navigator) {
  exports["default"] = _ember["default"].Controller.extend(_goodcityMixinsBack_navigator["default"], {
    messages: _ember["default"].inject.service(),

    unreadMessageCount: _ember["default"].computed.alias("messages.unreadMessageCount"),

    hasMessages: _ember["default"].computed("unreadMessageCount", function () {
      return this.get("unreadMessageCount") > 0;
    }),

    actions: {
      displayNotification: function displayNotification() {
        this.send("togglePath", "my_notifications");
      }
    }
  });
});