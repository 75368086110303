define("goodcity/controllers/companies/new", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    offerId: _ember["default"].computed.alias("model.id"),
    messageBox: _ember["default"].inject.service(),

    isInvalidCompanyName: _ember["default"].computed("name", function () {
      return this.get("name").trim().length === 0;
    }),

    actions: {
      back: function back() {
        this.get("model.company") && this.get("model.company").rollbackAttributes();
        this.transitionToRoute("review_offer.donor_details", this.get("model.id"));
      },

      saveCompanyAndOffer: function saveCompanyAndOffer() {
        var _this = this;

        if (this.get("isInvalidCompanyName")) {
          return;
        }
        var offer = this.get("model");
        var name = this.get("name");
        var crmId = this.get("crmId");
        var createdById = this.get("session.currentUser.id");
        var company = this.store.createRecord("company", {
          name: name,
          crmId: crmId,
          createdById: createdById
        });

        company.get("offers").pushObject(offer);
        company.save().then(function () {
          offer.save().then(function () {
            _this.transitionToRoute("review_offer.donor_details", offer.get("id"));
          });
        })["catch"](function (_ref) {
          var errors = _ref.errors;

          throw errors[0]["detail"];
        });
      }
    }
  });
});