define("goodcity/adapters/shareable", ["exports", "ember", "ember-data", "goodcity/config/environment"], function (exports, _ember, _emberData, _goodcityConfigEnvironment) {
  exports["default"] = _emberData["default"].JSONAPIAdapter.extend({
    namespace: _goodcityConfigEnvironment["default"].APP.NAMESPACE_V2,
    host: _goodcityConfigEnvironment["default"].APP.API_HOST_URL,
    session: _ember["default"].inject.service(),

    headers: _ember["default"].computed("session.authToken", function () {
      return {
        Authorization: "Bearer " + this.get("session.authToken"),
        "Accept-Language": this.get("session.language"),
        "X-GOODCITY-APP-NAME": _goodcityConfigEnvironment["default"].APP.NAME,
        "X-GOODCITY-APP-VERSION": _goodcityConfigEnvironment["default"].APP.VERSION,
        "X-GOODCITY-APP-SHA": _goodcityConfigEnvironment["default"].APP.SHA,
        "X-GOODCITY-APP-SHARED-SHA": _goodcityConfigEnvironment["default"].APP.SHARED_SHA
      };
    }),

    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, "updateRecord");

      return this.ajax(url, "PUT", { data: data });
    }
  });
});