define("goodcity/helpers/date-time-string", ["exports", "ember"], function (exports, _ember) {

  // Date Time String used in App:
  // "Tue Sep 14 2021 21:30:00 GMT+0530 (India Standard Time)" => "14/09/2021, 02:00:00""// HKT format

  exports["default"] = _ember["default"].Helper.helper(function (value) {
    if (value[0]) {
      return new Date(value).toLocaleString(undefined, {
        timeZone: "Asia/Hong_Kong"
      });
    } else {
      return "Date is not present";
    }
  });
});