define("goodcity/components/display-offer-list", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    store: _ember["default"].inject.service(),

    allMessages: _ember["default"].computed(function () {
      return this.get("store").peekAll("message");
    }),

    messageThreads: _ember["default"].computed("offer", "offer.id", "allMessages.[]", "allMessages.length", "allMessages.@each.{senderId,recipientId}", function () {
      var _this = this;

      var unreadMessageCount = this.get("offer.unreadMessagesCount") ? this.get("offer.unreadMessagesCount") : 0;
      var offerResponse = this.get("store").peekAll("offerResponse").filterBy("offerId", this.get("offer.id"));

      offerResponse.uniq().map(function (uid) {
        var messages = _this.get("allMessages").filter(function (m) {
          return m.get("messageableType") === "OfferResponse" && m.get("messageableId") === uid.id;
        });

        unreadMessageCount += messages.reduce(function (sum, m) {
          return sum + (m.get("isUnread") ? 1 : 0);
        }, 0);
      });

      return unreadMessageCount;
    })
  });
});