define('goodcity/models/version', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

  var attr = _emberData['default'].attr;

  exports['default'] = _emberData['default'].Model.extend({
    event: attr('string'),
    itemType: attr('string'),
    itemId: attr('number'),
    whodunnit: attr('string'),
    whodunnitName: attr('string'),
    state: attr('string'),
    createdAt: attr('date'),

    createdDate: _ember['default'].computed(function () {
      return this.get("createdAt").toDateString();
    }),

    i18n: _ember['default'].inject.service(),

    displayMessage: _ember['default'].computed(function () {
      switch (this.get("state")) {
        case 'draft':
          return this.get("i18n").t("item_log.added", { name: this.get("whodunnitName") });
        case 'submitted':
          return this.get("i18n").t("item_log.submitted", { name: this.get("whodunnitName") });
        case 'accepted':
          return this.get("i18n").t("item_log.accepted", { name: this.get("whodunnitName") });
        case 'rejected':
          return this.get("i18n").t("item_log.rejected", { name: this.get("whodunnitName") });
        case 'received':
          return this.get("i18n").t("item_log.received", { name: this.get("whodunnitName") });
        case 'missing':
          return this.get("i18n").t("item_log.missing", { name: this.get("whodunnitName") });
      }

      switch (this.get("event")) {
        case 'admin_called':
        case 'donor_called':
          return this.get("i18n").t("offer_log.donor_called", { name: this.get("whodunnitName") });
        case 'call_accepted':
          return this.get("i18n").t("offer_log.call_accepted", { name: this.get("whodunnitName") });
      }

      return this.get("i18n").t("item_log.updated", { name: this.get("whodunnitName") });
    })
  });
});