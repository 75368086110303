define("goodcity/controllers/authenticate", ["exports", "ember", "goodcity/utils/ajax-promise", "goodcity/config/environment"], function (exports, _ember, _goodcityUtilsAjaxPromise, _goodcityConfigEnvironment) {
  var getOwner = _ember["default"].getOwner;

  var timeout = undefined;

  exports["default"] = _ember["default"].Controller.extend({
    messageBox: _ember["default"].inject.service(),
    attemptedTransition: null,
    pin: "",
    timer: _goodcityConfigEnvironment["default"].APP.OTP_RESEND_TIME,
    pinAlreadySent: false,

    mobile: _ember["default"].computed("mobilePhone", function () {
      return _goodcityConfigEnvironment["default"].APP.HK_COUNTRY_CODE + this.get("mobilePhone");
    }),

    timerFunction: function timerFunction() {
      var _this2 = this;

      var waitTime = this.get("timer");
      if (waitTime === 1) {
        this.resetTimerParameters();
        return false;
      }

      if (!this.isDestroyed) {
        // check aaded as workaround to avoid failing test cases because of timer
        this.set("timer", waitTime - 1);
      }

      timeout = setTimeout(function () {
        _this2.timerFunction();
      }, 1000);
    },

    resetTimerParameters: function resetTimerParameters() {
      this.set("pinAlreadySent", false);
      if (!this.isDestroyed) {
        // check aaded as workaround to avoid failing test cases because of timer
        this.set("timer", _goodcityConfigEnvironment["default"].APP.OTP_RESEND_TIME);
      }
    },

    backLinkPath: _ember["default"].computed("session.backLinkPath", function () {
      return this.get("session.backLinkPath") || "login";
    }),

    actions: {
      authenticateUser: function authenticateUser() {
        _ember["default"].$(".auth_error").hide();
        var pin = this.get("pin");
        var otp_auth_key = this.get("session.otpAuthKey");
        var _this = this;

        var loadingView = getOwner(this).lookup("component:loading").append();
        new _goodcityUtilsAjaxPromise["default"]("/auth/verify", "POST", null, {
          pin: pin,
          otp_auth_key: otp_auth_key
        }).then(function (data) {
          clearTimeout(timeout);
          _this.resetTimerParameters();
          _this.setProperties({ pin: null });
          _this.set("session.authToken", data.jwt_token);
          _this.set("session.otpAuthKey", null);
          _this.store.pushPayload(data.user);
          _this.transitionToRoute("post_login");
        })["catch"](function (jqXHR) {
          _ember["default"].$("#pin").closest("div").addClass("error");
          _this.setProperties({ pin: null });
          if (jqXHR.status === 422 && jqXHR.responseJSON.errors && jqXHR.responseJSON.errors.pin) {
            _this.get("messageBox").alert(jqXHR.responseJSON.errors.pin);
          }
          console.log("Unable to authenticate");
        })["finally"](function () {
          return loadingView.destroy();
        });
      },

      resendPin: function resendPin() {
        var _this3 = this;

        var mobile = this.get("mobile");
        var loadingView = getOwner(this).lookup("component:loading").append();
        var _this = this;

        new _goodcityUtilsAjaxPromise["default"]("/auth/send_pin", "POST", null, { mobile: mobile }).then(function (data) {
          _this3.set("session.otpAuthKey", data.otp_auth_key);
          _this3.set("session.backLinkPath", "login");
          _this3.setProperties({ pin: null });
          _this3.transitionToRoute("/authenticate");
          _this3.set("pinAlreadySent", true);
          _this3.timerFunction();
        })["catch"](function (error) {
          if ([401].includes(error.status)) {
            _this.get("messageBox").alert("You are not authorized.", function () {
              _this.transitionToRoute("/");
            });
          } else if ([422, 403].includes(error.status)) {
            _ember["default"].$("#mobile").closest(".mobile").addClass("error");
            return;
          }
          throw error;
        })["finally"](function () {
          return loadingView.destroy();
        });
      },

      transitionTo: function transitionTo(path) {
        this.transitionToRoute(path);
      }
    }
  });
});