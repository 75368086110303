define('goodcity/components/external-link', ['exports', 'ember', 'goodcity/config/environment'], function (exports, _ember, _goodcityConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: "a",
    href: "#",
    cordova: _ember['default'].inject.service(),

    click: function click() {
      if (_goodcityConfigEnvironment['default'].cordova.enabled) {
        cordova.InAppBrowser.open(this.decodeLink(), "_system");
      } else {
        window.open(this.decodeLink(), "_system");
      }
      return false;
    },

    decodeLink: function decodeLink() {
      var link = this.attrs.linkUrl.value || this.attrs.linkUrl;
      return link.replace(/&amp;/g, '&');
    }
  });
});