define("goodcity/utils/ajax-promise", ["exports", "ember", "goodcity/config/environment"], function (exports, _ember, _goodcityConfigEnvironment) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  exports.AjaxBuilder = AjaxBuilder;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  var defaultHeaders = {
    "X-GOODCITY-APP-NAME": _goodcityConfigEnvironment["default"].APP.NAME,
    "X-GOODCITY-APP-VERSION": _goodcityConfigEnvironment["default"].APP.VERSION,
    "X-GOODCITY-APP-SHA": _goodcityConfigEnvironment["default"].APP.SHA
  };

  function _read(data) {
    if (typeof data == "function") {
      return data();
    }
    return data;
  }

  function AjaxPromise(url, type, authToken, data, args) {
    var language = arguments.length <= 5 || arguments[5] === undefined ? "" : arguments[5];
    var headers = arguments.length <= 6 || arguments[6] === undefined ? {} : arguments[6];

    return new _ember["default"].RSVP.Promise(function (resolve, reject) {
      headers = _ember["default"].$.extend({}, _read(defaultHeaders), headers);

      if (language) {
        headers["Accept-Language"] = language;
      }

      if (authToken) {
        headers["Authorization"] = "Bearer " + authToken;
      }

      _ember["default"].$.ajax(_ember["default"].$.extend({}, {
        type: type,
        dataType: "json",
        data: data,
        language: language,
        url: url.indexOf("http") === -1 ? _goodcityConfigEnvironment["default"].APP.SERVER_PATH + url : url,
        headers: headers,
        success: function success(data) {
          _ember["default"].run(function () {
            resolve(data);
          });
        },
        error: function error(jqXHR) {
          jqXHR.url = url;
          _ember["default"].run(function () {
            reject(jqXHR);
          });
        }
      }, args));
    });
  }

  AjaxPromise.setDefaultHeaders = function (headers) {
    defaultHeaders = headers;
  };

  exports["default"] = AjaxPromise;

  // ---- Helpers ----

  function trimmedObject(obj) {
    var trimmed = {};
    Object.keys(obj).filter(function (key) {
      return obj[key] !== undefined;
    }).forEach(function (key) {
      return trimmed[key] = obj[key];
    });
    return trimmed;
  }

  /**
   * Chainable ajax request builder
   *
   * Usage:
   *    AjaxBuilder('/offers')
   *      .withData({ some: 'data' })
   *      .post();
   *
   * @class Builder
   */

  var Builder = (function () {
    function Builder(url) {
      _classCallCheck(this, Builder);

      this.url = url;
      this.data = {};
      this.query = {};
      this.headers = _ember["default"].$.extend({}, defaultHeaders);
      this.lang = "en";
    }

    _createClass(Builder, [{
      key: "withBody",
      value: function withBody(data) {
        this.data = data;
        return this;
      }
    }, {
      key: "withQuery",
      value: function withQuery(query) {
        this.query = trimmedObject(query);
        return this;
      }
    }, {
      key: "withHeader",
      value: function withHeader(key, value) {
        this.headers[key] = value;
        return this;
      }
    }, {
      key: "withAuth",
      value: function withAuth(token) {
        return this.withHeader("Authorization", "Bearer " + token);
      }
    }, {
      key: "withLang",
      value: function withLang(lang) {
        this.lang = lang;
        return this;
      }
    }, {
      key: "do",
      value: function _do(method) {
        var _this = this;

        var qstr = Object.keys(this.query).map(function (k) {
          return k + "=" + _this.query[k];
        }).join("&");

        var sep = /\?/.test(this.url) ? "&" : "?";
        var url = qstr.length ? "" + this.url + sep + qstr : this.url;
        return AjaxPromise(url, method, null, this.data, {}, this.lang, this.headers);
      }
    }, {
      key: "getPage",
      value: function getPage(page) {
        var perPage = arguments.length <= 1 || arguments[1] === undefined ? 25 : arguments[1];

        this.query["page"] = page;
        this.query["per_page"] = perPage;
        return this.get();
      }
    }, {
      key: "get",
      value: function get() {
        return this["do"]("GET");
      }
    }, {
      key: "post",
      value: function post() {
        return this["do"]("POST");
      }
    }, {
      key: "put",
      value: function put() {
        return this["do"]("PUT");
      }
    }, {
      key: "delete",
      value: function _delete() {
        return this["do"]("DELETE");
      }
    }]);

    return Builder;
  })();

  function AjaxBuilder(url) {
    return new Builder(url);
  }
});