define("goodcity/adapters/offer", ["exports", "goodcity/adapters/application", "goodcity/config/environment"], function (exports, _goodcityAdaptersApplication, _goodcityConfigEnvironment) {
  exports["default"] = _goodcityAdaptersApplication["default"].extend({

    urlForQuery: function urlForQuery(query, modelName) {
      var slug = query.slug;

      if (modelName === "offer" && slug === "search") {
        return _goodcityConfigEnvironment["default"].APP.API_HOST_URL + "/" + _goodcityConfigEnvironment["default"].APP.NAMESPACE + "/offers/search";
      }
      return this._super.apply(this, arguments);
    }

  });
});