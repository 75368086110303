define("goodcity/controllers/offer/merge", ["exports", "ember", "goodcity/utils/ajax-promise"], function (exports, _ember, _goodcityUtilsAjaxPromise) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Controller.extend({
    sortProperties: ["updatedAt:desc"],
    arrangedContent: _ember["default"].computed.sort("offersForMerge", "sortProperties"),

    offerDonor: _ember["default"].computed.alias("offer.createdBy"),
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    apiBaseService: _ember["default"].inject.service(),

    locale: function locale(str) {
      return this.get("i18n").t(str);
    },

    actions: {
      confirmMergeOffer: function confirmMergeOffer(offer) {
        var _this = this;

        this.get("messageBox").custom(this.locale("offer.merge.message"), this.locale("offer.merge.merge"), function () {
          return _this.send("mergeOffer", offer);
        }, this.locale("cancel"));
      },

      mergeOffer: function mergeOffer(baseOffer) {
        var loadingView, offer, url, data;
        return regeneratorRuntime.async(function mergeOffer$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              loadingView = getOwner(this).lookup("component:loading").append();
              offer = this.get("offer");
              url = "/offers/" + offer.id + "/merge_offer";
              context$1$0.next = 5;
              return regeneratorRuntime.awrap(this.get("apiBaseService").PUT(url, {
                base_offer_id: baseOffer.id
              }));

            case 5:
              data = context$1$0.sent;

              loadingView.destroy();
              if (data.status) {
                this.transitionToRoute("review_offer.items", baseOffer);
              } else {
                this.get("messageBox").alert(this.locale("offer.merge.error"));
              }

            case 8:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      }
    }
  });
});