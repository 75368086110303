define('goodcity/controllers/delivery/confirm_van', ['exports', 'ember', 'goodcity/config/environment', 'goodcity/utils/ajax-promise'], function (exports, _ember, _goodcityConfigEnvironment, _goodcityUtilsAjaxPromise) {
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _ember['default'].Controller.extend({
    deliveryController: _ember['default'].inject.controller('delivery'),
    delivery: _ember['default'].computed.alias("deliveryController.model"),
    user: _ember['default'].computed.alias('delivery.offer.createdBy'),
    orderDetails: _ember['default'].computed.alias('model'),

    mobileNumber: _ember['default'].computed('user.mobile', function () {
      return this.get("user.mobile").replace(/\+852/, "");
    }),

    districtName: _ember['default'].computed('model.districtId', function () {
      var district = this.store.peekRecord("district", this.get('model.districtId'));
      return district.get('name');
    }),

    actions: {

      confirmOrder: function confirmOrder() {
        var controller = this;
        var loadingView = getOwner(this).lookup('component:loading').append();
        var orderDetails = controller.get("orderDetails");

        // contact details
        var name = _ember['default'].$("#userName").val();
        var mobile = _goodcityConfigEnvironment['default'].APP.HK_COUNTRY_CODE + _ember['default'].$("#mobile").val();
        var contactProperties = { name: name, mobile: mobile };

        // schedule details
        var scheduleProperties = { scheduledAt: orderDetails.get('pickupTime'), slotName: orderDetails.get('slot') };

        var delivery = controller.store.peekRecord("delivery", controller.get('deliveryController.model.id'));
        var offer = delivery.get('offer');

        orderDetails.setProperties({ name: name, mobile: mobile, offerId: offer.get('id') });
        var handleError = function handleError(error) {
          loadingView.destroy();throw error;
        };

        contactProperties.addressAttributes = { addressType: 'collection', districtId: orderDetails.get('districtId') };

        var properties = {
          delivery: {
            id: delivery.id,
            deliveryType: 'Gogovan',
            offerId: offer.id,
            scheduleAttributes: scheduleProperties,
            contactAttributes: contactProperties
          },
          gogovanOrder: orderDetails.toJSON()
        };

        new _goodcityUtilsAjaxPromise['default']("/confirm_delivery", "POST", this.get('session.authToken'), properties).then(function (data) {
          controller.store.pushPayload(data);
          controller.set("inProgress", false);
          offer.set('state', 'scheduled');
          loadingView.destroy();

          if (controller.get("session.isAdminApp")) {
            controller.transitionToRoute('review_offer.logistics', offer);
          } else {
            controller.transitionToRoute('offer.transport_details', offer);
          }
        })['catch'](function (error) {
          loadingView.destroy();
          throw error;
        });
      }
    }
  });
});