define('goodcity/components/visit-page-start', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    didInsertElement: function didInsertElement() {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        _ember['default'].$('html, body').scrollTop(0);
      });
    }

  });
});