define("goodcity/controllers/delivery/address_details", ["exports", "ember", "ember-i18n"], function (exports, _ember, _emberI18n) {
  exports["default"] = _ember["default"].Controller.extend({
    i18n: _ember["default"].inject.service(),
    delivery: _ember["default"].computed.alias("deliveryController.model"),
    user: _ember["default"].computed.alias("delivery.offer.createdBy"),
    selectedTerritory: null,
    selectedDistrict: null,

    initSelectedTerritories: _ember["default"].on("init", function () {
      if (this.get("selectedDistrict") === null) {
        this.set("selectedTerritory", this.get("user.address.district.territory"));
        this.set("selectedDistrict", this.get("user.address.district"));
      }
    }),

    territoriesPrompt: (0, _emberI18n.translationMacro)("all"),
    destrictPrompt: (0, _emberI18n.translationMacro)("delivery.select_district"),

    territories: _ember["default"].computed(function () {
      return this.store.peekAll("territory");
    }),

    districtsByTerritory: _ember["default"].computed("selectedTerritory", function () {
      if (this.selectedTerritory && this.selectedTerritory.id) {
        return this.selectedTerritory.get("districts").sortBy("name");
      } else {
        return this.store.peekAll("district").sortBy("name");
      }
    }),

    actions: {
      onTerritoryChange: function onTerritoryChange(value) {
        this.set("selectedTerritory", value);
        this.set("selectedDistrict", this.selectedTerritory.get("districts").sortBy("name")[0]);
      }
    }
  });
});