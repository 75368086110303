define('goodcity/controllers/delivery/drop_off_schedule', ['exports', 'ember', 'goodcity/utils/ajax-promise', 'ember-i18n'], function (exports, _ember, _goodcityUtilsAjaxPromise, _emberI18n) {
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _ember['default'].Controller.extend({

    delivery: _ember['default'].inject.controller(),
    selectedId: null,
    selectedDate: null,
    datePrompt: (0, _emberI18n.translationMacro)("gogovan.book_van.date"),
    timePrompt: (0, _emberI18n.translationMacro)("gogovan.book_van.time"),
    i18n: _ember['default'].inject.service(),

    slots: _ember['default'].computed('timeslot.[]', function () {
      return this.store.peekAll('timeslot').sortBy('name');
    }),

    available_dates: _ember['default'].computed('available_dates.[]', {
      get: function get() {
        var _this = this;

        new _goodcityUtilsAjaxPromise['default']("/available_dates", "GET", this.get('session.authToken'), { schedule_days: 40 }).then(function (data) {
          return _this.set("available_dates", data);
        });
      },
      set: function set(key, value) {
        return value;
      }
    }),

    requiredFieldsFilled: _ember['default'].computed('selectedId', 'selectedDate', function () {
      return !!(this.get('selectedId') && this.get('selectedDate'));
    }),

    actions: {
      bookSchedule: function bookSchedule() {
        var controller = this;
        var loadingView = getOwner(this).lookup('component:loading').append();
        var selectedSlot = controller.get('selectedId');
        var slotName = controller.get('slots').filterBy('id', selectedSlot.get('id')).get('firstObject.name');

        var scheduleProperties = {
          slot: selectedSlot.id,
          scheduledAt: controller.get('selectedDate'),
          slotName: slotName };

        var deliveryId = this.get('delivery.model.id');
        var delivery = this.get("store").peekRecord('delivery', deliveryId);
        var offer = delivery.get("offer");

        var properties = {
          delivery: {
            id: deliveryId,
            deliveryType: 'Drop Off',
            offerId: offer.id,
            scheduleAttributes: scheduleProperties }
        };

        new _goodcityUtilsAjaxPromise['default']("/confirm_delivery", "POST", this.get('session.authToken'), properties).then(function (data) {
          controller.store.pushPayload(data);
          controller.set("inProgress", false);
          offer.set('state', 'scheduled');
          loadingView.destroy();
          if (controller.get("session.isAdminApp")) {
            controller.transitionToRoute('review_offer.logistics', offer);
          } else {
            controller.transitionToRoute('offer.transport_details', offer);
          }
        })['catch'](function (error) {
          loadingView.destroy();
          throw error;
        });
      }
    }
  });
});