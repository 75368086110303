define("goodcity/adapters/application", ["exports", "ember", "goodcity/config/environment", "active-model-adapter", "goodcity/utils/polyfill"], function (exports, _ember, _goodcityConfigEnvironment, _activeModelAdapter, _goodcityUtilsPolyfill) {
  exports["default"] = _activeModelAdapter["default"].extend({
    namespace: _goodcityConfigEnvironment["default"].APP.NAMESPACE,
    host: _goodcityConfigEnvironment["default"].APP.API_HOST_URL,
    session: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    headers: _ember["default"].computed("session.authToken", function () {
      return {
        Authorization: "Bearer " + this.get("session.authToken"),
        "Accept-Language": this.get("session.language"),
        "X-GOODCITY-APP-NAME": _goodcityConfigEnvironment["default"].APP.NAME,
        "X-GOODCITY-APP-VERSION": _goodcityConfigEnvironment["default"].APP.VERSION,
        "X-GOODCITY-APP-SHA": _goodcityConfigEnvironment["default"].APP.SHA,
        "X-GOODCITY-APP-SHARED-SHA": _goodcityConfigEnvironment["default"].APP.SHARED_SHA
      };
    }),

    handleResponse: function handleResponse(status, headers, responseBody) {
      if (status >= 400) {
        // Errors coming from the adapter do not carry on the message of the server eror
        // The same message ends up apearing on every page whenever the backend returns an error.
        //
        // This returns a normal ember error, with the same format as before, with our own messages inside
        //
        return new DS.AdapterError([{
          detail: responseBody,
          status: status,
          title: (0, _goodcityUtilsPolyfill.__get)(responseBody, "error", this.get("i18n").t("unexpected_error"))
        }]);
      }

      return this._super.apply(this, arguments);
    }
  });
});