define("goodcity/services/printer-service", ["exports", "ember", "goodcity/utils/helpers", "goodcity/services/api-base-service"], function (exports, _ember, _goodcityUtilsHelpers, _goodcityServicesApiBaseService) {
  exports["default"] = _goodcityServicesApiBaseService["default"].extend({
    store: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),
    allAvailablePrinters: function allAvailablePrinters() {
      return this.get("store").peekAll("printer").sortBy("name").map(function (printer) {
        return printer.getProperties("name", "id");
      });
    },

    printInventoryLabel: function printInventoryLabel(pkg) {
      var tag = arguments.length <= 1 || arguments[1] === undefined ? "admin" : arguments[1];

      var id = (0, _goodcityUtilsHelpers.toID)(pkg);
      return this.GET("/packages/" + id + "/print_inventory_label", { tag: tag });
    },

    updateUserDefaultPrinter: function updateUserDefaultPrinter(printerId) {
      var _this = this;

      var defaultPrinterUser = this.__getStockPrintersUsers().get("firstObject");
      this.PUT("/printers_users/" + defaultPrinterUser.id, {
        printers_users: {
          printer_id: printerId
        }
      }).then(function (data) {
        _this.get("store").pushPayload(data);
      });
    },

    addDefaultPrinter: function addDefaultPrinter(printer) {
      var _this2 = this;

      var id = (0, _goodcityUtilsHelpers.toID)(printer);
      this.POST("/printers_users", {
        printers_users: {
          printer_id: id,
          user_id: this.get("session.currentUser.id"),
          tag: "admin"
        }
      }).then(function (data) {
        return _this2.get("store").pushPayload(data);
      });
    },

    getDefaultPrinter: function getDefaultPrinter() {
      return this.__getStockPrintersUsers().get("firstObject.printer");
    },

    __getStockPrintersUsers: function __getStockPrintersUsers() {
      return this.get("store").peekAll("printers_user").filterBy("tag", "admin");
    }
  });
});