define("goodcity/mixins/async_tasks", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {
  var getOwner = _ember["default"].getOwner;

  var getString = function getString(obj, path) {
    var val = _lodash["default"].get(obj, path);
    return val && _lodash["default"].isString(val) ? val : null;
  };

  /**
   * @enum {number}
   * @readonly
   * @memberof AsyncMixin
   * @static
   */
  var ERROR_STRATEGIES = {
    /** Will ignore errors */
    IGNORE: 1,
    /** Will display the error message in a modal */
    MODAL: 2,
    /** Will let the error go through */
    RAISE: 3
  };

  exports.ERROR_STRATEGIES = ERROR_STRATEGIES;
  exports["default"] = _ember["default"].Mixin.create({
    i18n: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    logger: _ember["default"].inject.service(),

    ERROR_STRATEGIES: ERROR_STRATEGIES,

    // ---- Helpers

    __tasksCount: 0,
    __loadingView: null,
    __modalActive: false,

    __handleError: function __handleError(err) {
      var errorStrategy = arguments.length <= 1 || arguments[1] === undefined ? ERROR_STRATEGIES.RAISE : arguments[1];

      if (errorStrategy === ERROR_STRATEGIES.RAISE) {
        return _ember["default"].RSVP.reject(err);
      }

      if (errorStrategy === ERROR_STRATEGIES.MODAL) {
        return this.showErrorPopup(err);
      }
    },

    __incrementTaskCount: function __incrementTaskCount() {
      var val = arguments.length <= 0 || arguments[0] === undefined ? 1 : arguments[0];

      this.__tasksCount += val;
      if (this.__tasksCount > 0) {
        this.showLoadingSpinner();
      } else {
        this.__tasksCount = 0;
        this.hideLoadingSpinner();
      }
    },

    __toErrorMessage: function __toErrorMessage(reason) {
      var defaultMessage = this.get("i18n").t("unexpected_error");

      if (_lodash["default"].isString(reason)) {
        return reason;
      }

      if (reason && reason.responseJSON) {
        reason = reason.responseJSON;
      }

      return getString(reason, "error") || getString(reason, "message") || getString(reason, "errors[0].message") || getString(reason, "errors[0].detail.message") || getString(reason, "errors[0]") || defaultMessage;
    },

    __run: function __run(task) {
      var res = typeof task === "function" ? task() : task;
      return _ember["default"].RSVP.resolve(res);
    },

    // --- Mixin api

    /**
     * Runs the asynchronous task, showing and hiding loading spinners accordingly
     *
     * @memberof AsyncMixin
     * @instance
     * @param {Promise|Function} task the job to run
     * @param {number} [errorStrategy] an indicator of how to handle the error
     */
    runTask: function runTask(task, errorStrategy) {
      var _this = this;

      this.__incrementTaskCount();
      return this.__run(task).then(function (res) {
        _this.__incrementTaskCount(-1);
        return res;
      })["catch"](function (err) {
        _this.__incrementTaskCount(-1);
        return _this.__handleError(err, errorStrategy);
      });
    },

    /**
     * Runs function, show a modal if it fails
     *
     * @memberof AsyncMixin
     * @instance
     * @param {Function} task the job to run
     * @param {String} message an optional message to supersede the actual error
     */
    modalCatch: function modalCatch(func) {
      var message = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];
      return regeneratorRuntime.async(function modalCatch$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.prev = 0;
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(func());

          case 3:
            context$1$0.next = 8;
            break;

          case 5:
            context$1$0.prev = 5;
            context$1$0.t0 = context$1$0["catch"](0);
            return context$1$0.abrupt("return", this.__handleError(context$1$0.t0 || message, ERROR_STRATEGIES.MODAL));

          case 8:
          case "end":
            return context$1$0.stop();
        }
      }, null, this, [[0, 5]]);
    },

    showLoadingSpinner: function showLoadingSpinner() {
      if (_ember["default"].testing) {
        return;
      }
      if (!this.__loadingView) {
        this.__loadingView = getOwner(this).factoryFor("component:loading").create().append();
      }
    },

    hideLoadingSpinner: function hideLoadingSpinner() {
      if (_ember["default"].testing) {
        return;
      }
      if (this.__loadingView) {
        this.__loadingView.destroy();
        this.__loadingView = null;
      }
    },

    showErrorPopup: function showErrorPopup(reason) {
      var _this2 = this;

      this.get("logger").error(reason);

      if (this.get("__modalActive")) {
        return;
      }

      this.set("__modalActive", true);
      this.get("messageBox").alert(this.__toErrorMessage(reason), function () {
        _this2.set("__modalActive", false);
      });
    },

    i18nAlert: function i18nAlert(key, cb) {
      this.get("messageBox").alert(this.get("i18n").t(key), cb);
    },

    raiseI18n: function raiseI18n(key) {
      throw new Error(this.get("i18n").t(key));
    }
  });
});