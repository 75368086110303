define("goodcity/routes/dashboard/index", ["exports", "ember", "goodcity/routes/authorize", "goodcity/utils/ajax-promise"], function (exports, _ember, _goodcityRoutesAuthorize, _goodcityUtilsAjaxPromise) {
  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    renderTemplate: function renderTemplate() {
      this.render(); // default template
      this.render("appMenuList", {
        into: "dashboard",
        outlet: "appMenuList",
        controller: "offers"
      });
    },

    model: function model() {
      var recentOfferParams = {
        state: "submitted",
        slug: "search", //slug: is an identifier used in offer adapter to query url `/offers/search`
        recent_offers: true,
        recent_offer_count: 5
      };
      return _ember["default"].RSVP.hash({
        offersCount: new _goodcityUtilsAjaxPromise["default"]("/offers/summary", "GET", this.session.get("authToken")),
        recentOffers: this.get("store").query("offer", recentOfferParams)
      });
    }
  });
});