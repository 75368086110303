define('goodcity/routes/delivery/confirm_van', ['exports', 'goodcity/routes/delivery/verify_offer_state'], function (exports, _goodcityRoutesDeliveryVerify_offer_state) {
  exports['default'] = _goodcityRoutesDeliveryVerify_offer_state['default'].extend({

    model: function model() {
      var ggvOrder = this.store.peekAll('gogovan_order').get('lastObject');
      return ggvOrder.get("districtId") ? ggvOrder : this.store.peekAll('gogovan_order').filterBy('districtId').get("lastObject");
    },

    afterModel: function afterModel(order) {
      if (!order) {
        this.transitionTo('delivery.book_van');
      }
    }
  });
});