define("goodcity/controllers/offer/plan_delivery", ["exports", "ember", "goodcity/utils/ajax-promise"], function (exports, _ember, _goodcityUtilsAjaxPromise) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Controller.extend({
    offerController: _ember["default"].inject.controller("offer"),
    logger: _ember["default"].inject.service(),
    offerId: _ember["default"].computed.alias("offerController.model.id"),
    gogovanPriceCalculated: _ember["default"].computed.notEmpty("gogovanPrice"),
    isDiscountAvailable: false,
    couponDiscount: 0,
    priceWithDiscount: 0,

    offer: _ember["default"].computed("offerId", function () {
      return this.store.peekRecord("offer", this.get("offerId"));
    }),

    gogovanPrice: _ember["default"].computed("offerId", {
      get: function get() {
        var _this = this;

        var params = {
          districtId: this.get("offer.createdBy.address.district.id"),
          offerId: this.get("offerId")
        };

        new _goodcityUtilsAjaxPromise["default"]("/gogovan_orders/calculate_price", "POST", this.session.get("authToken"), params).then(function (data) {
          var coupon;
          if (data.breakdown) {
            coupon = data.breakdown.coupon_discount;
          }
          _this.set("gogovanPrice", data.base);
          if (coupon) {
            _this.set("isDiscountAvailable", true);
            _this.set("couponDiscount", coupon.value.toString().substring(1));
            _this.set("priceWithDiscount", data.total);
          } else {
            _this.set("isDiscountAvailable", false);
          }
        })["catch"](function (error) {
          return _this.get("logger").error(error);
        });

        return "";
      },
      set: function set(key, value) {
        return value;
      }
    }),

    actions: {
      startDelivery: function startDelivery(delivery_type) {
        var _this2 = this;

        var loadingView = getOwner(this).lookup("component:loading").append();
        var offerId = this.get("offerController.model.id");
        var offer = this.store.peekRecord("offer", offerId);
        var delivery = offer.get("delivery");
        if (delivery && !delivery.get("isEmpty")) {
          // Checking the internalState of the delivery record to avoid setting offer property to empty delivery record with the help of isEmpty state.
          delivery.setProperties({ offer: offer });
        } else {
          delivery = this.store.createRecord("delivery", { offer: offer });
        }

        delivery.save().then(function (delivery) {
          var route;
          switch (delivery_type) {
            case "Alternate":
              route = "delivery.book_timeslot";
              break;
            case "Gogovan":
              route = "delivery.book_van";
              break;
            case "Drop Off":
              route = "delivery.drop_off_schedule";
              break;
          }

          _this2.transitionToRoute(route, delivery, {
            queryParams: { placeOrder: true }
          });
        })["catch"](function (error) {
          delivery.unloadRecord();
          throw error;
        })["finally"](function () {
          return loadingView.destroy();
        });
      }
    }
  });
});