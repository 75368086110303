define('goodcity/controllers/delivery/book_van', ['exports', 'ember', 'goodcity/utils/ajax-promise', 'goodcity/controllers/delivery/address_details', 'ember-i18n'], function (exports, _ember, _goodcityUtilsAjaxPromise, _goodcityControllersDeliveryAddress_details, _emberI18n) {
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _goodcityControllersDeliveryAddress_details['default'].extend({
    deliveryController: _ember['default'].inject.controller('delivery'),

    selectedDate: null,
    selectedTime: null,
    speakEnglish: false,
    borrowTrolley: false,
    porterage: false,
    longerGoods: false,
    longGoodSelection: "half",

    datePrompt: (0, _emberI18n.translationMacro)("gogovan.book_van.date"),
    timePrompt: (0, _emberI18n.translationMacro)("gogovan.book_van.time"),
    i18n: _ember['default'].inject.service(),

    isSelectedVan: _ember['default'].computed("selectedGogovanOption", function () {
      return this.get("selectedGogovanOption") === "1";
    }),

    offer: _ember['default'].computed("deliveryController", {
      get: function get() {
        return this.get("deliveryController.model.offer");
      },
      set: function set(key, value) {
        return value;
      }
    }),

    available_dates: _ember['default'].computed('available_dates.[]', {
      get: function get() {
        var _this = this;

        new _goodcityUtilsAjaxPromise['default']("/available_dates", "GET", this.get('session.authToken'), { schedule_days: 120 }).then(function (data) {
          return _this.set("available_dates", data);
        });
      },
      set: function set(key, value) {
        return value;
      }
    }),

    gogovanOptions: _ember['default'].computed(function () {
      var allOptions = this.store.peekAll('gogovan_transport');
      return allOptions.rejectBy('disabled', true).sortBy('id');
    }),

    selectedGogovanOption: _ember['default'].computed('gogovanOptions', 'offer', function () {
      return this.get("offer.gogovanTransport.id") || this.get('gogovanOptions.firstObject.id');
    }),

    timeSlots: _ember['default'].computed(function () {
      var options = [];
      var slots = {
        "600": "10:00",
        "630": "10:30",
        "660": "11:00",
        "690": "11:30",
        "720": "12:00",
        "750": "12:30",
        "780": "1:00",
        "810": "1:30",
        "840": "2:00",
        "870": "2:30",
        "900": "3:00"
      };
      for (var minutes in slots) {
        var period = parseInt(minutes, 10) >= 720 ? this.locale("gogovan.book_van.pm") : this.locale("gogovan.book_van.am");
        options.push({ id: minutes, name: slots[minutes] + " " + period });
      }
      return options;
    }),

    locale: function locale(str) {
      return this.get("i18n").t(str);
    },

    actions: {
      bookVan: function bookVan() {
        var controller = this;
        var loadingView = getOwner(controller).lookup('component:loading').append();
        var selectedDate = controller.get('selectedDate');
        var deliveryId = controller.get('deliveryController.model.id');
        var delivery = controller.store.peekRecord('delivery', deliveryId);
        var gogovanOptionId = controller.get('selectedGogovanOption');

        selectedDate.setMinutes(selectedDate.getMinutes() + parseInt(controller.get('selectedTime.id'), 10));

        var requestProperties = {};
        requestProperties.pickupTime = selectedDate;
        requestProperties.slot = this.get('selectedTime.name');
        requestProperties.districtId = controller.get('selectedDistrict.id');
        requestProperties.territoryId = controller.get('selectedTerritory.id');
        requestProperties.needEnglish = controller.get("speakEnglish");
        requestProperties.needCart = controller.get("borrowTrolley");
        requestProperties.needCarry = controller.get("porterage");
        requestProperties.offerId = delivery.get('offer.id');
        requestProperties.gogovanOptionId = gogovanOptionId;

        if (this.get("isSelectedVan")) {
          requestProperties.needOver6ft = this.get("longerGoods");
          requestProperties.removeNet = this.get("longGoodSelection");
        }

        var order = controller.store.createRecord('gogovan_order', requestProperties);
        order.set('delivery', delivery);
        new _goodcityUtilsAjaxPromise['default']("/gogovan_orders/calculate_price", "POST", controller.get('session.authToken'), requestProperties).then(function (data) {
          var coupon = data.breakdown.coupon_discount;
          if (coupon) {
            var discount = coupon.value.toString();
            order.set("isDiscountAvailable", true);
            order.set("couponDiscount", discount.slice(0, 1) + "$" + discount.slice(1));
          } else {
            order.set("isDiscountAvailable", false);
          }
          order.set('baseFee', data.base);
          order.set('total', data.total);
          order.set('needEnglishFee', data.breakdown.speak_english && data.breakdown.speak_english.value);
          order.set('needCartFee', data.breakdown.borrow_carts && data.breakdown.borrow_carts.value || data.breakdown.borrow_forklift_pcs && data.breakdown.borrow_forklift_pcs.value);
          order.set('removeNetFee', data.breakdown.remove_net && data.breakdown.remove_net.value);
          loadingView.destroy();
          controller.transitionToRoute('delivery.confirm_van', { queryParams: { placeOrder: true } });
        });
      }
    }
  });
});