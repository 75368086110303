define('goodcity/components/toggle-receive-menu-list', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    click: function click() {
      var itemOptionsLink = _ember['default'].$('.options-link-open.hidden');
      if (itemOptionsLink.length) {
        _ember['default'].$('.receive-options').not('.hidden').toggleClass('hidden');
        _ember['default'].$('.options-link-open.hidden').toggleClass('hidden');
        return false;
      } else {
        return true;
      }
    }
  });
});