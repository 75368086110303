define("goodcity/controllers/offer/search_label", ["exports", "goodcity/controllers/search_label"], function (exports, _goodcityControllersSearch_label) {
  exports["default"] = _goodcityControllersSearch_label["default"].extend({
    // ----- Arguments -----
    packageType: null,
    item: null,

    //--Ajax Methods
    createItem: function createItem() {
      var _this = this;

      var item = this.get("store").createRecord("item", this.itemParams());
      this.runTask(item.save().then(function (itemResponse) {
        _this.set("item", itemResponse);
        _this.addPackage();
      }));
    },

    addPackage: function addPackage() {
      var _this2 = this;

      var pkgRecord = this.store.createRecord("package", this.packageParams());
      this.runTask(pkgRecord.save().then(function (pkg) {
        _this2.transitionToRoute("receive_package", pkg.id, {
          queryParams: { isUnplannedPackage: true }
        });
      }));
    },

    donorConditions: Ember.computed(function () {
      return this.get("store").peekAll("donorCondition");
    }),

    //--Params helpers
    itemParams: function itemParams() {
      var conditions = this.get("donorConditions");
      var defaultDonorCondition = conditions.filterBy("name", "Lightly Used").get("firstObject") || conditions.get("firstObject");
      return {
        offer: this.get("model"),
        donorCondition: defaultDonorCondition,
        state: "accepted",
        packageType: this.get("packageType")
      };
    },

    packageParams: function packageParams() {
      var item = this.get("item");
      var pkgType = this.get("packageType");
      var packageTypeId = pkgType.id;

      return {
        notes: item.get("packageType.name"),
        receivedQuantity: 1,
        packageTypeId: packageTypeId,
        packageType: pkgType,
        offerId: item.get("offer.id"),
        item: item
      };
    },

    // ----- Actions -----
    actions: {
      cancelSearch: function cancelSearch() {
        window.history.back();
      },

      assignItemLabel: function assignItemLabel(type) {
        this.set("packageType", type);
        this.createItem();
      }
    }
  });
});