define('goodcity/components/scroll-to-bottom', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    cordova: _ember['default'].inject.service(),

    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super();

      _ember['default'].run.scheduleOnce('afterRender', this, function () {

        // Scroll back to page-top on back-click
        _ember['default'].$('.sticky_title_bar').on('click', '.back', function () {
          window.scrollTo(0, 0);
        });

        // Stick Notification bell icon in header
        if (_ember['default'].$('.sticky_title_bar').length > 0) {
          _ember['default'].$('.all_unread_messages_count').addClass("fixed_to_header");
        }

        // Fixed header in iOS
        if (_this.get("cordova").isIOS()) {
          _ember['default'].$("textarea").on("touchstart", function () {
            _ember['default'].$(".sticky_title_bar").css({ "position": "absolute" });
          });

          _ember['default'].$("textarea").on("blur", function () {
            _ember['default'].$(".sticky_title_bar").css({ "position": "fixed" });
          });
        }
      });

      _ember['default'].run.scheduleOnce("afterRender", this, function () {

        var messageBox, id, scrollOffset;
        var hadUnread = _ember['default'].$(".hidden.unread_id") && _ember['default'].$(".hidden.unread_id").attr("data-name");

        // Scroll to first unread message in thread
        if (_ember['default'].$(".unread.received_message:first").length > 0) {
          id = _ember['default'].$(".unread.received_message:first").attr("id");
          messageBox = _ember['default'].$('#' + id);
          scrollOffset = messageBox.offset().top - 100;
        } else {

          // scroll to bottom
          if (_ember['default'].$(".message-textbar").length > 0) {
            scrollOffset = _ember['default'].$(document).height();
          }
        }

        var screenHeight = document.documentElement.clientHeight;
        var pageHeight = document.documentElement.scrollHeight;

        if (scrollOffset && !hadUnread && pageHeight > screenHeight) {
          window.scrollTo(0, scrollOffset);
        }

        _ember['default'].$(".hidden.unread_id").attr("data-name", id || 0);
        return true;
      });
    },

    willDestroyElement: function willDestroyElement() {
      _ember['default'].$('.all_unread_messages_count').removeClass("fixed_to_header");
    }
  });
});