define("goodcity/components/date-selector", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].TextField.extend({
    tagName: "input",
    classNames: "pickadate",
    attributeBindings: ["name", "type", "value", "id", "required", "pattern", "placeholder"],

    didInsertElement: function didInsertElement() {
      var _this = this;
      var setting = false;

      _ember["default"].run.scheduleOnce("afterRender", this, function () {
        _ember["default"].$(".pickadate").pickadate({
          format: "dd/mm/yyyy",
          monthsFull: moment.months(),
          monthsShort: moment.monthsShort(),
          weekdaysShort: moment.weekdaysShort(),
          clear: false,
          today: false,
          close: false,
          min: moment().toDate(),

          onClose: function onClose() {
            var _this2 = this;

            _ember["default"].$(document.activeElement).blur();
            if (setting) {
              return;
            }
            var date = this.get("select") && this.get("select").obj;

            if (date) {
              _this.set("selection", date);
              setting = true;
              _ember["default"].run.next(function () {
                _this2.set("select", new Date(date), { format: "dd/mm/yyyy" });
                setting = false;
              });
            }
          },

          onOpen: function onOpen() {
            var date = _this.get("selection");
            if (date) {
              date = date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3");
              this.set("select", new Date(date), { format: "dd/mm/yyyy" });
            }
          }
        });
      });
    }
  });
});