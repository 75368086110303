define("goodcity/components/add-holiday", ["exports", "ember"], function (exports, _ember) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Component.extend({
    displayUserPrompt: false,
    holidayName: "",
    invalidName: false,
    invalidDate: false,
    selectedDate: "",
    store: _ember["default"].inject.service(),

    actions: {
      displayAddHolidayForm: function displayAddHolidayForm() {
        this.set("displayUserPrompt", true);
      },

      resetForm: function resetForm() {
        this.set("holidayName", "");
        this.set("selectedDate", "");
        this.set("invalidName", false);
        this.set("invalidDate", false);
      },

      addHoliday: function addHoliday() {
        var _this = this;

        this.set("invalidName", false);
        this.set("invalidDate", false);
        var name = this.get("holidayName");
        var date = this.get("selectedDate");

        var isNameEmpty = name.trim().length === 0;
        var isDateEmpty = date.toString().trim().length === 0;

        this.set("invalidName", isNameEmpty);
        this.set("invalidDate", isDateEmpty);

        if (isNameEmpty || isDateEmpty) {
          return false;
        }

        var loadingView = getOwner(this).lookup("component:loading").append();

        var holiday = this.get("store").createRecord("holiday", {
          name: name,
          holiday: date
        });

        holiday.save()["catch"](function (error) {
          holiday.unloadRecord();
          throw error;
        })["finally"](function () {
          _this.set("displayUserPrompt", false);
          loadingView.destroy();
          _this.send("resetForm");
        });
      }
    }
  });
});