define("goodcity/routes/offer/merge", ["exports", "goodcity/routes/authorize"], function (exports, _goodcityRoutesAuthorize) {
  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    offerService: Ember.inject.service(),

    model: function model() {
      var offer = this.modelFor("offer");

      return Ember.RSVP.hash({
        offersForMerge: this.get("offerService").mergeableOffers(offer.get("id")),
        offer: offer
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model.offer);
      controller.set("offer", model.offer);
      controller.set("offersForMerge", model.offersForMerge);
    }
  });
});