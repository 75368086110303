define("goodcity/components/auto-resize-textarea", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].TextArea.extend({
    tagName: "textarea",

    attributeBindings: ["data-autoresize", "value", "name", "id", "placeholder", "maxlength", "required", "pattern"],

    valueChanged: _ember["default"].observer("value", function () {
      this.setTextareaHeight();
    }),

    didInsertElement: function didInsertElement() {
      this.setTextareaHeight();
    },

    setTextareaHeight: function setTextareaHeight() {
      if (!this.get("dataAutoresize")) {
        return;
      }

      var textarea = this.element;
      var offset = textarea.offsetHeight - textarea.clientHeight;

      _ember["default"].$(textarea).css("height", "auto").css("height", offset).removeAttr("data-autoresize");
    },

    callAction: function callAction(action) {
      var data = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      if (typeof action === "function") {
        return action(data);
      } else if (typeof action === "string") {
        this.sendAction(action, data);
      }
    },

    input: function input() {
      this.callAction(this.get("changeAction"));
    },

    click: function click() {
      this.callAction(this.get("clickAction"));
    },

    focusOut: function focusOut() {
      this.callAction(this.get("focusOutAction"));
    }
  });
});