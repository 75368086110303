define('goodcity/models/designation', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports['default'] = _emberDataModel['default'].extend({

    status: (0, _emberDataAttr['default'])('string'),
    createdAt: (0, _emberDataAttr['default'])('date'),
    recentlyUsedAt: (0, _emberDataAttr['default'])('date'),
    code: (0, _emberDataAttr['default'])('string'),
    activity: (0, _emberDataAttr['default'])('string'),
    description: (0, _emberDataAttr['default'])('string'),
    detailType: (0, _emberDataAttr['default'])('string'),
    detailId: (0, _emberDataAttr['default'])('number'),

    ordersPackages: (0, _emberDataRelationships.hasMany)('ordersPackages', { async: false }),

    isLocalOrder: _ember['default'].computed.equal('detailType', 'LocalOrder'),

    designatedOrdersPackages: _ember['default'].computed('ordersPackages.@each.state', function () {
      return this.get("ordersPackages").filterBy('state', "designated");
    }),

    dispatchedOrdersPackages: _ember['default'].computed('ordersPackages.@each.state', function () {
      return this.get("ordersPackages").filterBy('state', "dispatched");
    }),

    designatedItems: _ember['default'].computed('items.@each.sentOn', function () {
      return this.get("items").filterBy('sentOn', null);
    }),

    isInactive: _ember['default'].computed('status', function () {
      return ["Sent", "Cancelled", "Closed"].indexOf(this.get("status")) >= 0;
    })

  });
});