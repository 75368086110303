define("goodcity/mixins/back_navigator", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Mixin.create({
    history: [],

    hasHistory: _ember["default"].computed("history.length", function () {
      return this.get("history.length") > 1;
    }),

    actions: {
      togglePath: function togglePath(path) {
        this.get("history").pushObject(this.get("currentPath"));

        if (this.get("target.currentPath") === path) {
          if (this.get("hasHistory")) {
            this.get("history").popObject();
            window.history.back();
          } else {
            this.transitionToRoute("dashboard");
          }
        } else {
          this.transitionToRoute(path);
        }
      }
    }
  });
});