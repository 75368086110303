define("goodcity/components/receive-item", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    tagName: "li",
    classNameBindings: ["hidden"],
    itemId: null,
    pState: null, // experienced initial value of 'inBuffer' on staging if name is state
    store: _ember["default"].inject.service(),
    hidden: _ember["default"].computed.empty("packages"),
    hasMultiplePackages: _ember["default"].computed.gte("packages.length", 2),

    item: _ember["default"].computed("itemId", function () {
      return this.get("store").peekRecord("item", this.get("itemId"));
    }),

    packages: _ember["default"].computed("pState", "item", "item.packages.@each.state", function () {
      var itemPackages = this.get("item.packages");
      return itemPackages && itemPackages.filterBy("state", this.get("pState"));
    })
  });
});