define("goodcity/services/sharing-service", ["exports", "ember", "goodcity/services/api-base-service", "goodcity/config/environment", "goodcity/utils/helpers", "goodcity/models/shareable"], function (exports, _ember, _goodcityServicesApiBaseService, _goodcityConfigEnvironment, _goodcityUtilsHelpers, _goodcityModelsShareable) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports["default"] = _goodcityServicesApiBaseService["default"].extend({
    store: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),
    offerService: _ember["default"].inject.service(),

    init: function init() {
      this.useBaseUrl(_goodcityConfigEnvironment["default"].APP.SERVER_PATH_V2);
    },

    /**
     * Loads shareable records
     *
     * @param {string} type
     * @param {string|string[]} ids
     * @returns {Promise<Shareable[]>}
     */
    loadShareable: function loadShareable(type, ids) {
      ids = Array.isArray(ids) ? ids : [ids];

      return this.get("store").query("shareable", {
        resource_type: type,
        resource_id: ids.join(",")
      });
    },

    loadOfferShareables: function loadOfferShareables(offer) {
      var offerService, _ref, _ref2, some, more;

      return regeneratorRuntime.async(function loadOfferShareables$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            offerService = this.get("offerService");
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(_ember["default"].RSVP.all([this.loadShareable(_goodcityModelsShareable.SHAREABLE_TYPES.OFFER, offer.get("id")), this.loadShareable(_goodcityModelsShareable.SHAREABLE_TYPES.PACKAGE, offerService.packagesOf(offer).map(_goodcityUtilsHelpers.toID))]));

          case 3:
            _ref = context$1$0.sent;
            _ref2 = _slicedToArray(_ref, 2);
            some = _ref2[0];
            more = _ref2[1];
            return context$1$0.abrupt("return", [].concat(_toConsumableArray(some.toArray()), _toConsumableArray(more.toArray())));

          case 8:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    // flatten
    share: function share(type, id) {
      var props = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      var shareable = this.get("store").peekAll("shareable").filterBy("resourceType", type).findBy("resourceId", id);

      shareable = shareable || this.get("store").createRecord("shareable", {
        resourceId: id,
        resourceType: type
      });

      shareable.setProperties(props);

      if (Object.keys(shareable.changedAttributes()).length === 0) {
        return _ember["default"].RSVP.resolve(shareable);
      }

      return shareable.save();
    },

    unshare: function unshare(type, ids) {
      return regeneratorRuntime.async(function unshare$(context$1$0) {
        var _this = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            ids = Array.isArray(ids) ? ids : [ids];

            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.DELETE("/shareables/unshare", {
              resource_type: type,
              resource_id: ids.join(",")
            }));

          case 3:

            this.get("store").peekAll("shareable").filterBy("resourceType", type).forEach(function (sh) {
              var unload = ids.find(function (id) {
                return id === sh.get("resourceId");
              });

              if (unload) {
                _this.get("store").unloadRecord(sh);
              }
            });

          case 4:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    unshareOffer: function unshareOffer(offer) {
      var offerService;
      return regeneratorRuntime.async(function unshareOffer$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            offerService = this.get("offerService");
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(_ember["default"].RSVP.all([this.unshare(_goodcityModelsShareable.SHAREABLE_TYPES.OFFER, offer.get("id")), this.unshare(_goodcityModelsShareable.SHAREABLE_TYPES.PACKAGE, offerService.packagesOf(offer).map(_goodcityUtilsHelpers.toID))]));

          case 3:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    }
  });
});