define('goodcity/models/delivery', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {

  var attr = _emberData['default'].attr,
      belongsTo = _emberData['default'].belongsTo;

  exports['default'] = _emberData['default'].Model.extend({
    start: attr('date'),
    finish: attr('date'),
    deliveryType: attr('string'),

    offer: belongsTo('offer', { async: false }),
    contact: belongsTo('contact', { async: false }),
    schedule: belongsTo('schedule', { async: false }),
    gogovanOrder: belongsTo('gogovan_order', { async: false }),

    isGogovan: _ember['default'].computed.equal("deliveryType", "Gogovan"),
    isDropOff: _ember['default'].computed.equal("deliveryType", "Drop Off"),
    isAlternate: _ember['default'].computed.equal("deliveryType", "Alternate"),
    wasDropOff: _ember['default'].computed.notEmpty('schedule.slot'),
    hasGGVorder: _ember['default'].computed.and('isGogovan', 'gogovanOrder'),

    noDropOff: _ember['default'].computed('deliveryType', function () {
      return this.get('deliveryType') !== 'Drop Off';
    }),

    noGogovan: _ember['default'].computed('deliveryType', function () {
      return this.get('deliveryType') !== 'Gogovan';
    }),

    completedWithGogovan: _ember['default'].computed('gogovanOrder', 'gogovanOrder.status', function () {
      return this.get("isGogovan") && this.get("gogovanOrder.isCompleted");
    })
  });
});