define('goodcity/components/validatable-form', ['exports', 'ember', 'ember-cli-html5-validation/components/validatable-form'], function (exports, _ember, _emberCliHtml5ValidationComponentsValidatableForm) {

  // This component will resolve the issue with `checkValidity()` function in Android 4.4.2
  // in Samsung devices, as it always returns true in this version and causes invalid form submission.

  exports['default'] = _emberCliHtml5ValidationComponentsValidatableForm['default'].extend({
    submit: function submit() {
      var form = this.get('element');
      var allValidElements = true;

      if (form.checkValidity()) {
        $('form input, form select, form textarea').each(function () {
          allValidElements = this.validity.valid;
          if (!allValidElements) {
            _ember['default'].$(this).trigger("invalid"); // to highlight invalid field
            return false;
          }
        });
      }

      if (form.checkValidity() && allValidElements) {
        this.sendAction('action', this.get('model'));
      } else {
        this.scrollToFirstError();
      }

      return false;
    },

    // Overriding it to highlight all invalid fields in form
    scrollToFirstError: function scrollToFirstError() {
      var form = this.get('element');

      for (var i = 0; i !== form.elements.length; ++i) {
        if (!form.elements[i].validity.valid) {
          _ember['default'].$(form.elements[i]).trigger("invalid");
        }
      }
      return false;
    }

  });
});