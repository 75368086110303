define("goodcity/routes/offers_filters", ["exports", "goodcity/routes/authorize"], function (exports, _goodcityRoutesAuthorize) {
  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    deactivate: function deactivate() {
      var controller = this.controllerFor("offersFilters");
      var queryParams = controller.get("queryParams");
      queryParams.forEach(function (queryParam) {
        return controller.set(queryParam, null);
      });
    }
  });
});