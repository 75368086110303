define("goodcity/controllers/delete_account", ["exports", "ember", "goodcity/mixins/async_tasks", "goodcity/utils/ajax-promise"], function (exports, _ember, _goodcityMixinsAsync_tasks, _goodcityUtilsAjaxPromise) {
  exports["default"] = _ember["default"].Controller.extend(_goodcityMixinsAsync_tasks["default"], {
    user: _ember["default"].computed.alias("model.user"),
    application: _ember["default"].inject.controller(),

    actions: {
      cancelAccountDeletion: function cancelAccountDeletion() {
        this.transitionToRoute("my_account");
      },

      confirmDeleteAccount: function confirmDeleteAccount() {
        var _this = this;

        this.runTask(function callee$1$0() {
          var userId, data;
          return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
            while (1) switch (context$2$0.prev = context$2$0.next) {
              case 0:
                userId = this.get("user.id");
                context$2$0.next = 3;
                return regeneratorRuntime.awrap(new _goodcityUtilsAjaxPromise["default"]("/users/" + userId, "DELETE", this.get("session.authToken")));

              case 3:
                data = context$2$0.sent;

                this.get("application").send("logMeOut");

              case 5:
              case "end":
                return context$2$0.stop();
            }
          }, null, _this);
        }, _goodcityMixinsAsync_tasks.ERROR_STRATEGIES.MODAL);
      }
    }
  });
});