define("goodcity/controllers/review_item", ["exports", "ember"], function (exports, _ember) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Controller.extend({
    application: _ember["default"].inject.controller(),
    store: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    packageService: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    defaultPackage: _ember["default"].computed.alias("model.packageType"),
    item: _ember["default"].computed.alias("model"),
    cordova: _ember["default"].inject.service(),
    isPackageTypeChanged: false,

    isItemVanished: _ember["default"].computed.or("item.isDeleted", "item.isDeleting"),

    showDeleteError: _ember["default"].observer("item", "isItemVanished", function () {
      var _this2 = this;

      var currentRoute = this.get("application.currentRouteName");

      if (this.get("isItemVanished")) {
        if (currentRoute.indexOf("review_item") >= 0) {
          this.get("messageBox").alert(this.get("i18n").t("404_error"), function () {
            _this2.transitionToRoute("dashboard");
          });
        }
      }
    }),

    itemDescriptionPlaceholder: _ember["default"].computed(function () {
      return this.get("i18n").t("items.add_item.description_placeholder").string;
    }),

    formData: _ember["default"].computed("model.donorCondition", "model.donorDescription", {
      get: function get() {
        return {
          donorConditionId: this.get("model.donorCondition.id"),
          donorDescription: this.get("model.donorDescription")
        };
      },
      set: function set() {
        return {
          donorConditionId: this.get("model.donorCondition.id"),
          donorDescription: this.get("model.donorDescription")
        };
      }
    }),

    displayEditLink: _ember["default"].computed("application.currentRouteName", function () {
      return this.get("application.currentRouteName").indexOf("accept") >= 0;
    }),

    isEditing: _ember["default"].computed("item", "item.donorDescription", "item.donorCondition", {
      get: function get() {
        var item = this.get("item");
        var description = _ember["default"].$.trim(item.get("donorDescription"));
        return !(item.get("donorCondition") && description.length > 0);
      },
      set: function set(key, value) {
        return value;
      }
    }),

    canCopyItem: _ember["default"].computed("item.state", function () {
      var item = this.get("item");
      return !item.get("offer").get("isFinished") && item.get("state") != "draft";
    }),

    itemTypeId: _ember["default"].computed("defaultPackage", {
      get: function get() {
        return this.get("defaultPackage.id");
      },
      set: function set(key, value) {
        return value;
      }
    }),

    itemType: _ember["default"].computed("defaultPackage", {
      get: function get() {
        return this.get("defaultPackage");
      },
      set: function set(key, value) {
        return value;
      }
    }),

    itemTypes: _ember["default"].computed(function () {
      return this.get("store").peekAll("package_type").sortBy("name");
    }),

    actions: {
      setEditing: function setEditing(value) {
        this.set("isEditing", value);
      },

      selectPackageType: function selectPackageType() {
        this.set("packageService.isPackageTypeOverlayVisible", true);
      },

      setPackaageType: function setPackaageType(pkgType) {
        this.set("model.packageType", pkgType);
      },

      copyItem: function copyItem() {
        var loadingView = getOwner(this).lookup("component:loading").append();
        var _this = this;
        var item = _this.get("model");
        var images = item.get("images");
        var promises = [];

        var newItem = _this.get("store").createRecord("item", {
          offer: item.get("offer"),
          donorCondition: item.get("donorCondition"),
          state: "draft",
          packageType: item.get("packageType"),
          donorDescription: item.get("donorDescription")
        });

        newItem.save().then(function () {
          images.forEach(function (image) {
            var newImage = _this.get("store").createRecord("image", {
              cloudinaryId: image.get("cloudinaryId"),
              item: newItem,
              favourite: image.get("favourite")
            });
            promises.push(newImage.save());
          });

          _ember["default"].RSVP.all(promises).then(function () {
            loadingView.destroy();
            _this.transitionToRoute("item.image_editor", newItem, {
              queryParams: { forwardRoute: "review_item.accept" }
            });
          });
        });
      }
    }
  });
});