define("goodcity/components/complete-receive-offer", ["exports", "ember", "goodcity/utils/ajax-promise", "goodcity/utils/promisify"], function (exports, _ember, _goodcityUtilsAjaxPromise, _goodcityUtilsPromisify) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Component.extend({
    store: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    messageService: _ember["default"].inject.service(),
    displayUserPrompt: false,
    stopSharingAt: null,

    invalidMessage: _ember["default"].computed({
      get: function get() {
        return false;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    closeMessage: _ember["default"].computed("offer.allPackagesMissing", function () {
      var _this = this;

      var offer = this.get("offer");
      if (offer.get("allPackagesMissing")) {
        return (0, _goodcityUtilsPromisify["default"])(function () {
          return _this.get("messageService").getSystemMessage({
            guid: "review-offer-missing-offer-message"
          });
        });
      } else {
        return (0, _goodcityUtilsPromisify["default"])(function () {
          return _this.get("messageService").getSystemMessage({
            guid: "review-offer-receive-offer-message"
          });
        });
      }
    }),

    actions: {
      confirmCloseOffer: function confirmCloseOffer() {
        this.set("displayUserPrompt", true);
      },

      closeOffer: function closeOffer() {
        var _this2 = this;

        var closeOfferMessage = "";
        if (this.get("offer.createdById")) {
          closeOfferMessage = this.get("closeMessage").content || "";
          if (closeOfferMessage.trim().length === 0) {
            this.set("invalidMessage", true);
            return false;
          }
        }

        this.set("invalidMessage", false);

        var loadingView = getOwner(this).lookup("component:loading").append();
        var offerId = this.get("offer.id");

        var expressionMatch = closeOfferMessage.match(/\[(.*?)\]/);
        if (expressionMatch) {
          var expression = expressionMatch[1];

          var _expression$split = expression.split("|");

          var _expression$split2 = _slicedToArray(_expression$split, 2);

          var mainText = _expression$split2[0];
          var replaceText = _expression$split2[1];

          if (replaceText === "feedback_form") {
            closeOfferMessage = closeOfferMessage.replace("[" + expression + "]", "<a href='https://crossroads-foundation.formstack.com/forms/goods_donor_survey?field114124226=" + offerId + "'>" + mainText + "</a>");
          }
        }
        var url = "/offers/" + offerId + "/receive_offer";

        new _goodcityUtilsAjaxPromise["default"](url, "PUT", this.get("session.authToken"), {
          close_offer_message: closeOfferMessage,
          sharing_expires_at: this.get("stopSharingAt")
        }).then(function (data) {
          _this2.get("store").pushPayload(data);
        })["finally"](function () {
          loadingView.destroy();
        });
      }
    }
  });
});