define("goodcity/controllers/item/edit_images", ["exports", "ember", "ember-i18n", "goodcity/config/environment"], function (exports, _ember, _emberI18n, _goodcityConfigEnvironment) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Controller.extend({
    queryParams: ["isUnplannedPackage"],
    isUnplannedPackage: false,
    offerController: _ember["default"].inject.controller("offer"),
    offer: _ember["default"].computed.alias("offerController.model"),
    item: _ember["default"].computed.alias("model"),
    session: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    cordova: _ember["default"].inject.service(),
    offerId: null,
    itemId: null,
    packageId: null,
    noImage: _ember["default"].computed.empty("item.images"),
    previewImage: null,
    addPhotoLabel: (0, _emberI18n.translationMacro)("edit_images.add_photo"),
    isReady: false,
    isExpanded: false,
    backBtnVisible: true,
    loadingPercentage: (0, _emberI18n.translationMacro)("edit_images.image_uploading"),
    uploadedFileDate: null,
    previousRoute: null,

    initActionSheet: function initActionSheet(onSuccess) {
      return window.plugins.actionsheet.show({
        buttonLabels: [this.locale("edit_images.upload").string, this.locale("edit_images.camera").string, this.locale("edit_images.cancel").string]
      }, function (buttonIndex) {
        if (buttonIndex === 1) {
          navigator.camera.getPicture(onSuccess, null, {
            targetHeight: 2000,
            targetWidth: 2000,
            quality: 90,
            destinationType: navigator.camera.DestinationType.DATA_URL,
            sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY
          });
        }
        if (buttonIndex === 2) {
          navigator.camera.getPicture(onSuccess, null, {
            correctOrientation: true,
            targetHeight: 2000,
            targetWidth: 2000,
            quality: 90,
            destinationType: navigator.camera.DestinationType.DATA_URL,
            sourceType: navigator.camera.PictureSourceType.CAMERA
          });
        }
        if (buttonIndex === 3) {
          window.plugins.actionsheet.hide();
        }
      });
    },

    "package": _ember["default"].computed("packageId", function () {
      return this.get("store").peekRecord("package", this.get("packageId"));
    }),

    previewMatchesFavourite: _ember["default"].computed("previewImage", "favouriteImage", function () {
      return this.get("previewImage") === this.get("favouriteImage");
    }),

    images: _ember["default"].computed("item.images.[]", function () {
      //The reason for sorting is because by default it's ordered by favourite
      //then id order. If another image is made favourite then deleted the first image
      //by id order is made favourite which can be second image in list which seems random.

      //Sort by id ascending except place new images id = 0 at end
      return (this.get("item.images") || _ember["default"].A()).filterBy("itemId").toArray().sort(function (a, b) {
        a = parseInt(a.get("id"), 10);
        b = parseInt(b.get("id"), 10);
        if (a === 0) {
          return 1;
        }
        if (b === 0) {
          return -1;
        }
        return a - b;
      });
    }),

    favouriteImage: _ember["default"].computed("item.images.@each.favourite", "package.favouriteImage", function () {
      return this.get("package") ? this.get("package.favouriteImage") : this.get("images").filterBy("favourite").get("firstObject");
    }),

    initPreviewImage: _ember["default"].on("init", _ember["default"].observer("package", "item", "item.images.[]", function () {
      var path = history.state ? history.state.path : "";
      this.set("previousRoute", path);
      var image = this.get("package.image") || this.get("item.displayImage");
      if (image) {
        this.send("setPreview", image);
      }
    })),

    isImageOnLongTermStorage: _ember["default"].computed("previewImage", function () {
      return this.get("previewImage.isImageOnLongTermStorage");
    }),

    //css related
    previewImageBgCss: _ember["default"].computed("previewImage", "isExpanded", "previewImage.angle", {
      get: function get() {
        var _this2 = this;

        var css = this.get("instructionBoxCss");
        if (!this.get("previewImage")) {
          return css;
        }

        var imgTag = new Image();
        imgTag.onload = function () {
          var newCSS = new _ember["default"].Handlebars.SafeString(css + "background-image:url(" + _this2.get("previewImage.imageUrl") + ");" + "background-size: " + (_this2.get("isExpanded") ? "contain" : "cover") + ";");
          _this2.set("previewImageBgCss", newCSS);
        };

        imgTag.src = this.get("previewImage.imageUrl");

        return new _ember["default"].Handlebars.SafeString(css + "background-image:url('assets/images/image_loading.gif');" + "background-size: 'inherit';");
      },

      set: function set(key, value) {
        return value;
      }
    }),

    instructionBoxCss: _ember["default"].computed("previewImage", "isExpanded", function () {
      var height = _ember["default"].$(window).height() * 0.6;
      return new _ember["default"].Handlebars.SafeString("min-height:" + height + "px;");
    }),

    thumbImageCss: _ember["default"].computed(function () {
      var imgWidth = Math.min(120, _ember["default"].$(window).width() / 4 - 14);
      return new _ember["default"].Handlebars.SafeString("width:" + imgWidth + "px; height:" + imgWidth + "px;");
    }),

    noImageLink: _ember["default"].computed("noImage", function () {
      return this.get("noImage") && this.get("session.isAdminApp");
    }),

    locale: function locale(str) {
      return this.get("i18n").t(str);
    },

    createItem: function createItem(donorCondition, withoutImage, identifier) {
      var _this3 = this;

      var _this = this;
      var loadingView = getOwner(this).lookup("component:loading").append();
      var offer = this.get("offer");
      var item = this.get("store").createRecord("item", {
        offer: offer,
        donorCondition: donorCondition,
        state: "draft"
      });
      item.save().then(function () {
        if (withoutImage) {
          loadingView.destroy();
          if (_this3.get("session.isAdminApp")) {
            _this.transitionToRoute("review_item.accept", _this.get("offer"), item);
          } else {
            _this.send("onNewItem", item);
          }
        } else {
          _this3.get("store").createRecord("image", {
            cloudinaryId: identifier,
            item: item,
            favourite: true
          }).save().then(function () {
            _this.send("onNewItem", item);
            loadingView.destroy();
          });
        }
      })["catch"](function (error) {
        item.unloadRecord();
        loadingView.destroy();
        throw error;
      });
    },

    deleteOffer: function deleteOffer(loadingView) {
      var controller = this;
      var offer = this.get("offer");
      offer.destroyRecord().then(function () {
        controller.transitionToRoute("my_list.reviewing");
      })["finally"](function () {
        return loadingView.destroy();
      });
    },

    cancelItem: function cancelItem(controller, item) {
      var _this4 = this;

      var offer = item.get("offer");
      var loadingView = getOwner(controller).lookup("component:loading").append();

      if (offer.get("itemCount") === 1) {
        var delivery = offer.get("delivery");
        if (delivery) {
          this.get("messageBox").confirm(this.locale("edit_images.cancelling_item_will_cancel_offer"), function () {
            var gogovanOrder = offer.get("delivery.gogovanOrder");
            if (gogovanOrder && gogovanOrder.get("isActive")) {
              loadingView.destroy();
              controller.transitionToRoute("offer.cancel_gogovan", offer);
            } else {
              _this4.deleteOffer(loadingView);
            }
          });
        } else {
          this.deleteOffer(loadingView);
        }
      } else {
        offer.get("items").removeObject(item);
        item.destroyRecord().then(function () {
          controller.transitionToRoute("review_offer.items");
        })["finally"](function () {
          return loadingView.destroy();
        });
      }
    },

    removeImage: function removeImage(controller, item) {
      var _this5 = this;

      var img = item.get("images.firstObject");
      var loadingView = getOwner(controller).lookup("component:loading").append();
      img.deleteRecord();
      img.save().then(function (i) {
        i.unloadRecord();
        if (_this5.get("session.isAdminApp") && _this5.get("isUnplannedPackage")) {
          var packageId = _this5.get("item.packages.firstObject.id");
          controller.transitionToRoute("receive_package", packageId, {
            queryParams: {
              isUnplannedPackage: true
            }
          });
        } else {
          controller.transitionToRoute("item.edit_images", item);
        }
      })["finally"](function () {
        loadingView.destroy();
        _this5.set("isExpanded", false);
      });
    },

    confirmRemoveLastImage: function confirmRemoveLastImage() {
      var _this6 = this;

      var item = this.get("item");
      this.get("messageBox").custom(this.locale("edit_images.last_image_with_item"), this.locale("edit_images.cancel_item"), function () {
        return _this6.cancelItem(_this6, item);
      }, this.locale("edit_images.remove_image"), function () {
        return _this6.removeImage(_this6, item);
      });
    },

    cannotRemoveImageAlert: function cannotRemoveImageAlert() {
      this.get("messageBox").alert(this.locale("edit_images.cant_delete_last_image"));
    },

    actions: {
      next: function next() {
        var offer = this.get("offer");
        var model = this.get("model");
        if (this.get("session.isAdminApp")) {
          if (this.get("isUnplannedPackage")) {
            var packageId = this.get("item.packages.firstObject.id");
            this.transitionToRoute("receive_package", packageId, {
              queryParams: {
                isUnplannedPackage: true
              }
            });
          } else {
            this.transitionToRoute("review_item.accept", offer, model);
          }
        } else {
          this.transitionToRoute("item.edit");
        }
      },

      nextWithoutImage: function nextWithoutImage() {
        var item = this.get("item");
        if (item) {
          if (this.get("session.isAdminApp")) {
            if (this.get("isUnplannedPackage")) {
              var packageId = this.get("item.packages.firstObject.id");
              return this.transitionToRoute("receive_package", packageId, {
                queryParams: {
                  isUnplannedPackage: true
                }
              });
            }
            return this.transitionToRoute("review_item.accept", this.get("offer"), item);
          } else if (!item.get("isOffer")) {
            return this.transitionToRoute("item.edit", item.get("id"));
          }
        }
        var defaultDonorCondition = this.get("store").peekAll("donorCondition").sortBy("id").get("firstObject");
        this.createItem(defaultDonorCondition, true);
      },

      back: function back() {
        if (this.get("session.isAdminApp")) {
          this.transitionToRoute("review_offer.items");
        } else {
          if (this.get("offer.itemCount") === 0) {
            this.transitionToRoute("offers");
          } else {
            this.transitionToRoute("offer.offer_details");
          }
        }
      },

      onNewItem: function onNewItem(item) {
        if (this.get("session.isAdminApp")) {
          this.transitionToRoute("item.edit_images", item.get("id"));
        } else {
          this.transitionToRoute("item.edit", item.get("id"));
        }
      },

      setPreview: function setPreview(image) {
        this.get("item.images").setEach("selected", false);
        image.set("selected", true);
        this.set("previewImage", image);
      },

      setFavourite: function setFavourite() {
        var _this7 = this;

        if (this.get("package")) {
          var pkg = this.get("package");
          pkg.set("favouriteImage", this.get("previewImage"));
          pkg.save()["catch"](function (error) {
            pkg.rollbackAttributes();
            throw error;
          });
        } else {
          this.get("item.images").setEach("favourite", false);
          this.get("previewImage").set("favourite", true);
          this.get("previewImage").save()["catch"](function (error) {
            _this7.get("item.images").forEach(function (img) {
              return img.rollbackAttributes();
            });
            throw error;
          });
        }
      },

      deleteImage: function deleteImage() {
        var _this8 = this;

        if (this.get("item.images.length") === 1) {
          this.get("session.isAdminApp") ? this.confirmRemoveLastImage() : this.cannotRemoveImageAlert();
          return;
        } else {
          this.get("messageBox").confirm(this.get("i18n").t("edit_images.delete_confirm"), function () {
            var loadingView = getOwner(_this8).lookup("component:loading").append();
            var img = _this8.get("previewImage");
            img.deleteRecord();
            img.save().then(function (i) {
              i.unloadRecord();
              _this8.initPreviewImage();
              if (!_this8.get("favouriteImage")) {
                _this8.send("setFavourite");
              }
            })["catch"](function (error) {
              img.rollbackAttributes();
              throw error;
            })["finally"](function () {
              return loadingView.destroy();
            });
          });
        }
      },

      expandImage: function expandImage() {
        this.toggleProperty("isExpanded");
      },

      //file upload
      triggerUpload: function triggerUpload() {
        // For Cordova application
        if (_goodcityConfigEnvironment["default"].cordova.enabled) {
          var onSuccess = (function (_this) {
            return function (path) {
              console.log(path);
              var dataURL = "data:image/jpg;base64," + path;

              $("input[type='file']").fileupload("option", "formData").file = dataURL;
              $("input[type='file']").fileupload("add", { files: [dataURL] });
            };
          })(this);

          this.initActionSheet(onSuccess);
        } else {
          // On the browser
          if (this.get("item.isOffer")) {
            // On the offer page we allow the user to move to the item edition page first
            // and then upload images from there
            return this.send("nextWithoutImage");
          }
          // On the item edition page we trigger the file selection
          if (navigator.userAgent.match(/iemobile/i)) {
            //don't know why but on windows phone need to click twice in quick succession
            //for dialog to appear
            _ember["default"].$("#photo-list input[type='file']").click().click();
          } else {
            _ember["default"].$("#photo-list input[type='file']").trigger("click");
          }
        }
      },

      uploadReady: function uploadReady() {
        this.set("isReady", true);
      },

      uploadStart: function uploadStart(e, data) {
        this.set("uploadedFileDate", data);
        _ember["default"].$(".loading-image-indicator").show();
      },

      cancelUpload: function cancelUpload() {
        if (this.get("uploadedFileDate")) {
          this.get("uploadedFileDate").abort();
        }
      },

      uploadProgress: function uploadProgress(e, data) {
        e.target.disabled = true; // disable image-selection
        var progress = parseInt(data.loaded / data.total * 100, 10) || 0;
        this.set("addPhotoLabel", progress + "%");
        this.set("loadingPercentage", this.get("i18n").t("edit_images.image_uploading") + progress + "%");
      },

      uploadComplete: function uploadComplete(e) {
        e.target.disabled = false; // enable image-selection
        this.set("uploadedFileDate", null);
        _ember["default"].$(".loading-image-indicator.hide_image_loading").hide();
        this.set("addPhotoLabel", this.get("i18n").t("edit_images.add_photo"));
        this.set("loadingPercentage", this.get("i18n").t("edit_images.image_uploading"));
      },

      uploadSuccess: function uploadSuccess(e, data) {
        var identifier = data.result.version + "/" + data.result.public_id + "." + data.result.format;
        var item = this.get("item");
        if (!item || this.get("item.isOffer")) {
          var defaultDonorCondition = this.get("store").peekAll("donorCondition").sortBy("id").get("firstObject");
          this.createItem(defaultDonorCondition, false, identifier);
        } else {
          var favourite = item.get("images.length") === 0;
          var img = this.get("store").createRecord("image", {
            cloudinaryId: identifier,
            item: this.get("item"),
            favourite: favourite
          });
          img.save()["catch"](function (error) {
            img.unloadRecord();
            throw error;
          });
        }
      },

      rotateImageRight: function rotateImageRight() {
        var angle = this.get("previewImage.angle");
        angle = (angle + 90) % 360;
        this.send("rotateImage", angle);
      },

      rotateImageLeft: function rotateImageLeft() {
        var angle = this.get("previewImage.angle");
        angle = (angle ? angle - 90 : 270) % 360;
        this.send("rotateImage", angle);
      },

      rotateImage: function rotateImage(angle) {
        var image = this.get("previewImage");
        image.set("angle", angle);
        _ember["default"].run.debounce(this, this.saveImageRotation, image, 400);
      }
    },

    saveImageRotation: function saveImageRotation(image) {
      image.save();
    }
  });
});