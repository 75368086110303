define("goodcity/routes/review_offer/share/index", ["exports", "goodcity/routes/authorize"], function (exports, _goodcityRoutesAuthorize) {
  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    model: function model() {
      return this.store.query("offerResponse", {
        offer_response: {
          offer_id: this.modelFor("offer").id
        }
      });
    },

    afterModel: function afterModel(model) {
      var _this = this;

      model.content.map(function (offerResponse) {
        _this.store.query("message", {
          messageable_type: "OfferResponse",
          messageable_id: offerResponse.id
        });
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("offer", this.modelFor("offer"));
    }
  });
});