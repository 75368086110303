define("goodcity/routes/offer/search_label", ["exports"], function (exports) {
  exports["default"] = Ember.Route.extend({
    model: function model() {
      return this.modelFor("offer");
    },

    renderTemplate: function renderTemplate() {
      this.render("search_label", { controller: "offer.search_label" });
    }
  });
});