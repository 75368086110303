define("goodcity/routes/review_offer/share", ["exports", "goodcity/utils/helpers", "goodcity/routes/authorize"], function (exports, _goodcityUtilsHelpers, _goodcityRoutesAuthorize) {
  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    sharingService: Ember.inject.service(),

    model: function model() {
      var offer;
      return regeneratorRuntime.async(function model$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            offer = this.modelFor("offer");
            return context$1$0.abrupt("return", this.get("sharingService").loadOfferShareables(offer));

          case 2:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.set("offer", this.modelFor("offer"));
    }
  });
});