define("goodcity/models/location", ["exports", "ember-data", "ember", "ember-data/relationships"], function (exports, _emberData, _ember, _emberDataRelationships) {

  var attr = _emberData["default"].attr;

  exports["default"] = _emberData["default"].Model.extend({
    building: attr("string"),
    area: attr("string"),
    name: _ember["default"].computed("building", "area", function () {
      return this.get("building") + this.get("area");
    })
  });
});