define("goodcity/components/pro-forma-message-overlay", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {

  var SYSTEM = "SYSTEM";

  exports["default"] = _ember["default"].Component.extend({
    messageService: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set("isSelected", true);
      this.set("selected", "canned");
    },

    onSearchTextChange: _ember["default"].observer("searchText", function () {
      _ember["default"].run.debounce(this, this.reloadResults, 500);
    }),

    refreshDisplayResult: _ember["default"].observer("messageService.isAddMessageVisible", function () {
      if (!this.get("messageService.isAddMessageVisible")) {
        this.reloadResults();
      }
    }),

    didRender: function didRender() {
      if (this.get("messageService.isProFormaMessageVisible")) {
        this.set("displayResults", true);
      }
    },

    reloadResults: function reloadResults() {
      var _this = this;

      this.set("displayResults", false);
      _ember["default"].run.debounce(this, function () {
        return _this.set("displayResults", true);
      }, 500);
    },

    hasSearchText: _ember["default"].computed("searchText", function () {
      return this.get("searchText") && this.get("searchText").trim().length;
    }),

    actions: {
      loadMoreCannedMessages: function loadMoreCannedMessages() {
        var params = {
          searchText: this.get("searchText")
        };
        if (this.get("selected") == "system") {
          params.message_type = SYSTEM;
        }
        return this.get("store").query("canned_response", params);
      },

      addMessage: function addMessage() {
        this.set("messageService.isAddMessageVisible", true);
      },

      closeOverlay: function closeOverlay() {
        this.set("messageService.isProFormaMessageVisible", false);
      },

      editMessage: function editMessage(message, lang) {
        this.set("messageService.editMessage.messageId", message.id);
        this.set("messageService.editMessage.language", lang);
        this.send("addMessage");
      },

      cancel: function cancel() {
        this.set("open", false);
      },

      selectTab: function selectTab() {
        var selectedTab = arguments.length <= 0 || arguments[0] === undefined ? "canned" : arguments[0];

        this.set("selected", selectedTab);
        if (selectedTab == "canned") {
          this.set("isSelected", true);
        } else {
          this.set("isSelected", false);
        }
      }
    }
  });
});