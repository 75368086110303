define("goodcity/models/offer", ["exports", "ember", "ember-data"], function (exports, _ember, _emberData) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

  var attr = _emberData["default"].attr,
      hasMany = _emberData["default"].hasMany,
      belongsTo = _emberData["default"].belongsTo;

  function createCounterOf(collection) {
    var opts = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
    var filter = opts.filter;
    var fallbackProp = opts.fallbackProp;

    var computer = function computer() {
      var records = this.get(collection);
      var hasRecords = records && records.length;

      if (!hasRecords) {
        return fallbackProp ? this.getWithDefault(fallbackProp, 0) : 0;
      }
      if (filter) {
        var _filter = _slicedToArray(filter, 2);

        var key = _filter[0];
        var value = _filter[1];

        records = records.filterBy(key, value);
      }
      return records.length;
    };

    if (fallbackProp) {
      return _ember["default"].computed(fallbackProp, collection + ".@each.state", computer);
    }
    return _ember["default"].computed(collection + ".@each.state", computer);
  }

  exports["default"] = _emberData["default"].Model.extend({
    cloudinaryUtils: _ember["default"].inject.service(),

    language: attr("string"),
    state: attr("string", { defaultValue: "draft" }),
    origin: attr("string"),
    stairs: attr("boolean"),
    parking: attr("boolean"),
    saleable: attr("boolean"),
    isShared: attr("boolean"),
    estimatedSize: attr("string"),
    notes: attr("string"),
    createdById: attr("string"),
    createdAt: attr("date"),
    updatedAt: attr("date"),
    submittedAt: attr("date"),
    cancelledAt: attr("date"),
    state_event: attr("string"),
    reviewedAt: attr("date"),
    receivedAt: attr("date"),
    reviewCompletedAt: attr("date"),
    deliveredBy: attr("string"),
    startReceivingAt: attr("date"),
    cancelReason: attr("string"),
    inactiveAt: attr("date"),
    displayImageCloudinaryId: attr("string"),
    companyId: attr("string"),
    sharing_expires_at: attr("string"),
    districtId: attr("string"),
    district: belongsTo("district", { async: false }),

    gogovanTransport: belongsTo("gogovan_transport", { async: false }),
    crossroadsTransport: belongsTo("crossroads_transport", { async: false }),
    cancellationReason: belongsTo("cancellation_reason", { async: false }),
    company: belongsTo("company", { async: false }),

    items: hasMany("item", { async: false }),
    messages: hasMany("message", { async: true }),

    delivery: belongsTo("delivery", { async: false }),
    createdBy: belongsTo("user", { async: false }),
    reviewedBy: belongsTo("user", { async: false }),
    closedBy: belongsTo("user", { async: false }),
    receivedBy: belongsTo("user", { async: false }),

    // User details
    userName: attr("string"),
    userPhone: attr("string"),

    companyName: _ember["default"].computed("company", function () {
      return this.get("company.name");
    }),

    crossroadsTruckCost: _ember["default"].computed("crossroadsTransport", function () {
      return this.get("crossroadsTransport.cost");
    }),

    itemCount: _ember["default"].computed("items.@each.state", function () {
      return this.get("items").rejectBy("state", "draft").length;
    }),

    packages: _ember["default"].computed("items.@each.packages", function () {
      var res = [];
      this.get("items").filterBy("state", "accepted").forEach(function (i) {
        return res = res.concat(i.get("packages").toArray());
      });
      return res;
    }),

    itemPackages: _ember["default"].computed.alias("packages"),

    // Package types
    expectingPackagesCount: attr("number"),
    missingPackagesCount: attr("number"),
    receivedPackagesCount: attr("number"),
    computedExpectingPackageCount: createCounterOf("packages", {
      filter: ["state", "expecting"],
      fallbackProp: "expectingPackagesCount"
    }),
    computedMissingPackageCount: createCounterOf("packages", {
      filter: ["state", "missing"],
      fallbackProp: "missingPackagesCount"
    }),
    computedReceivedPackageCount: createCounterOf("packages", {
      filter: ["state", "received"],
      fallbackProp: "receivedPackagesCount"
    }),

    // Item types
    approvedItems: _ember["default"].computed.filterBy("items", "state", "accepted"),
    rejectedItems: _ember["default"].computed.filterBy("items", "state", "rejected"),
    submittedItems: _ember["default"].computed.filterBy("items", "state", "submitted"),
    acceptedItemsCount: attr("number"),
    rejectedItemsCount: attr("number"),
    submittedItemsCount: attr("number"),
    computedSubmittedItemsCount: createCounterOf("submittedItems", {
      fallbackProp: "submittedItemsCount"
    }),
    computedApprovedItemsCount: createCounterOf("approvedItems", {
      fallbackProp: "acceptedItemsCount"
    }),
    computedRejectedItemsCount: createCounterOf("rejectedItems", {
      fallbackProp: "rejectedItemsCount"
    }),

    isDraft: _ember["default"].computed.equal("state", "draft"),
    isInactive: _ember["default"].computed.equal("state", "inactive"),
    isSubmitted: _ember["default"].computed.equal("state", "submitted"),
    isScheduled: _ember["default"].computed.equal("state", "scheduled"),
    isUnderReview: _ember["default"].computed.equal("state", "under_review"),
    isReviewed: _ember["default"].computed.equal("state", "reviewed"),
    isClosed: _ember["default"].computed.equal("state", "closed"),
    isReceived: _ember["default"].computed.equal("state", "received"),
    isReceiving: _ember["default"].computed.equal("state", "receiving"),
    isCancelled: _ember["default"].computed.equal("state", "cancelled"),
    preventNewItem: _ember["default"].computed.alias("isFinished"),

    hasReceived: _ember["default"].computed.or("isReceived", "isReceiving"),
    isReviewing: _ember["default"].computed.or("isUnderReview", "isReviewed"),
    adminCurrentOffer: _ember["default"].computed.or("isReviewing", "isScheduled"),
    nonSubmittedOffer: _ember["default"].computed.or("isDraft", "isInactive"),
    closedOrCancelled: _ember["default"].computed.or("isClosed", "isCancelled"),

    needReview: _ember["default"].computed("isUnderReview", "isSubmitted", "isClosed", function () {
      return this.get("isUnderReview") || this.get("isSubmitted") || this.get("isClosed");
    }),

    isFinished: _ember["default"].computed("isClosed", "isReceived", "isCancelled", "isInactive", function () {
      return this.get("isClosed") || this.get("isReceived") || this.get("isCancelled") || this.get("isInactive");
    }),

    canMerged: _ember["default"].computed("isSubmitted", "isUnderReview", "isReviewed", function () {
      return this.get("isSubmitted") || this.get("isUnderReview") || this.get("isReviewed");
    }),

    canReopen: _ember["default"].computed("isClosed", "isCancelled", function () {
      return this.get("isClosed") || this.get("isCancelled");
    }),

    canResumeReceiving: _ember["default"].computed("isReceived", function () {
      return this.get("isReceived");
    }),

    baseForMerge: _ember["default"].computed("isSubmitted", "isUnderReview", "isReviewed", "isScheduled", function () {
      return this.get("isSubmitted") || this.get("isUnderReview") || this.get("isReviewed") || this.get("isScheduled");
    }),

    activeItems: _ember["default"].computed("items.@each.state", function () {
      return this.get("items").rejectBy("state", "draft");
    }),

    nonEmptyOffer: _ember["default"].computed("items.[]", function () {
      return this.get("itemCount") > 0;
    }),

    allItemsReviewed: _ember["default"].computed("items.@each.state", function () {
      var reviewedItems = this.get("activeItems").filterBy("state", "submitted");
      return reviewedItems.get("length") === 0;
    }),

    readyForSchedule: _ember["default"].computed("state", "allItemsReviewed", function () {
      return (this.get("isUnderReview") || this.get("isSubmitted")) && this.get("allItemsReviewed");
    }),

    allItemsRejected: _ember["default"].computed("items.@each.state", "needReview", function () {
      return this.get("needReview") && this.get("computedRejectedItemsCount") === this.get("itemCount");
    }),

    displayImageUrl: _ember["default"].computed("displayImageCloudinaryId", "items.@each.displayImageUrl", function () {
      var dImageId = this.get("displayImageCloudinaryId");
      if (dImageId) {
        return this.get("cloudinaryUtils").generateThumbnailUrl(dImageId);
      }
      return this.get("activeItems.firstObject.displayImageUrl") || "assets/images/default_item.jpg";
    }),

    isCharitableSale: _ember["default"].computed("saleable", function () {
      return this.get("saleable") ? this.locale("yes") : this.locale("no");
    }),

    isAccepted: _ember["default"].computed("isReviewed", "computedApprovedItemsCount", function () {
      return this.get("computedApprovedItemsCount") > 0 && this.get("isReviewed");
    }),

    donor: _ember["default"].computed("createdById", function () {
      return this.get("createdById") && this.get("createdBy");
    }),

    getOfferStatus: function getOfferStatus(state) {
      switch (state) {
        case "draft":
          return this.locale("offers.index.complete_offer");
        case "under_review":
          return this.locale("offers.index.in_review");
        case "submitted":
          return this.locale("offers.index.awaiting_review");
        case "reviewed":
          return this.locale("offers.index.arrange_transport");
        case "scheduled":
          return this.scheduledStatus();
        case "closed":
          return this.locale("offers.index.closed");
        case "received":
          return this.locale("offers.index.received");
        case "receiving":
          return this.locale("offers.index.receiving");
        case "inactive":
          return this.locale("offers.index.inactive");
      }
    },

    status: _ember["default"].computed("state", function () {
      var state = this.get("state");
      var status = this.getOfferStatus(state);
      return status;
    }),

    i18n: _ember["default"].inject.service(),

    locale: function locale(text) {
      return this.get("i18n").t(text);
    },

    statusText: _ember["default"].computed("status", "itemCount", function () {
      return this.get("nonSubmittedOffer") ? this.get("status") : this.get("status") + " (" + this.get("itemCount") + " " + this.locale("items_text") + ")";
    }),

    scheduledStatus: function scheduledStatus() {
      var deliveryType = this.get("delivery.deliveryType");
      switch (deliveryType) {
        case "Gogovan":
          return this.get("gogovan_status");
        case "Drop Off":
          return this.locale("offers.index.drop_off");
        case "Alternate":
          return this.locale("offers.index.alternate");
      }
    },

    gogovan_status: _ember["default"].computed("delivery.gogovanOrder.status", function () {
      var ggvStatus = this.get("delivery.gogovanOrder.status");
      switch (ggvStatus) {
        case "pending":
          return this.locale("offers.index.van_booked");
        case "active":
          return this.locale("offers.index.van_confirmed");
        case "completed":
          return this.locale("offers.index.picked_up");
      }
    }),

    isOffer: _ember["default"].computed("this", function () {
      return this.get("constructor.modelName") === "offer";
    }),

    // unread offer-items messages
    unreadMessagesCount: _ember["default"].computed("messages.@each.state", function () {
      return this.get("messages").filterBy("state", "unread").length;
    }),

    hasUnreadMessages: _ember["default"].computed("unreadMessagesCount", function () {
      return this.get("unreadMessagesCount") > 0;
    }),

    // unread offer-messages
    unreadOfferMessages: _ember["default"].computed("messages.@each.state", function () {
      return this.get("messages").filterBy("state", "unread").filterBy("item", null).sortBy("createdAt");
    }),

    unreadOfferMessagesCount: _ember["default"].computed("unreadOfferMessages", function () {
      var count = this.get("unreadOfferMessages.length");
      return count > 0 ? count : "";
    }),

    // unread offer-messages by donor
    hasUnreadDonorMessages: _ember["default"].computed("unreadOfferMessages", function () {
      return this.get("unreadOfferMessages").filterBy("isPrivate", false).length > 0;
    }),

    // unread offer-messages by supervisor-reviewer
    hasUnreadPrivateMessages: _ember["default"].computed("unreadOfferMessages", function () {
      return this.get("unreadOfferMessages").filterBy("isPrivate", true).length > 0;
    }),

    // recent offer message
    lastMessage: _ember["default"].computed("messages.[]", function () {
      var messages = this.get("messages").filterBy("item", null).sortBy("createdAt");
      return messages.get("length") > 0 ? messages.get("lastObject") : null;
    }),

    hasCrossroadsTransport: _ember["default"].computed("crossroadsTransport", function () {
      return this.get("crossroadsTransport") && this.get("crossroadsTransport.isVanAllowed");
    }),

    hasGogovanTransport: _ember["default"].computed("gogovanTransport", function () {
      return this.get("gogovanTransport") && !this.get("gogovanTransport.disabled");
    }),

    // display "General Messages Thread"
    displayGeneralMessages: _ember["default"].computed("isDraft", "lastMessage", function () {
      return !(this.get("isDraft") && this.get("lastMessage") === null);
    }),

    // to sort on offer-details page for updated-offer and latest-message
    latestUpdatedTime: _ember["default"].computed("lastMessage", function () {
      var value;
      switch (_ember["default"].compare(this.get("lastMessage.createdAt"), this.get("updatedAt"))) {
        case 0:
        case 1:
          value = this.get("lastMessage.createdAt");
          break;
        case -1:
          value = this.get("updatedAt");
          break;
      }
      return value;
    }),

    hasCompleteGGVOrder: _ember["default"].computed("delivery.gogovanOrder.status", function () {
      return (this.get("delivery.gogovanOrder.status") || "") === "completed";
    }),

    showOfferIcons: _ember["default"].computed("hasCompleteGGVOrder", "itemCount", "isClosed", "hasReceived", function () {
      return this.get("itemCount") > 0 && !(this.get("isClosed") || this.get("hasReceived")) && !this.get("hasCompleteGGVOrder");
    }),

    statusBarClass: _ember["default"].computed("state", function () {
      var retState = "";
      if (this.get("isSubmitted")) {
        retState = "is-submitted";
      } else if (this.get("isUnderReview")) {
        retState = "is-under-review";
      } else if (this.get("isReviewed")) {
        retState = "is-reviewed";
      } else if (this.get("isScheduled")) {
        retState = "is-scheduled";
      } else if (this.get("isClosed")) {
        retState = "is-closed";
      } else if (this.get("hasReceived")) {
        retState = "is-received";
      }
      return retState;
    }),

    showDeliveryDetails: _ember["default"].computed("state", function () {
      return this.get("isScheduled") || this.get("isReceived") || this.get("isReceiving");
    }),

    hideBookingModification: _ember["default"].computed.alias("delivery.gogovanOrder.isCompleted"),

    allPackagesMissing: _ember["default"].computed("state", "items.@each.state", "packages.@each.state", function () {
      return !this.get("allItemsRejected") && this.get("allItemsReviewed") && this.get("state") !== "received" && this.get("packages.length") > 0 && this.get("packages").filter(function (p) {
        return !p.get("item.isRejected") && p.get("state") === "missing";
      }).get("length") === this.get("packages.length");
    }),

    readyForClosure: _ember["default"].computed("state", "packages.@each.state", function () {
      return !this.get("allItemsRejected") && this.get("allItemsReviewed") && this.get("state") !== "received" && this.get("packages.length") > 0 && this.get("packages").filter(function (p) {
        return !p.get("item.isRejected") && p.get("state") === "expecting";
      }).get("length") === 0;
    }),

    timeDetail: _ember["default"].computed("state", "delivery", function () {
      var prefix = "",
          suffix = "",
          date;

      if (this.get("isSubmitted")) {
        prefix = this.locale("submitted");
        date = this.get("submittedAt");
      } else if (this.get("isUnderReview")) {
        prefix = this.get("i18n").t("review_offer.review_started_by", {
          firstName: this.get("reviewedBy.firstName"),
          lastName: this.get("reviewedBy.lastName")
        });
        date = this.get("reviewedAt");
      } else if (this.get("isReviewed")) {
        prefix = this.locale("review_offer.reviewed");
        date = this.get("reviewCompletedAt");
        suffix = this.locale("review_offer.plan_transport");
      } else if (this.get("isClosed")) {
        prefix = this.get("i18n").t("offer.closed_by", {
          firstName: this.get("closedBy.firstName"),
          lastName: this.get("closedBy.lastName")
        });
        date = this.get("reviewCompletedAt");
      } else if (this.get("isCancelled")) {
        prefix = this.get("i18n").t("offer.cancelled_by", {
          firstName: this.get("closedBy.firstName"),
          lastName: this.get("closedBy.lastName")
        });
        date = this.get("cancelledAt");
      } else if (this.get("isReceived")) {
        prefix = this.get("i18n").t("offer.received_by", {
          firstName: this.get("closedBy.firstName"),
          lastName: this.get("closedBy.lastName")
        });
        date = this.get("receivedAt");
      } else if (this.get("isReceiving")) {
        prefix = this.get("i18n").t("offer.offer_details.start_receiving_by", {
          firstName: this.get("receivedBy.firstName"),
          lastName: this.get("receivedBy.lastName")
        });
        date = this.get("startReceivingAt");
      } else if (this.get("isInactive")) {
        prefix = this.get("i18n").t("offer.offer_details.inactive");
        date = this.get("inactiveAt");
      } else if (this.get("isScheduled")) {
        if (this.get("delivery.isAlternate")) {
          prefix = this.locale("offer.offer_details.is_collection");
        } else if (this.get("delivery.isDropOff")) {
          prefix = this.locale("offer.offer_details.is_drop_off");
        } else if (this.get("delivery.isGogovan")) {
          prefix = this.get("delivery.gogovanOrder.ggvOrderStatus");
        }

        if (this.get("delivery.isGogovan")) {
          if (this.get("delivery.completedWithGogovan")) {
            date = this.get("delivery.gogovanOrder.completedAt");
          } else {
            prefix = prefix + " " + this.get("delivery.schedule.slotName");
            date = this.get("delivery.schedule.scheduledAt");
          }
        } else {
          date = this.get("delivery.schedule.scheduledAt");
          suffix = this.get("delivery.schedule.dayTime");
        }
      }
      return { prefix: prefix, date: date, suffix: suffix };
    }),

    hideCancelOfferOption: _ember["default"].computed("state", "hasCompleteGGVOrder", function () {
      return this.get("closedOrCancelled") || this.get("isReceived") || this.get("hasCompleteGGVOrder") || this.get("isReceiving");
    }),

    hideInactiveOfferOption: _ember["default"].computed("state", "hasCompleteGGVOrder", function () {
      return this.get("isFinished") || this.get("hasCompleteGGVOrder") || this.get("isReceiving");
    }),

    allowResubmit: _ember["default"].computed("isCancelled", "allItemsReviewed", function () {
      return this.get("isCancelled") && !this.get("allItemsReviewed") || this.get("isInactive");
    })
  });
});