define("goodcity/components/holiday-detail", ["exports", "ember"], function (exports, _ember) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Component.extend({
    i18n: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    isEditing: false,
    selectedDate: _ember["default"].computed.alias("day.holiday"),
    invalidName: false,
    invalidDate: false,

    actions: {
      removeHoliday: function removeHoliday(holiday) {
        var _this = this;

        this.get("messageBox").custom(this.get("i18n").t("holiday.delete_confirm"), this.get("i18n").t("yes"), function () {
          var loadingView = getOwner(_this).lookup("component:loading").append();

          holiday.deleteRecord();
          holiday.save()["catch"](function (error) {
            holiday.rollbackAttributes();
            throw error;
          })["finally"](function () {
            return loadingView.destroy();
          });
        }, this.get("i18n").t("no"));
      },

      displayEditForm: function displayEditForm() {
        this.set("isEditing", true);
      },

      hideEditForm: function hideEditForm() {
        this.get("day").rollbackAttributes();
        this.set("isEditing", false);
        this.set("invalidName", false);
        this.set("invalidDate", false);
      },

      saveHoliday: function saveHoliday() {
        var _this2 = this;

        var holiday = this.get("day");
        var isNameEmpty = holiday.get("name").trim().length === 0;
        var isDateEmpty = this.get("selectedDate").toString().trim().length === 0;

        this.set("invalidName", isNameEmpty);
        this.set("invalidDate", isDateEmpty);

        if (isNameEmpty || isDateEmpty) {
          return false;
        }

        var loadingView = getOwner(this).lookup("component:loading").append();

        holiday.save()["catch"](function (error) {
          holiday.rollbackAttributes();
          throw error;
        })["finally"](function () {
          loadingView.destroy();
          _this2.set("isEditing", false);
          _this2.set("invalidName", false);
          _this2.set("invalidDate", false);
        });
      }
    }
  });
});