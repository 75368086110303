define('goodcity/controllers/review_offer/logistics', ['exports', 'ember', 'goodcity/controllers/offer/transport_details', 'goodcity/config/environment'], function (exports, _ember, _goodcityControllersOfferTransport_details, _goodcityConfigEnvironment) {
  exports['default'] = _goodcityControllersOfferTransport_details['default'].extend({

    accepted: _ember['default'].computed.filterBy('model.items', 'state', 'accepted'),
    pendingItem: _ember['default'].computed.filterBy('model.items', 'state', 'submitted'),
    i18n: _ember['default'].inject.service(),

    crossroadsContact: _ember['default'].computed(function () {
      return _goodcityConfigEnvironment['default'].APP.CROSSROADS_CONTACT;
    }),

    ggvDriverUrl: _ember['default'].computed('model', 'model.delivery.gogovanOrder', function () {
      var language = this.get("session.language");
      var uuid = this.get("model.delivery.gogovanOrder.ggvUuid");
      var url = _goodcityConfigEnvironment['default'].ADMIN_APP_HOST_URL + "/ggv_orders/" + uuid;
      var params = [];
      if (language) {
        params.push("ln=" + language);
      }
      if (params.length) {
        url = url + "?" + params.join("&");
      }
      return url;
    })
  });
});