define('goodcity/routes/delivery/contact_details', ['exports', 'goodcity/routes/delivery/verify_offer_state'], function (exports, _goodcityRoutesDeliveryVerify_offer_state) {
  exports['default'] = _goodcityRoutesDeliveryVerify_offer_state['default'].extend({

    beforeModel: function beforeModel(params) {
      this._super(params);
      var offerId = params.params.offer.offer_id;
      var offer = this.store.peekRecord('offer', offerId);
      var delivery = offer.get("delivery");
      if (!delivery.get('schedule')) {
        this.transitionTo('delivery.book_timeslot', delivery);
      }
    }

  });
});