define("goodcity/routes/message_base", ["exports", "goodcity/routes/authorize", "ember"], function (exports, _goodcityRoutesAuthorize, _ember) {
  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    model: function model() {
      return _ember["default"].RSVP.Promise.all([this.store.query("message", {
        messageable_type: "Offer",
        messageable_id: this.modelFor("offer").get("id")
      })]);
    },

    afterModel: function afterModel() {
      var offerId = this.modelFor("offer").get("id");
      this.store.query("version", { item_id: offerId, for_offer: true });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("isActive", true);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("isActive", false);
      }
    }
  });
});