define("goodcity/controllers/internet_call_status", ["exports", "ember", "goodcity/config/environment"], function (exports, _ember, _goodcityConfigEnvironment) {
  exports["default"] = _ember["default"].Controller.extend({
    isCordovaApp: _goodcityConfigEnvironment["default"].cordova.enabled,
    activeCall: false,
    twilio_device: {},
    donorName: null,

    actions: {
      hangupCall: function hangupCall() {
        var twilioDevice = this.get("twilio_device");
        this.set("activeCall", false);
        this.get("isCordovaApp") ? twilioDevice.disconnect() : twilioDevice.disconnectAll();
      }
    }
  });
});