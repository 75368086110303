define("goodcity/controllers/review_offer", ["exports", "ember", "goodcity/mixins/async_tasks"], function (exports, _ember, _goodcityMixinsAsync_tasks) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Controller.extend(_goodcityMixinsAsync_tasks["default"], {
    application: _ember["default"].inject.controller(),
    offer: _ember["default"].computed.alias("model"),
    isStartReviewClicked: false,
    i18n: _ember["default"].inject.service(),
    offerService: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    backLinkPath: "",
    displayCompleteReviewPopup: false,
    displayCompleteReceivePopup: false,

    allShareables: _ember["default"].computed(function () {
      return this.get("store").peekAll("shareable");
    }),

    allMessages: _ember["default"].computed(function () {
      return this.get("store").peekAll("message");
    }),

    allOfferResponses: _ember["default"].computed("offer", "allMessages.[]", function () {
      return this.get("store").peekAll("offerResponse").filterBy("offerId", this.get("offer.id"));
    }),

    offerShareable: _ember["default"].computed("offer.id", "allShareables.length", "allShareables.[]", "allShareables.@each.{id,active,publicUid}", function () {
      var _this = this;

      return this.get("allShareables").find(function (sh) {
        return sh.get("resourceType") == "Offer" && sh.get("resourceId") == _this.get("offer.id") && sh.get("active");
      });
    }),

    unreadCharityMessages: _ember["default"].computed("offer", "offerShareable", "offer.id", "allOfferResponses", "allMessages.[]", "allMessages.length", "allMessages.@each.{senderId,recipientId}", function () {
      var _this2 = this;

      var unreadMessageCount = 0;
      var offerResponse = this.get("allOfferResponses");

      offerResponse.uniq().map(function (uid) {
        var messages = _this2.get("allMessages").filter(function (m) {
          return m.get("messageableType") === "OfferResponse" && m.get("messageableId") === uid.id;
        });

        unreadMessageCount += messages.reduce(function (sum, m) {
          return sum + (m.get("isUnread") ? 1 : 0);
        }, 0);
      });

      return unreadMessageCount;
    }),

    isShared: _ember["default"].computed.alias("offerShareable"),

    displayOfferOptions: _ember["default"].computed({
      get: function get() {
        return false;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    isMyOffer: _ember["default"].computed("offer.reviewedBy", {
      get: function get() {
        var currentUserId = this.session.get("currentUser.id");
        return this.get("offer.reviewedBy.id") === currentUserId;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    cancelByMe: _ember["default"].computed("model", {
      get: function get() {
        return false;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    isOfferVanished: _ember["default"].computed.or("offer.isDeleted", "offer.isDeleting"),

    showDeleteError: _ember["default"].observer("offer", "isOfferVanished", function () {
      var _this3 = this;

      var currentPath = window.location.href;

      if (this.get("isOfferVanished") && !this.get("cancelByMe")) {
        if (currentPath.indexOf("review_item") < 0 && currentPath.indexOf("merge") < 0 && currentPath.indexOf("offers/" + this.get("offer.id")) >= 0) {
          this.get("messageBox").alert(this.get("i18n").t("404_error"), function () {
            _this3.transitionToRoute("dashboard");
          });
        }
      }
    }),

    deleteOffer: function deleteOffer(offer) {
      var _this4 = this;

      var path = arguments.length <= 1 || arguments[1] === undefined ? this.get("backLinkPath") : arguments[1];

      this.set("cancelByMe", true);
      this.runTask(offer.destroyRecord()).then(function () {
        return _this4.transitionToRoute(path);
      })["catch"](function (error) {
        offer.rollbackAttributes();
        throw error;
      })["finally"](function () {
        return _this4.set("cancelByMe", false);
      });
    },

    actions: {
      toggleOfferOptions: function toggleOfferOptions() {
        this.toggleProperty("displayOfferOptions");
      },

      addItem: function addItem() {
        var _this5 = this;

        var offer = this.get("offer");

        var task = this.get("offerService").addNewItem(offer).then(function (item) {
          _this5.transitionToRoute("item.image_editor", item, {
            queryParams: { forwardRoute: "review_item.accept" }
          });
        });

        this.runTask(task, this.ERROR_STRATEGIES.MODAL);
      },

      startReview: function startReview() {
        var _this6 = this;

        if (this.get("isStartReviewClicked")) {
          return;
        }
        var offer = this.store.peekRecord("offer", this.get("offer.id"));
        this.set("isStartReviewClicked", true);
        var adapter = getOwner(this).lookup("adapter:application");
        var url = adapter.buildURL("offer", offer.get("id")) + "/review";

        adapter.ajax(url, "PUT").then(function (data) {
          return _this6.store.pushPayload(data);
        })["finally"](function () {
          return _this6.set("isStartReviewClicked", false);
        });
      },

      deleteOffer: function deleteOffer() {
        var _this7 = this;

        this.send("toggleOfferOptions");
        var offer = this.get("model");
        this.get("messageBox").custom(this.get("i18n").t("delete_confirm"), this.get("i18n").t("review_offer.options.yes"), function () {
          _this7.deleteOffer(offer);
        }, this.get("i18n").t("review_item.not_now"), null);
      },

      transitionTo: function transitionTo(path) {
        var _this8 = this;

        var offer = this.get("model");
        var items = offer.get("items");
        if (!items.length) {
          this.get("messageBox").custom(this.get("i18n").t("review_offer.empty_offer_message"), this.get("i18n").t("review_offer.options.yes"), function () {
            _this8.deleteOffer(offer, path);
          }, this.get("i18n").t("review_item.not_now"), null);
        } else {
          this.transitionToRoute(path);
        }
      },

      reopenOffer: function reopenOffer() {
        var _this9 = this;

        this.runTask(function callee$1$0() {
          return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
            while (1) switch (context$2$0.prev = context$2$0.next) {
              case 0:
                context$2$0.next = 2;
                return regeneratorRuntime.awrap(this.get("offerService").reopenOffer(this.get("model")));

              case 2:
                this.send("toggleOfferOptions");

              case 3:
              case "end":
                return context$2$0.stop();
            }
          }, null, _this9);
        }, _goodcityMixinsAsync_tasks.ERROR_STRATEGIES.MODAL);
      },

      resumeReceivingOffer: function resumeReceivingOffer() {
        var _this10 = this;

        this.runTask(function callee$1$0() {
          return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
            while (1) switch (context$2$0.prev = context$2$0.next) {
              case 0:
                context$2$0.next = 2;
                return regeneratorRuntime.awrap(this.get("offerService").resumeReceiving(this.get("model")));

              case 2:
                this.set("displayOfferOptions", false);

              case 3:
              case "end":
                return context$2$0.stop();
            }
          }, null, _this10);
        }, _goodcityMixinsAsync_tasks.ERROR_STRATEGIES.MODAL);
      },

      submitOffer: function submitOffer() {
        this.toggleProperty("displayOfferOptions");
        var offer = this.get("model");
        offer.setProperties({ state_event: "submit" });

        this.runTask(offer.save());
      }
    }
  });
});