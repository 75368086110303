define("goodcity/components/select-list", ["exports", "ember", "ember-cli-html5-validation/mixins/validatable-input"], function (exports, _ember, _emberCliHtml5ValidationMixinsValidatableInput) {
  exports["default"] = _ember["default"].Component.extend(_emberCliHtml5ValidationMixinsValidatableInput["default"], {
    content: null,
    selectedValue: null,

    // overriden from ember-cli-html5-validation addon
    inputTagName: (function () {
      return "select";
    }).property(),

    // overriden from ember-cli-html5-validation addon
    validate: function validate() {
      var input = _ember["default"].$(this.element).find("select")[0],
          jQueryElement = _ember["default"].$(input);

      if (input.hasAttribute('formnovalidate')) {
        return;
      }

      if (input.hasAttribute('required')) {
        var content = _ember["default"].$.trim(jQueryElement.val());

        if (content.length === 0) {
          jQueryElement.val('');
        }
      }

      if (!input.validity.valid) {
        this.set('errorMessage', "");
      } else {
        this.set('errorMessage', null);
      }

      input.setCustomValidity('');

      if (!this.get('wasValidated')) {
        jQueryElement.off('focusout').on('keyup', _ember["default"].run.bind(this, this.validate));
        this.set('wasValidated', true);
      }
    },

    actions: {
      change: function change() {
        var changeAction = this.get('on-change');
        var selectedIndex = this.$('select').prop('selectedIndex');
        var content = this.get('content').toArray();
        if (this.get("prompt")) {
          content = [{ name: null }].concat(content);
        }
        var selectedValue = content[selectedIndex];

        this.set('selectedValue', selectedValue);
        changeAction(selectedValue);
      }
    }
  });
});