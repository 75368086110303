define("goodcity/controllers/offers", ["exports", "ember", "goodcity/mixins/async_tasks"], function (exports, _ember, _goodcityMixinsAsync_tasks) {
  exports["default"] = _ember["default"].Controller.extend(_goodcityMixinsAsync_tasks["default"], {
    store: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    application: _ember["default"].inject.controller(),
    appVersion: _ember["default"].computed.alias("application.appVersion"),

    currentUser: _ember["default"].computed("session.currentUser.id", function () {
      return this.store.peekRecord("user", this.get("session.currentUser.id"));
    }),

    newOffersCount: _ember["default"].computed("allOffers.@each.isSubmitted", function () {
      return this.get("allOffers").filterBy("isSubmitted", true).length;
    }),

    receivingOffersCount: _ember["default"].computed("allOffers.@each.isReceiving", function () {
      return this.get("allOffers").filterBy("isReceiving", true).length;
    }),

    inProgressOffersCount: _ember["default"].computed("allOffers.@each.isReviewing", function () {
      return this.get("allOffers").filterBy("isReviewing", true).length;
    }),

    scheduledCount: _ember["default"].computed("allOffers.@each.isScheduled", function () {
      return this.get("allOffers").filterBy("isScheduled", true).length;
    }),

    myOffersCount: _ember["default"].computed("allOffers.@each.isReviewing", function () {
      var currentUserId = this.session.get("currentUser.id");
      return this.get("allOffers").filterBy("adminCurrentOffer", true).filterBy("reviewedBy.id", currentUserId).length;
    }),

    allOffers: _ember["default"].computed(function () {
      return this.store.peekAll("offer");
    }),

    actions: {
      logMeOut: function logMeOut() {
        this.get("application").send("logMeOut");
      },

      manageCannedMessage: function manageCannedMessage() {
        this.get("application").send("manageCannedMessage");
      },

      createOffer: function createOffer() {
        var _this = this;

        var now = new Date();
        var offer = this.get("store").createRecord("offer", {
          createdAt: now,
          createdById: null,
          language: this.get("i18n.locale"),
          reviewedAt: now,
          reviewedBy: this.get("currentUser"),
          state: "under_review",
          submittedAt: null
        });

        this.runTask(offer.save()).then(function () {
          _this.transitionToRoute("review_offer.items", offer);
        });
      }
    }
  });
});