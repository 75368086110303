define("goodcity/components/custom-select2", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    attributeBindings: ["record", "recordId", "placeholder", "content", "enabled", "id"],
    isAndroidDevice: false,
    enabled: true,
    cordova: _ember["default"].inject.service(),

    didInsertElement: function didInsertElement() {
      var isAndroidDevice = this.get("cordova").isAndroid();
      this.set("isAndroidDevice", isAndroidDevice);
    }
  });
});