define("goodcity/models/shareable", ["exports", "ember", "ember-data"], function (exports, _ember, _emberData) {

  var attr = _emberData["default"].attr,
      belongsTo = _emberData["default"].belongsTo;

  var SHAREABLE_TYPES = {
    OFFER: "Offer",
    PACKAGE: "Package"
  };

  exports.SHAREABLE_TYPES = SHAREABLE_TYPES;
  exports["default"] = _emberData["default"].Model.extend({
    allowListing: attr("boolean"),
    publicUid: attr("string"),
    expiresAt: attr("date"),
    resourceId: attr("string"),
    resourceType: attr("string"),
    createdById: attr("string"),
    createdBy: belongsTo("user", { async: false }),
    offerId: attr("string"),
    itemId: attr("string"),
    item: belongsTo("item", { async: false }),
    offer: belongsTo("offer", { async: false }),
    notes: attr("string"),
    notesZhTw: attr("string"),

    active: _ember["default"].computed("expiresAt", function () {
      if (this.get("expiresAt") && typeof this.get("expiresAt") !== "string") {
        return this.get("expiresAt").getTime() > Date.now();
      } else if (!this.get("expiresAt")) {
        return true;
      }
    }).volatile(),

    isPackage: _ember["default"].computed.equal("resourceType", SHAREABLE_TYPES.PACKAGE),
    isOffer: _ember["default"].computed.equal("resourceType", SHAREABLE_TYPES.OFFER)
  });
});