define('goodcity/models/gogovan_transport', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

  var attr = _emberData['default'].attr;

  exports['default'] = _emberData['default'].Model.extend({
    name: attr('string'),
    disabled: attr('boolean'),

    specialId: _ember['default'].computed('id', function () {
      return this.get("id") + "_ggv";
    })
  });
});