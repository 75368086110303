define("goodcity/components/complete-review-offer", ["exports", "ember", "goodcity/utils/ajax-promise", "goodcity/utils/promisify", "ember-i18n"], function (exports, _ember, _goodcityUtilsAjaxPromise, _goodcityUtilsPromisify, _emberI18n) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Component.extend({
    store: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    invalidMessage: false,
    invalidSelection: false,
    rejectOffer: _ember["default"].computed.alias("offer.allItemsRejected"),
    displayUserPrompt: false,
    messageService: _ember["default"].inject.service(),

    selectedGogovanOption: "",
    ggvOptionPlaceholder: (0, _emberI18n.translationMacro)("logistics.choose_ggv_option"),

    summaryText: _ember["default"].computed("rejectOffer", function () {
      if (this.get("rejectOffer")) {
        return this.get("i18n").t("review_offer.close_offer_summary");
      }
    }),

    closeMessage: _ember["default"].computed("offer", function () {
      var _this = this;

      if (this.get("rejectOffer")) {
        return (0, _goodcityUtilsPromisify["default"])(function () {
          return _this.get("messageService").getSystemMessage({
            guid: "review-offer-close-offer-message"
          });
        });
      } else {
        return (0, _goodcityUtilsPromisify["default"])(function () {
          return _this.get("messageService").getSystemMessage({
            guid: "logistics-complete-review-message"
          });
        });
      }
    }),

    gogovanOptions: _ember["default"].computed(function () {
      var allOptions = this.get("store").peekAll("gogovan_transport");
      var options = allOptions.rejectBy("disabled", true).sortBy("id");
      var disabledOption = allOptions.filterBy("disabled", true);
      return options.concat(disabledOption);
    }),

    actions: {
      confirmCloseOffer: function confirmCloseOffer() {
        this.set("displayUserPrompt", true);
      },

      completeReview: function completeReview() {
        var _this2 = this;

        var completeReviewMessage = "";

        if (this.get("offer.createdById")) {
          completeReviewMessage = this.get("closeMessage").content || "";
          if (completeReviewMessage.trim().length === 0) {
            this.set("invalidMessage", true);
            return false;
          }
        }

        this.set("invalidMessage", false);

        var offerId = this.get("offer.id");
        var offerProperties = {},
            action;

        if (this.get("rejectOffer")) {
          action = "close_offer";
        } else {
          var gogovanOptionId = this.get("selectedGogovanOption.id");
          if (gogovanOptionId === undefined) {
            this.set("invalidSelection", true);
            return false;
          }
          this.set("invalidSelection", false);

          offerProperties = {
            gogovan_transport_id: gogovanOptionId,
            state_event: "finish_review",
            id: offerId
          };
          action = "complete_review";
        }

        var loadingView = getOwner(this).lookup("component:loading").append();
        var url_with_text = completeReviewMessage.slice(completeReviewMessage.indexOf("[") + 1, completeReviewMessage.indexOf("]"));
        var url_text_begin = url_with_text.indexOf("|");
        var url_text = url_with_text.slice(0, url_text_begin);
        var url_for = url_with_text.slice(url_text_begin + 1);
        var url = "/offers/" + offerId + "/" + action;

        if (url_for === "transport_page") {
          completeReviewMessage = completeReviewMessage.replace("[" + url_with_text + "]", "<a href='#/offers/" + offerId + "/plan_delivery'>" + url_text + "</a>");
        }

        new _goodcityUtilsAjaxPromise["default"](url, "PUT", this.get("session.authToken"), {
          offer: offerProperties,
          complete_review_message: completeReviewMessage
        }).then(function (data) {
          _this2.get("store").pushPayload(data);
        })["finally"](function () {
          return loadingView.destroy();
        });
      }
    }
  });
});