define('goodcity/computed/foreign-key', ['exports', 'ember'], function (exports, _ember) {

  // Example usage:
  //
  // DS.Model.extend({
  //   donorCondition:       DS.belongsTo('donor_condition'),
  //   donorConditionId:     Ember.computed.foreignKey('donorCondition.id'),
  // });
  //
  // This allows you to set `donorConditionId` which will update the `donorCondition`
  // property. Binding `donorCondition.id` is not good because if the value changes
  // it'll update the `id` property on donorCondition instead of the donorCondition
  // on the model.

  exports['default'] = _ember['default'].computed.foreignKey = function (path) {
    var relationshipName = path.split('.')[0];
    return _ember['default'].computed(relationshipName, {
      get: function get() {
        return this.get(path);
      },
      set: function set(key, value) {
        var _this = this;

        this.eachRelationship(function (name, meta) {
          if (name === relationshipName) {
            var model = _this.store.peekRecord(meta.type, value);
            if (!model) {
              return _this.get(path);
            }
            _this.set(relationshipName, model);
            return value;
          }
        });
      }
    });
  };
});