define("goodcity/services/message-service", ["exports", "ember", "lodash", "goodcity/services/api-base-service"], function (exports, _ember, _lodash, _goodcityServicesApiBaseService) {
  exports["default"] = _goodcityServicesApiBaseService["default"].extend({
    store: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    editMessage: {
      language: "en",
      messageId: ""
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.set("isCannedMessagesVisible", false);
      this.set("isProFormaMessageVisible", false);
      this.set("isAddMessageVisible", false);
    },

    toggle: function toggle() {
      this.toggleProperty("isCannedMessagesVisible");
    },

    fetchCannedResponse: function fetchCannedResponse() {
      var opts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
      var data;
      return regeneratorRuntime.async(function fetchCannedResponse$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.GET("/canned_responses", opts));

          case 2:
            data = context$1$0.sent;

            this.get("store").pushPayload(data);
            return context$1$0.abrupt("return", data);

          case 5:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    cannedMessageLookup: function cannedMessageLookup() {
      var _this = this;

      var deferred = _ember["default"].RSVP.defer();

      _ember["default"].run(function () {
        _this.set("isCannedMessagesVisible", true);
        _this.set("onCannedMessageSelect", function (text) {
          _this.set("onCannedMessageSelect", _lodash["default"].noop);
          _this.set("openLocationSearch", false);
          deferred.resolve(text || null);
        });
      });

      return deferred.promise;
    },

    getSystemMessage: function getSystemMessage(_ref) {
      var guid = _ref.guid;
      var cannedResponses, record;
      return regeneratorRuntime.async(function getSystemMessage$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            cannedResponses = this.get("store").peekAll("canned_response");
            record = cannedResponses.filter(function (res) {
              return res.get("guid") === guid;
            }).get("firstObject.content");

            if (record) {
              context$1$0.next = 7;
              break;
            }

            context$1$0.next = 5;
            return regeneratorRuntime.awrap(this.GET("/canned_responses/" + guid, {
              language: this.get("i18n").get("locale")
            }));

          case 5:
            record = context$1$0.sent;

            record = record.canned_response.content;

          case 7:
            return context$1$0.abrupt("return", record);

          case 8:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    }
  });
});

// check if record is already present in store