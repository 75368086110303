define("goodcity/helpers/user-organisation-status-icon", ["exports", "ember"], function (exports, _ember) {

  var STATE_ICONS = {
    pending: "fa-pause-circle",
    approved: "fa-check-circle",
    expired: "fa-times-circle",
    denied: "fa-times-circle"
  };

  exports["default"] = _ember["default"].Helper.helper(function (state) {
    return STATE_ICONS[state] || "";
  });
});