define("goodcity/services/offer-service", ["exports", "goodcity/services/api-base-service", "goodcity/utils/helpers"], function (exports, _goodcityServicesApiBaseService, _goodcityUtilsHelpers) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports["default"] = _goodcityServicesApiBaseService["default"].extend({
    fetchOffer: function fetchOffer(id, params) {
      var _this = this;

      return this.GET("/offers/" + id, params).then(function (payload) {
        _this.get("store").pushPayload(payload);
        return _this.get("store").peekRecord("offer", id);
      });
    },

    offersCount: function offersCount() {
      return this.GET("/offers/summary");
    },

    offersSearch: function offersSearch(searchParams) {
      return this.GET("/offers/search", searchParams);
    },

    mergeableOffers: function mergeableOffers(offerID) {
      var data;
      return regeneratorRuntime.async(function mergeableOffers$(context$1$0) {
        var _this2 = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.GET("/offers/" + offerID + "/mergeable_offers"));

          case 2:
            data = context$1$0.sent;

            this.get("store").pushPayload(data);

            return context$1$0.abrupt("return", (data.offers || []).map(function (_ref) {
              var id = _ref.id;

              return _this2.get("store").peekRecord("offer", id);
            }));

          case 5:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    addNewItem: function addNewItem(offer) {
      var defaultDonorCondition = this.get("store").peekAll("donorCondition").sortBy("id").get("firstObject");

      return this.get("store").createRecord("item", {
        offer: offer,
        donorCondition: defaultDonorCondition,
        state: "draft"
      }).save();
    },

    reopenOffer: function reopenOffer(offer) {
      return this.__udpateState(offer, "reopen_offer");
    },

    resumeReceiving: function resumeReceiving(offer) {
      return this.__udpateState(offer, "resume_receiving");
    },

    __udpateState: function __udpateState(offer, state) {
      var params = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];
      var id, url, data;
      return regeneratorRuntime.async(function __udpateState$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            id = (0, _goodcityUtilsHelpers.toID)(offer);
            url = "/offers/" + id + "/" + state;
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(this.PUT(url, params));

          case 4:
            data = context$1$0.sent;

            this.get("store").pushPayload(data);
            return context$1$0.abrupt("return", this.get("store").peekRecord("offer", id));

          case 7:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    packagesOf: function packagesOf(offer) {
      return offer.get("items").reduce(function (pkgs, item) {
        return [].concat(_toConsumableArray(pkgs), _toConsumableArray(item.get("packages").toArray()));
      }, []);
    }
  });
});