define('goodcity/controllers/delivery/contact_details', ['exports', 'ember', 'goodcity/controllers/delivery/address_details', 'goodcity/utils/ajax-promise'], function (exports, _ember, _goodcityControllersDeliveryAddress_details, _goodcityUtilsAjaxPromise) {
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _goodcityControllersDeliveryAddress_details['default'].extend({
    deliveryController: _ember['default'].inject.controller('delivery'),

    actions: {
      saveContactDetails: function saveContactDetails() {
        var controller = this;
        var addressProperties = this.getProperties('street', 'flat', 'building');
        addressProperties.districtId = this.selectedDistrict;
        addressProperties.addressType = 'collection';

        var contactProperties = {};
        contactProperties.name = _ember['default'].$('#userName').val();
        contactProperties.mobile = "+852" + _ember['default'].$('#mobile').val();

        var deliveryId = this.get('deliveryController.model.id');
        var delivery = this.store.peekRecord('delivery', deliveryId);
        var offer = delivery.get('offer');
        var schedule = delivery.get('schedule');

        var loadingView = getOwner(this).lookup('component:loading').append();
        var handleError = function handleError(error) {
          loadingView.destroy();throw error;
        };

        contactProperties.addressAttributes = addressProperties;

        var properties = {
          delivery: {
            id: delivery.id,
            deliveryType: delivery.get("deliveryType"),
            offerId: offer.id,
            scheduleAttributes: schedule._attributes,
            contactAttributes: contactProperties }
        };

        new _goodcityUtilsAjaxPromise['default']("/confirm_delivery", "POST", controller.get('session.authToken'), properties).then(function (data) {
          controller.store.pushPayload(data);
          controller.set("inProgress", false);
          loadingView.destroy();

          controller.transitionToRoute('delivery.thank_offer').then(function (newRoute) {
            return newRoute.controller.set('contact', delivery.get('contact'));
          });
        })['catch'](function (error) {
          loadingView.destroy();
          throw error;
        });
      }
    }
  });
});