define('goodcity/components/schedule-selection', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    attributeBindings: ['schedules', 'selectedValue'],
    i18n: _ember['default'].inject.service(),

    weekDays: _ember['default'].computed('schedules', function () {
      var _this = this;
      var currentDay = moment().day();
      var week = moment.weekdays();

      var options = [{ name: this.get("i18n").t('scheduled.all_offers') + ' (' + _this.allCount() + ')', id: 'all' }, { name: this.get("i18n").t('scheduled.overdue') + ' (' + _this.overdueCount() + ')', id: 'overdue' }, { name: this.get("i18n").t('scheduled.today') + ' (' + _this.scheduleCount() + ')', id: 'today' }];

      for (var i = currentDay + 1; i < week.length; i++) {
        options.push({ name: week[i] + " (" + _this.scheduleCount(week[i]) + ")", id: week[i] });
      }

      options.push({ name: this.get("i18n").t('scheduled.next_week') + ' (' + _this.nextWeekCount() + ')',
        id: 'next' });
      options.push({ name: this.get("i18n").t('scheduled.after_next_week') + ' (' + _this.afterNextWeekCount() + ')', id: 'after_next' });
      return options;
    }),

    overdueCount: function overdueCount() {
      return this.get('currentController').overdue().length;
    },

    scheduleCount: function scheduleCount(dayValue) {
      return this.get('currentController').daySchedule(dayValue).length;
    },

    nextWeekCount: function nextWeekCount() {
      return this.get('currentController').nextWeek().length;
    },

    afterNextWeekCount: function afterNextWeekCount() {
      return this.get('currentController').afterNextWeek().length;
    },

    allCount: function allCount() {
      return this.get('currentController.allScheduled.length');
    },

    actions: {
      change: function change() {
        var changeAction = this.get('on-change');
        var selectedIndex = this.$('select').prop('selectedIndex');
        var selectedValue = this.get('weekDays')[selectedIndex];
        this.set('selectedValue', selectedValue);
        changeAction(selectedValue);
      }
    }
  });
});