define('goodcity/initializers/app-version', ['exports', 'ember-cli-app-version/initializer-factory', 'goodcity/config/environment'], function (exports, _emberCliAppVersionInitializerFactory, _goodcityConfigEnvironment) {

  var name = undefined,
      version = undefined;
  if (_goodcityConfigEnvironment['default'].APP) {
    name = _goodcityConfigEnvironment['default'].APP.name;
    version = _goodcityConfigEnvironment['default'].APP.version;
  }

  exports['default'] = {
    name: 'App Version',
    initialize: (0, _emberCliAppVersionInitializerFactory['default'])(name, version)
  };
});