define("goodcity/services/api-base-service", ["exports", "ember", "goodcity/utils/ajax-promise", "lodash"], function (exports, _ember, _goodcityUtilsAjaxPromise, _lodash) {

  function urlWithParams(url, params) {
    if (!params) {
      return url;
    }
    var paramStr = _lodash["default"].map(params, function (value, key) {
      return key + "=" + value;
    }).join("&");
    var separator = /\?/.test(url) ? "&" : "?";
    return "" + url + separator + paramStr;
  }

  exports["default"] = _ember["default"].Service.extend({
    // ----- Services -----
    store: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),

    // ----- Utilities -----
    _request: function _request(url, options, authorizedRequest) {
      var _this = this;

      var action = options.action;
      var body = options.body;
      var _options$persist = options.persist;
      var persist = _options$persist === undefined ? false : _options$persist;
      var language = options.language;

      url = this.baseUrl ? "" + this.baseUrl + url : url;

      return new _goodcityUtilsAjaxPromise["default"](url, action, authorizedRequest ? this.get("session.authToken") : null, body, null, language || "en").then(function (data) {
        if (persist) {
          _this.get("store").pushPayload(data);
        }
        return data;
      });
    },

    // ----- Configuration -----

    useBaseUrl: function useBaseUrl(baseUrl) {
      this.baseUrl = baseUrl;
    },

    // ----- CRUD ACTIONS -----
    /**
      authorizedRequest is optional parameter to be be sent during request.
      By default requests are authorized
    **/
    GET: function GET(url) {
      var opts = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
      var _opts$authorizedRequest = opts.authorizedRequest;
      var authorizedRequest = _opts$authorizedRequest === undefined ? true : _opts$authorizedRequest;

      return this._request(urlWithParams(url, opts), _lodash["default"].extend({}, opts, {
        action: "GET"
      }), authorizedRequest);
    },

    POST: function POST(url, body) {
      var opts = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];
      var _opts$authorizedRequest2 = opts.authorizedRequest;
      var authorizedRequest = _opts$authorizedRequest2 === undefined ? true : _opts$authorizedRequest2;

      return this._request(url, _lodash["default"].extend({}, opts, {
        action: "POST",
        body: body
      }), authorizedRequest);
    },

    PUT: function PUT(url, body) {
      var opts = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];
      var _opts$authorizedRequest3 = opts.authorizedRequest;
      var authorizedRequest = _opts$authorizedRequest3 === undefined ? true : _opts$authorizedRequest3;

      return this._request(url, _lodash["default"].extend({}, opts, {
        action: "PUT",
        body: body
      }), authorizedRequest);
    },

    DELETE: function DELETE(url) {
      var opts = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
      var _opts$authorizedRequest4 = opts.authorizedRequest;
      var authorizedRequest = _opts$authorizedRequest4 === undefined ? true : _opts$authorizedRequest4;

      return this._request(urlWithParams(url, opts), _lodash["default"].extend({}, opts, {
        action: "DELETE"
      }), authorizedRequest);
    }
  });
});