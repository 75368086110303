define('goodcity/models/user_role', ['exports', 'ember-data'], function (exports, _emberData) {

  var belongsTo = _emberData['default'].belongsTo,
      attr = _emberData['default'].attr;

  exports['default'] = _emberData['default'].Model.extend({
    userId: attr('number'),
    roleId: attr('number'),
    user: belongsTo('user', { async: false }),
    role: belongsTo('role', { async: true })
  });
});