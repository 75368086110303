define("goodcity/components/datetime-selector", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    selectedDate: "",
    selectedTime: "",

    initDate: function initDate() {
      if (this.get("selectedDateTime") && typeof this.get("selectedDateTime") !== "string") {
        return this.set("selectedDate", moment(this.get("selectedDateTime")).format("DD/MM/YYYY"));
      }
    },

    initTime: function initTime() {
      if (this.get("selectedDateTime") && typeof this.get("selectedDateTime") !== "string") {
        var date = new Date(this.get("selectedDateTime")).toLocaleString("en-US", { timeZone: "Asia/Hong_Kong" });
        this.set("selectedTime", moment(date).format("HH:mm"));
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.initDate();
      this.initTime();
    },

    invalidDateTime: _ember["default"].computed("selectedDateTime", function () {
      return !this.get("selectedDateTime");
    }),

    selectedDateTime: _ember["default"].computed("selectedTime", "selectedDate", {
      get: function get() {
        if (typeof this.get("selectedDate") === "string") {
          var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
          var date = this.get("selectedDate").replace(pattern, "$3/$2/$1");
          var time = this.get("selectedTime") || "23:59";

          if (date) {
            return date + " " + time + " HKT";
          }
        }
      },
      set: function set(_, value) {
        return value;
      }
    }),

    actions: {
      // Remove when Ember is upgraded to >= 3.0
      updateErrorMessage: function updateErrorMessage() {
        return false;
      }
    }
  });
});