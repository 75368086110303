define("goodcity/serializers/application", ["exports", "active-model-adapter"], function (exports, _activeModelAdapter) {

  // Polymorphic associations are not supported in ember-data beta version:
  // refer: https://github.com/emberjs/data/issues/1574

  exports["default"] = _activeModelAdapter.ActiveModelSerializer.extend({

    keyForAttribute: function keyForAttribute(attr, method) {
      if (attr === "addressable") {
        return "addressable_id";
      }
      return this._super(attr, method);
    }
  });
});