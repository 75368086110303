define('goodcity/models/packages_location', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports['default'] = _emberDataModel['default'].extend({
    packageId: (0, _emberDataAttr['default'])('number'),
    itemId: (0, _emberDataAttr['default'])('number'),
    quantity: (0, _emberDataAttr['default'])('number'),
    locationId: (0, _emberDataAttr['default'])('number'),

    'package': (0, _emberDataRelationships.belongsTo)('package', { async: false }),
    location: (0, _emberDataRelationships.belongsTo)('location', { async: false })
  });
});