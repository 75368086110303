define("goodcity/models/donor_condition", ["exports", "ember-data"], function (exports, _emberData) {

  var attr = _emberData["default"].attr,
      hasMany = _emberData["default"].hasMany;

  exports["default"] = _emberData["default"].Model.extend({
    name: attr("string"),
    visibleToDonor: attr("boolean"),
    items: hasMany("item", { async: false })
  });
});