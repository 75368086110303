define("goodcity/components/stop-offer-sharing", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    store: _ember["default"].inject.service(),
    offer: null,
    stopSharingAt: null,

    allShareables: _ember["default"].computed(function () {
      return this.get("store").peekAll("shareable");
    }),

    isSharedOffer: _ember["default"].computed("offer.id", "allShareables.length", "allShareables.[]", "allShareables.@each.{id,active,publicUid}", function () {
      var _this = this;

      return this.get("allShareables").find(function (sh) {
        return sh.get("resourceType") == "Offer" && sh.get("resourceId") == _this.get("offer.id") && sh.get("active");
      });
    }),

    allowListingEnabled: _ember["default"].computed.alias("isSharedOffer.allowListing"),

    selectedStopSharingTime: _ember["default"].computed(function () {
      return moment().format("HH:mm");
    }),

    selectedStopSharingDate: _ember["default"].computed(function () {
      return moment().format("DD/MM/YYYY");
    }),

    stopSharingObserver: _ember["default"].observer("selectedStopSharingTime", "selectedStopSharingDate", function () {
      var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;

      if (typeof this.get("selectedStopSharingDate") === "string") {
        var date = this.get("selectedStopSharingDate").replace(pattern, "$3/$2/$1");
        var time = this.get("selectedStopSharingTime") || "23:59";

        this.set("stopSharingAt", date + " " + time + " HKT");
      }
    }),

    actions: {
      resetDateToNow: function resetDateToNow() {
        this.set("selectedStopSharingDate", moment().format("DD/MM/YYYY"));
        this.set("selectedStopSharingTime", moment().format("HH:mm"));
      },

      //Remove when Ember is upgraded to >= 3.0
      updateErrorMessage: function updateErrorMessage() {
        return false;
      }
    }
  });
});