define("goodcity/controllers/message_base_controller", ["exports", "ember", "goodcity/mixins/async_tasks"], function (exports, _ember, _goodcityMixinsAsync_tasks) {
  exports["default"] = _ember["default"].Controller.extend(_goodcityMixinsAsync_tasks["default"], {
    messageLinkConvertor: _ember["default"].inject.service(),
    messageService: _ember["default"].inject.service(),
    body: "",
    offerController: _ember["default"].inject.controller("offer"),
    messagesUtil: _ember["default"].inject.service("messages"),
    isPrivate: false,
    inProgress: false,
    offer: _ember["default"].computed.alias("offerController.model"),
    sortProperties: ["createdAt:asc"],
    sortedElements: _ember["default"].computed.sort("messagesAndVersions", "sortProperties"),
    isItemThread: _ember["default"].computed.notEmpty("item"),

    autoMarkAsRead: _ember["default"].on("init", _ember["default"].observer("isActive", "messages.[]", "messages.@each.state", function () {
      if (this.get("isActive")) {
        _ember["default"].run.debounce(this, this.markConversationAsRead, 1500);
      }
    })),

    disabled: _ember["default"].computed("offer.isCancelled", "item.isDraft", "missingRecipient", function () {
      return this.get("missingRecipient") || this.get("offer.isCancelled") || this.get("item.isDraft");
    }),

    groupedElements: _ember["default"].computed("sortedElements.[]", function () {
      return this.groupBy(this.get("sortedElements"), "createdDate");
    }),

    allMessages: _ember["default"].computed(function () {
      return this.store.peekAll("message");
    }),

    messages: _ember["default"].computed("allMessages.[]", "allMessages.@each.recipientId", "offer", "item", "isPrivate", "recipientId", function () {
      var _this = this;

      var messages = this.get("allMessages");
      messages = this.get("isItemThread") ? messages.filterBy("itemId", this.get("item.id")) : messages.filterBy("offerId", this.get("offer.id")).filterBy("item", null);

      // For a public chat with no recipient, we default to the donor
      var recipientId = this.get("recipientId") || (this.get("isPrivate") ? null : this.get("offer.createdById"));

      messages = messages.filter(function (m) {
        return recipientId && (m.get("recipientId") === recipientId || m.get("senderId") === recipientId);
      });

      return messages.filter(function (m) {
        return Boolean(m.get("isPrivate")) === _this.get("isPrivate");
      });
    }),

    messagesAndVersions: _ember["default"].computed("messages.[]", "itemVersions", "packageVersions", "offerVersions", function () {
      var messages = this.get("messages").toArray();
      var itemVersions = this.get("itemVersions").toArray();
      var packageVersions = this.get("packageVersions").toArray();
      var offerVersions = this.get("offerVersions").toArray();
      return messages.concat(itemVersions, packageVersions, offerVersions);
    }),

    missingRecipient: _ember["default"].computed("recipientId", "offer.createdById", function () {
      return !this.get("recipientId") && !this.get("offer.createdById");
    }),

    itemVersions: _ember["default"].computed("item.id", "allVersions.[]", "isItemThread", function () {
      if (!this.get("isItemThread")) {
        return [];
      }
      var itemId = parseInt(this.get("item.id"), 10);
      return this.get("allVersions").filterBy("itemId", itemId).filterBy("itemType", "Item");
    }),

    packageVersions: _ember["default"].computed("item.packages", "allVersions.[]", "isItemThread", function () {
      if (!this.get("isItemThread")) {
        return [];
      }
      var packageIds = (this.get("item.packages") || []).mapBy("id");
      return this.get("allVersions").filterBy("itemType", "Package").filter(function (log) {
        return packageIds.indexOf(String(log.get("itemId"))) >= 0 && ["received", "missing"].indexOf(log.get("state")) >= 0;
      });
    }),

    allVersions: _ember["default"].computed(function () {
      return this.get("store").peekAll("version");
    }),

    offerVersions: _ember["default"].computed("allVersions.[]", "offer.id", "isItemThread", function () {
      if (this.get("isItemThread")) {
        return [];
      }
      var offerId = parseInt(this.get("offer.id"), 10);
      return this.get("allVersions").filterBy("itemType", "Offer").filterBy("itemId", offerId);
    }),

    groupBy: function groupBy(content, key) {
      var result = [];
      var object, value;

      content.forEach(function (item) {
        value = item.get ? item.get(key) : item[key];
        object = result.findBy("value", value);
        if (!object) {
          object = {
            value: value,
            items: []
          };
          result.push(object);
        }
        return object.items.push(item);
      });
      return result.getEach("items");
    },

    markConversationAsRead: function markConversationAsRead() {
      var _this2 = this;

      this.get("messages").filterBy("state", "unread").forEach(function (m) {
        return _this2.get("messagesUtil").markRead(m);
      });
    },

    actions: {
      cannedMessageLookup: function cannedMessageLookup() {
        var text;
        return regeneratorRuntime.async(function cannedMessageLookup$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.get("messageService").cannedMessageLookup());

            case 2:
              text = context$1$0.sent;

              this.set("body", text);

            case 4:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      sendMessage: function sendMessage() {
        var _this3 = this;

        // To hide soft keyboard
        _ember["default"].$("textarea").trigger("blur");

        this.set("inProgress", true);
        var values = this.getProperties("body", "offer", "item", "isPrivate");
        values.itemId = this.get("item.id");
        values.offerId = this.get("offer.id");
        values.createdAt = new Date();
        values.sender = this.store.peekRecord("user", this.get("session.currentUser.id"));

        if (!this.get("isPrivate")) {
          values.recipientId = this.get("recipientId") || this.get("offer.createdById");

          if (!values.recipientId) return this.i18nAlert("chats.no_recipient");
        }

        this.get("messageLinkConvertor").convert(values);
        var message = this.store.createRecord("message", values);
        message.save().then(function () {
          _this3.set("body", "");
        })["catch"](function (error) {
          _this3.store.unloadRecord(message);
          throw error;
        })["finally"](function () {
          return _this3.set("inProgress", false);
        });

        _ember["default"].$("body").animate({ scrollTop: _ember["default"].$(document).height() }, 1000);
      }
    }
  });
});