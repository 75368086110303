define("goodcity/controllers/review_item/accept", ["exports", "ember"], function (exports, _ember) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Controller.extend({
    applicationController: _ember["default"].inject.controller("application"),
    reviewOfferController: _ember["default"].inject.controller("review_offer"),
    reviewItem: _ember["default"].inject.controller(),
    store: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    review_item: _ember["default"].inject.controller(),
    item: _ember["default"].computed.alias("reviewItem.item"),
    offer: _ember["default"].computed.alias("item.offer"),
    itemTypeId: _ember["default"].computed.alias("reviewItem.itemTypeId"),
    isItemAccepted: _ember["default"].computed.equal("item.state", "accepted"),
    packages: [],
    isAccepting: false,
    itemSaving: false,

    itemPackages: _ember["default"].computed.alias("item.packages"),

    onItemPackagesChange: _ember["default"].observer("itemPackages.[]", "itemPackages.@each.receivedQuantity", "itemPackages.@each.length", "itemPackages.@each.width", "itemPackages.@each.height", "itemPackages.@each.notes", "itemPackages.@each.packageTypeId", "itemPackages.@each.displayImageUrl", "itemPackages.@each.packageType", function () {
      this.onItemTypeChange();
      return false;
    }),

    itemType: _ember["default"].computed("itemTypeId", function () {
      var typeId = this.get("itemTypeId.id") || this.get("itemTypeId");

      if (!typeId) {
        return null;
      }

      return this.get("store").peekRecord("packageType", typeId);
    }),

    subPackageTypes: _ember["default"].computed("itemType", function () {
      var itemType = this.get("itemType");
      return itemType.get("allChildPackagesList").apply(itemType).sortBy("name");
    }),

    returnPackageTypes: function returnPackageTypes(itemType) {
      return itemType && itemType.get("allChildPackagesList").apply(itemType);
    },

    returnPackageProperties: function returnPackageProperties(pkg) {
      return pkg.getProperties("id", "receivedQuantity", "length", "width", "height", "notes", "item", "packageTypeId", "displayImageUrl", "packageType", "favouriteImage");
    },

    loadDefaultPackages: function loadDefaultPackages(itemType) {
      var _this = this;

      itemType.get("defaultChildPackagesList").apply(itemType).forEach(function (t) {
        return _this.send("addPackage", t.get("id"));
      });
    },

    onItemTypeChange: _ember["default"].observer("itemTypeId", function () {
      var _this2 = this;

      // remove focus to hide soft-keyboard
      _ember["default"].$("input").blur();

      if (this.get("itemSaving")) {
        return;
      }

      var itemType = this.get("itemType");
      var packages = this.get("packages");
      var packageTypes = this.returnPackageTypes(itemType);
      packages.clear();

      // load existing packages
      if (itemType && itemType.get("id") === this.get("item.packageType.id")) {
        this.get("item.packages").forEach(function (pkg, index) {
          var obj = _this2.returnPackageProperties(pkg);
          obj.packageType = packageTypes[index] || packageTypes[0];
          obj.changedNotes = obj.packageType.get("name");
          obj.hideComment = false;
          obj.receivedQuantity = obj.receivedQuantity || 1;
          packages.pushObject(obj);
        });
      }

      // load default packages
      if (itemType && packages.length === 0) {
        this.loadDefaultPackages(itemType);
      }
    }),

    showDesignatedDispatchError: function showDesignatedDispatchError() {
      var pkgs = this.get("itemPackages");
      return pkgs && pkgs.length > 0 && (pkgs.get("firstObject.hasAllPackagesDesignated") || pkgs.get("firstObject.hasAllPackagesDispatched"));
    },

    cannotSave: function cannotSave() {
      if (this.showDesignatedDispatchError()) {
        this.get("messageBox").alert(this.get("i18n").t("designated_dispatched_error"));
        return true;
      }
      return false;
    },

    actions: {
      clearText: function clearText(index) {
        if (index !== null) {
          _ember["default"].$("#" + index).val("");
        }
      },

      addPackage: function addPackage(packageTypeId) {
        var note_text = "";

        this.get("packages").pushObject({
          hideComment: false,
          displayImageUrl: this.get("item.displayImageUrl"),
          notes: note_text,
          receivedQuantity: 1,
          packageTypeId: packageTypeId,
          packageType: this.get("store").peekRecord("packageType", packageTypeId),
          offerId: this.get("item.offer.id"),
          item: this.get("item"),
          favouriteImage: this.get("item.displayImage"),
          favouriteImageId: this.get("item.displayImage.id")
        });
      },

      setPackageImage: function setPackageImage(currentPackage, image) {
        _ember["default"].set(currentPackage, "favouriteImageId", image.get("id"));
        _ember["default"].set(currentPackage, "displayImageUrl", image.get("thumbImageUrl"));
      },

      removePackage: function removePackage(index) {
        this.get("packages").removeAt(index);
      },

      save: function save() {
        var _this3 = this;

        if (this.get("itemPackages") && this.cannotSave()) {
          return false;
        }

        // save item and packages
        // getting "Attempted to handle event *event* on *record* while in state root.deleted.saved" if try
        // to save item same time as a package is being deleted
        this.set("itemSaving", true);
        this.get("review_item").set("isEditing", false);

        var loadingView = getOwner(this).lookup("component:loading").append();

        // save packages
        var promises = [];
        var existing = {};
        var packages = this.get("packages");
        this.get("item.packages").forEach(function (pkg) {
          return existing[pkg.get("id")] = pkg;
        });

        this.get("packages").forEach(function (data) {
          var pkg;
          data.notes = _ember["default"].$("#comment" + packages.indexOf(data)).val();
          data.donorConditionId = _this3.get("reviewItem.formData.donorConditionId");
          if (existing[data.id]) {
            pkg = existing[data.id];
            pkg.setProperties(data);
            delete existing[data.id];
          } else {
            pkg = _this3.store.createRecord("package", data);
          }
          pkg.set("state_event", null);
          promises.push(pkg.save());
        });

        for (var id in existing) {
          promises.push(existing[id].destroyRecord());
        }

        _ember["default"].RSVP.all(promises).then(function () {
          // save item
          var item = _this3.get("item");
          item.set("packageType", _this3.get("itemType")); // this throws error in onItemTypeChange so using itemSaving as workaround

          item.setProperties({
            donorConditionId: _this3.get("reviewItem.formData.donorConditionId"),
            donorDescription: _this3.get("reviewItem.formData.donorDescription")
          });

          if (_this3.get("isAccepting")) {
            item.set("state_event", "accept");
          } else if (item.get("isDrafted")) {
            item.set("state_event", "submit");
          } else {
            item.set("state_event", null);
          }

          return item.save()["finally"](function () {
            _this3.set("itemSaving", false);
            loadingView.destroy();
            _this3.set("reviewItem.isPackageTypeChanged", true);
            _this3.transitionToRoute("review_offer.items");
            _this3.get("reviewOfferController").set("displayCompleteReviewPopup", _this3.get("offer.allItemsReviewed") && _this3.get("offer.isUnderReview"));
          });
        });
      },

      setupAcceptClick: function setupAcceptClick(btnId, accept) {
        var _this4 = this;

        $("#" + btnId).click(function () {
          return _this4.set("isAccepting", accept);
        });
      }
    }
  });
});