define('goodcity/instance-initializers/rollbar', ['exports', 'ember-rollbar-client/instance-initializers/rollbar'], function (exports, _emberRollbarClientInstanceInitializersRollbar) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberRollbarClientInstanceInitializersRollbar['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberRollbarClientInstanceInitializersRollbar.initialize;
    }
  });
});