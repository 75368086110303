define("goodcity/models/image", ["exports", "ember", "ember-data", "goodcity/config/environment"], function (exports, _ember, _emberData, _goodcityConfigEnvironment) {

  var attr = _emberData["default"].attr,
      belongsTo = _emberData["default"].belongsTo;

  exports["default"] = _emberData["default"].Model.extend({
    cloudinaryUtils: _ember["default"].inject.service(),

    imageableType: attr("string"),
    imageableId: attr("number"),
    favourite: attr("boolean"),
    cloudinaryId: attr("string"),
    item: belongsTo("item", { async: false }),
    angle: attr("number"),
    itemId: attr("number"),
    packageId: attr("number"),

    imageUrl: _ember["default"].computed("cloudinaryId", "angle", function () {
      return this.get("cloudinaryUtils").generateUrl(this.get("cloudinaryId"), {
        angle: this.get("angle") || 0
      });
    }),

    thumbImageUrl: _ember["default"].computed("cloudinaryId", "angle", function () {
      return this.get("cloudinaryUtils").generateThumbnailUrl(this.get("cloudinaryId"), {
        angle: this.get("angle") || 0
      });
    }),

    isImageOnLongTermStorage: _ember["default"].computed("cloudinaryId", function () {
      return (this.get("cloudinaryId") || "").indexOf(_goodcityConfigEnvironment["default"].APP.LONG_TERM_IMAGE_STORAGE_ID_PREFIX) === 0;
    })
  });
});