define('goodcity/controllers/ggv_orders', ['exports', 'ember', 'goodcity/config/environment'], function (exports, _ember, _goodcityConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    user: _ember['default'].computed.alias('model.createdBy'),
    contact: _ember['default'].computed.alias('model.delivery.contact'),
    districtName: _ember['default'].computed.alias('contact.address.district.name'),

    gmapUrl: _ember['default'].computed(function () {
      return _goodcityConfigEnvironment['default'].APP.GMAP_URL;
    }),

    userName: _ember['default'].computed('contact.name', 'user', function () {
      return this.get('contact.name') || this.get("user.fullName");
    }),

    userMobile: _ember['default'].computed('contact.mobile', 'user', function () {
      var mobile = this.get('contact.mobile') || this.get("user.mobile");
      return mobile ? mobile.split("+852")[1] : "";
    })
  });
});