define('goodcity/routes/review_item/supervisor_messages', ['exports', 'shared-goodcity/routes/messages_base'], function (exports, _sharedGoodcityRoutesMessages_base) {
  exports['default'] = _sharedGoodcityRoutesMessages_base['default'].extend({
    renderTemplate: function renderTemplate() {
      this.render('message_template', { controller: 'review_item.supervisor_messages' });
    },

    afterModel: function afterModel() {
      var itemId = this.modelFor('review_item').get('id');
      this.store.query('version', { item_id: itemId, for_item: true });
    }
  });
});