define('goodcity/components/input-ui-control-with-counter', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    i18n: _ember['default'].inject.service(),

    isTextArea: _ember['default'].computed('type', function () {
      return this.get('type') === 'textarea';
    }),

    /* dataType: {String} ['textarea']
     * type  -> (description: Type of Input Field, by default - its a text input
     *           To use it with a textara, specify type as 'textarea')
     * value -> (description: Current value of the input field)
     * label -> (description: Label associated with 'INPUT' field), (method: required)
     * placeholder -> (description: Placeholder string of 'Input' field), (method: optional)
     * maxlength -> (description: HTML5 MaxLength attribute of 'Input' field), (method: required)
     * rows -> (description: Rows needed for the Textarea and optional for the textbox), (method: optional)
     * message -> (description: character count message e.g. (130/190)), (method: private)
     */
    type: null,
    // dataType: {String|Number}
    value: null,
    // dataType: {String}
    label: null,
    // dataType: {String}
    placeholder: null,
    // dataType: {String}
    maxlength: null,
    // dataType: {number}
    rows: null,
    // dataType:{String}
    message: null,
    // dataType:{String}
    name: null,

    required: false,

    currentCountBinding: 'inputControl.value.length',

    charactersKeyedIn: _ember['default'].computed('currentCount', function () {
      var control_val = this.get('value') || "";
      var total_count = 0,
          special_chars,
          special_chars_length;

      special_chars = control_val ? control_val.match(/(\r\n|\n|\r)/g) : "";
      special_chars_length = special_chars && special_chars.length || 0;

      var currentLength = parseInt(this.get('currentCount'), 10) || control_val.length;
      total_count = special_chars_length + currentLength;

      var maxlength = parseInt(this.get("maxlength"), 10);
      if (total_count > maxlength) {
        var text = this.value;
        this.set('value', text.substring(0, maxlength - special_chars_length));
      }

      return total_count;
    }).volatile(),

    isMaxCharLengthReached: _ember['default'].computed.equal('charactersKeyedIn', 'maxlength'),

    valueChanged: _ember['default'].observer('value', function () {
      this.send("displayCharCount");
    }),

    keyUp: function keyUp() {
      this.send("displayCharCount");
    },

    didInsertElement: function didInsertElement() {
      this.send("displayCharCount");

      if (!this.get('maxlength')) {
        _ember['default'].assert("InputWithCounter doesn't work without a maxlength attribute");
      }
    },

    actions: {
      displayCharCount: function displayCharCount() {
        var char_counter_message = this.get('charactersKeyedIn') + '/' + this.get('maxlength');
        this.set('message', char_counter_message);
      }
    }
  });
});