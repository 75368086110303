define('goodcity/components/start-page', ['exports', 'ember'], function (exports, _ember) {

  // Scroll to bottom of start page to display language-switcher
  exports['default'] = _ember['default'].Component.extend({
    didInsertElement: function didInsertElement() {
      this._super();

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        if (window.location.pathname === '/') {
          window.scrollTo(0, document.body.scrollHeight);
        }
      });
    }

  });
});