define("goodcity/controllers/search", ["exports", "ember", "ember-i18n", "goodcity/mixins/back_navigator", "goodcity/utils/utility-methods", "lodash"], function (exports, _ember, _emberI18n, _goodcityMixinsBack_navigator, _goodcityUtilsUtilityMethods, _lodash) {
  exports["default"] = _ember["default"].Controller.extend(_goodcityMixinsBack_navigator["default"], {
    filter: "",
    searchText: "",
    searchPlaceholder: (0, _emberI18n.translationMacro)("search.placeholder"),
    minSearchTextLength: 1,
    i18n: _ember["default"].inject.service(),
    displayResults: false,
    filterService: _ember["default"].inject.service(),

    allUsers: _ember["default"].computed(function () {
      return this.store.peekAll("user");
    }),

    allItems: _ember["default"].computed(function () {
      return this.store.peekAll("item");
    }),

    allGogovanOrders: _ember["default"].computed(function () {
      return this.store.peekAll("gogovan_order");
    }),

    allPackageTypes: _ember["default"].computed(function () {
      return this.store.peekAll("package_type");
    }),

    allAddresses: _ember["default"].computed(function () {
      return this.store.peekAll("address");
    }),

    hasSearchText: _ember["default"].computed("searchText", function () {
      return _ember["default"].$.trim(this.get("searchText")).length;
    }),

    onSearchTextChange: _ember["default"].observer("searchText", function () {
      var searchTextLength = this.get("searchText").length;
      if (searchTextLength > this.get("minSearchTextLength") || searchTextLength === 0) {
        this.reloadResults();
      }
    }),

    on: function on() {
      this.showResults(); // Upon opening the page, we populate with results
      this.get("filterService").on("change", this, this.reloadResults);
    },

    off: function off() {
      this.get("filterService").off("change", this, this.reloadResults);
    },

    reloadResults: function reloadResults() {
      this.hideResults();
      _ember["default"].run.debounce(this, this.showResults, 500);
    },

    hideResults: function hideResults() {
      var _this = this;

      _ember["default"].run(function () {
        _this.set("displayResults", false);
      });
    },

    showResults: function showResults() {
      var _this2 = this;

      _ember["default"].run(function () {
        _this2.set("displayResults", true);
      });
    },

    getFilterQuery: function getFilterQuery() {
      var filterService = this.get("filterService");
      var isPriority = filterService.isPriority();
      var isPublished = filterService.isPublished();
      var includeExpiryShareables = filterService.includeExpiryShareables();

      var _filterService$get = filterService.get("offerTimeRange");

      var after = _filterService$get.after;
      var before = _filterService$get.before;

      var stateFilters = _lodash["default"].without(filterService.get("offerStateFilters"), "priorityOffers", "nonExpiredPublishedOffers", "allPublishedOffers");

      return {
        state: _goodcityUtilsUtilityMethods["default"].stringifyArray(stateFilters),
        priority: isPriority,
        shareable: isPublished,
        include_expiry: includeExpiryShareables,
        after: after && after.getTime(),
        before: before && before.getTime()
      };
    },

    getSearchQuery: function getSearchQuery() {
      return {
        searchText: this.get("searchText")
      };
    },

    getPaginationQuery: function getPaginationQuery(pageNo) {
      return {
        per_page: 25,
        page: pageNo
      };
    },

    getReviewerFilter: function getReviewerFilter() {
      return {
        selfReview: this.get("filterService.selfReviewFilter")
      };
    },

    getSortingType: function getSortingType() {
      return this.get("filterService.offerSorting");
    },

    trimQuery: function trimQuery(query) {
      // Remove any undefined values
      return _lodash["default"].pickBy(query, _lodash["default"].identity);
    },

    actions: {
      loadMoreOffers: function loadMoreOffers(pageNo) {
        var params = this.trimQuery(_lodash["default"].merge({ slug: "search" }, //slug: is an identifier used in offer adapter to query url `/offers/search`
        this.getFilterQuery(), this.getReviewerFilter(), this.getSearchQuery(), this.getPaginationQuery(pageNo), this.getSortingType()));
        return this.get("store").query("offer", params);
      },

      clearSearch: function clearSearch() {
        this.set("searchText", "");
      },

      cancelSearch: function cancelSearch() {
        this.transitionToRoute("dashboard");
      }
    }
  });
});