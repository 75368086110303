define('goodcity/instance-initializers/timeago', ['exports', 'ember', 'goodcity/config/environment'], function (exports, _ember, _goodcityConfigEnvironment) {
  exports['default'] = {
    name: 'timeago',

    initialize: function initialize(app) {
      var i18n = app.lookup("service:i18n");
      var loc = function loc(str) {
        return i18n.t("time_ago." + str).string;
      };

      moment.locale('en', {
        relativeTime: {
          future: "",
          past: "",
          s: loc("1m"),
          m: loc("1m"),
          mm: "%d" + loc("m"),
          h: "%d" + loc("h"),
          hh: "%d" + loc("h"),
          d: "%d" + loc("d"),
          dd: "%d" + loc("d"),
          M: "%d" + loc("mth"),
          MM: "%d" + loc("mths"),
          y: "%d" + loc("y"),
          yy: "%d" + loc("y")
        }
      });

      if (_goodcityConfigEnvironment['default'].environment !== "test") {
        _ember['default'].run.later(this, this.updateTime, 60000);
      }
    },

    updateTime: function updateTime() {
      _ember['default'].$(".timeago").each(function (idx, elm) {
        return elm.innerText = moment(elm.getAttribute("datetime")).fromNow(true);
      });
      _ember['default'].run.later(this, this.updateTime, 60000);
    }
  };
});