define("goodcity/controllers/review_item/reject", ["exports", "ember", "ember-i18n"], function (exports, _ember, _emberI18n) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Controller.extend({
    reviewItem: _ember["default"].inject.controller(),
    reviewOfferController: _ember["default"].inject.controller("review_offer"),
    offer: _ember["default"].inject.controller(),

    itemTypeId: _ember["default"].computed.alias("reviewItem.itemTypeId"),
    itemId: _ember["default"].computed.alias("reviewItem.model.id"),
    rejectionReasonId: _ember["default"].computed.alias("model.rejectionReason.id"),
    rejectReasonPlaceholder: (0, _emberI18n.translationMacro)("reject.custom_reason"),
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    itemPackages: _ember["default"].computed.alias("item.packages"),
    rejectMsg: "",

    rejectReason: _ember["default"].computed("itemId", function () {
      return this.get("reviewItem.model.rejectReason");
    }),

    isBlank: _ember["default"].computed({
      get: function get() {
        return false;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    noReasonSelected: _ember["default"].computed({
      get: function get() {
        return false;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    selectedId: _ember["default"].computed("rejectionReasonId", {
      get: function get() {
        this.set("isBlank", false);
        var reasonId = this.get("rejectionReasonId");
        if (reasonId) {
          return reasonId;
        } else {
          if (this.get("rejectReason") && this.get("rejectReason").length) {
            return "-1";
          }
        }
      },
      set: function set(key, value) {
        this.set("isBlank", false);
        this.set("noReasonSelected", false);
        return value;
      }
    }),

    rejectionOptions: _ember["default"].computed(function () {
      return this.store.peekAll("rejection_reason").sortBy("id");
    }),

    cannotSave: function cannotSave() {
      var pkgs = this.store.peekRecord("item", this.get("itemId")).get("packages");
      if (pkgs && pkgs.length && (pkgs.get("firstObject.hasAllPackagesDesignated") || pkgs.get("firstObject.hasAllPackagesDispatched"))) {
        this.get("messageBox").alert(this.get("i18n").t("designated_dispatched_error"));
        return true;
      }
      return false;
    },

    rejectValidation: function rejectValidation(selectedReason, rejectProperties) {
      if (selectedReason === undefined) {
        this.set("noReasonSelected", true);
        return false;
      }
      if (selectedReason === "-1" && _ember["default"].$.trim(rejectProperties.rejectReason).length === 0) {
        this.set("isBlank", true);
        return false;
      }
      return true;
    },

    rejectProperties: function rejectProperties() {
      return {
        rejectReason: this.get("rejectReason"),
        rejectionComments: this.get("rejectMsg"),
        rejectionReason: this.store.peekRecord("rejection_reason", this.get("selectedId")),
        state_event: "reject",
        id: this.get("itemId"),
        offer: this.get("offer.model"),
        packageType: this.store.peekRecord("packageType", this.get("itemTypeId"))
      };
    },

    actions: {
      setRejectOption: function setRejectOption() {
        this.set("selectedId", "-1");
      },

      setMessage: function setMessage(message) {
        this.set("rejectMsg", message);
      },

      rejectItem: function rejectItem() {
        var _this = this;

        if (this.get("itemId") && this.cannotSave()) {
          return false;
        }
        var selectedReason = this.get("selectedId");
        var rejectProperties = this.rejectProperties();

        if (!this.rejectValidation(selectedReason, rejectProperties)) {
          return false;
        }

        if (selectedReason !== "-1") {
          rejectProperties.rejectReason = null;
          this.set("rejectReason", null);
        }

        var offer = this.get("offer.model");

        var saveItem = function saveItem() {
          var loadingView = getOwner(_this).lookup("component:loading").append();
          var item = _this.store.peekRecord("item", _this.get("itemId"));
          item.setProperties(rejectProperties);

          // Save changes to Item
          item.save().then(function () {
            _this.transitionToRoute("review_offer.items");
            _this.get("reviewOfferController").set("displayCompleteReviewPopup", offer.get("allItemsReviewed") && offer.get("isUnderReview"));
          })["catch"](function (error) {
            if (item) {
              item.rollbackAttributes();
            }
            if (error.errors instanceof Array && error.errors.filter(function (e) {
              return !!e["requires_gogovan_cancellation"];
            }).length > 0) {
              return _this.transitionToRoute("offer.cancel_gogovan", offer);
            }
            throw error;
          })["finally"](function () {
            return loadingView.destroy();
          });
        };

        // if rejecting last accepted item but gogovan is booked display gogovan cancellation page
        var gogovanOrder = offer.get("delivery.gogovanOrder");
        var itemIsLastAccepted = offer.get("approvedItems").every(function (i) {
          return i.id === _this.get("itemId");
        });

        if (itemIsLastAccepted && gogovanOrder) {
          if (gogovanOrder.get("isPickedUp")) {
            this.get("messageBox").alert(this.get("i18n").t("reject.cannot_reject_error"));
          } else {
            this.get("messageBox").confirm(this.get("i18n").t("reject.cancel_gogovan_confirm"), function () {
              if (gogovanOrder.get("isActive")) {
                _this.transitionToRoute("offer.cancel_gogovan", offer);
              } else {
                saveItem();
              }
            });
          }
        } else {
          saveItem();
        }
      }
    }
  });
});