define("goodcity/utils/helpers", ["exports", "lodash"], function (exports, _lodash) {
  exports.toID = toID;

  /**
   *
   * @export
   * @param {Model|string} modelOrId
   * @returns {string}
   */

  function toID(modelOrId) {
    if (modelOrId.get) {
      return modelOrId.get("id");
    }
    return modelOrId;
  }
});