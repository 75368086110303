define('goodcity/components/cloudinary-upload', ['exports', 'ember', 'goodcity/utils/ajax-promise', 'goodcity/config/environment'], function (exports, _ember, _goodcityUtilsAjaxPromise, _goodcityConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: "input",
    type: "file",
    accept: "image/*",
    name: "file",
    classNames: ["cloudinary-fileupload", "hidden_file_input"],
    "data-cloudinary-field": "image_upload",
    "data-url": _goodcityConfigEnvironment['default'].APP.CLOUD_URL,
    attributeBindings: ["name", "type", "value", "data-cloudinary-field", "data-url", "data-form-data", "disabled", "style", "accept", "offerId"],

    disabled: true,
    offerId: null,

    messageBox: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    didInsertElement: function didInsertElement() {
      var _this2 = this;

      var _this = this;

      // https://github.com/blueimp/jQuery-File-Upload/wiki/Options
      var options = {
        dataType: 'json',
        timeout: 120000, // 2 minute
        imageMaxHeight: 800,
        imageMaxWidth: 800,
        disableImageResize: false,

        fail: function fail(e, data) {
          if (data.errorThrown === "timeout") {
            _this.get("messageBox").alert(_this.get("i18n").t('upload-image.upload_error'));
          }
        }
      };

      // forward cloudinary events
      ["submit", "progress", "always", "fail", "done"].forEach(function (ev) {
        if (_this2[ev]) {
          options[ev] = function (e, data) {
            return _ember['default'].run(function () {
              return _this2.sendAction(ev, e, data);
            });
          };
        }
      });

      var reqData = this.get("offerId") ? { tags: 'offer_' + this.get('offerId') } : {};
      new _goodcityUtilsAjaxPromise['default']("/images/generate_signature", "GET", this.get('session.authToken'), reqData).then(function (data) {
        if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
          _ember['default'].$(_this.element).attr("data-form-data", JSON.stringify(data)).cloudinary_fileupload(options);
          _this.set("disabled", false);
          _this.sendAction("ready");
        }
      });
    }
  });
});