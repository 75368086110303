define('goodcity/components/variable-height-textarea', ['exports', 'ember', 'goodcity/config/environment'], function (exports, _ember, _goodcityConfigEnvironment) {
  exports['default'] = _ember['default'].TextArea.extend({
    tagName: "textarea",
    attributeBindings: ["disabled"],
    disabled: false,
    cordova: _ember['default'].inject.service(),

    valueChanged: _ember['default'].observer('value', function () {
      var _this = this;
      var textarea = _this.element;

      if (textarea) {
        _ember['default'].run.once(function () {
          // auto-resize height of textarea $('textarea')[0].
          if (textarea.scrollHeight < 120) {
            _ember['default'].$(textarea).css({ 'height': 'auto', 'overflow-y': 'hidden' }).height(textarea.scrollHeight - 15);

            var parent = _this.get('parentDiv');
            var grandParentDiv = _ember['default'].$('.' + parent).closest(".review_item ");
            if (grandParentDiv.length === 0) {

              // auto-move textarea by chaning margin of parentDiv
              var paddingSize = _goodcityConfigEnvironment['default'].cordova.enabled ? 5 : textarea.scrollHeight - 40;
              _ember['default'].$('.' + parent).css({ 'padding-bottom': paddingSize > 0 ? paddingSize : 0 });

              // scrolling down to bottom of page
              if (_this.get("value") !== "") {
                window.scrollTo(0, document.body.scrollHeight);
              }
            }
          } else {
            _ember['default'].$(textarea).css({ 'height': 'auto', 'overflow-y': 'auto' }).height(105);
          }
        });
      }
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;
      var parent = _this.get('parentDiv');
      var grandParentDiv = _ember['default'].$('.' + parent).closest(".review_item ");

      // Apply only in Donor Cordova App.
      if (grandParentDiv.length === 0 && _goodcityConfigEnvironment['default'].cordova.enabled) {

        var msgTextbox = _ember['default'].$(_ember['default'].$(_this.element).closest(".message-textbar"));

        _ember['default'].run.scheduleOnce('afterRender', this, function () {

          var isIOS = _this.get("cordova").isIOS();

          var height = isIOS ? 55 : 30;
          _ember['default'].$(".message-footer").height(height);

          _ember['default'].$(_this.element).focus(function () {

            if (isIOS) {
              if (document.body.scrollHeight === _ember['default'].$(window).height()) {
                _ember['default'].$(".message-footer").addClass("message_footer_small_page");
              } else {
                _ember['default'].$(".message-footer").removeClass("message_footer_small_page");
              }
              msgTextbox.css({ 'position': 'relative' });
            } else {
              var positionVal = document.body.scrollHeight === _ember['default'].$(window).height() ? 'fixed' : 'relative';
            }

            window.scrollTo(0, document.body.scrollHeight);
          });

          _ember['default'].$(_this.element).blur(function () {
            msgTextbox.css({ 'position': 'fixed' });
          });
        });
      }
    }

  });
});