define("goodcity/components/inventory-number-input", ["exports", "ember", "goodcity/config/environment", "goodcity/utils/ajax-promise"], function (exports, _ember, _goodcityConfigEnvironment, _goodcityUtilsAjaxPromise) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Component.extend({
    i18n: _ember["default"].inject.service(),
    attributeBindings: ["name", "inputId", "value", "invalid", "disabled", "packageId"],
    isCordovaApp: _goodcityConfigEnvironment["default"].cordova.enabled,
    messageBox: _ember["default"].inject.service(),
    showMenu: false,
    bardcodeReadonly: true,

    checkPermissionAndScan: function checkPermissionAndScan() {
      var _this2 = this;

      var _this = this;
      var permissions = cordova.plugins.permissions;
      var permissionError = function permissionError() {
        var error_message = _this2.get("i18n").t("camera_scan.permission_error");
        _this.get("messageBox").alert(error_message);
      };
      var permissionSuccess = function permissionSuccess(status) {
        //after requesting check for permission then, permit to scan
        if (status.hasPermission) {
          _this.scan();
        } else {
          permissionError();
        }
      };
      permissions.checkPermission(permissions.CAMERA, function (status) {
        //check permission here
        if (status.hasPermission) {
          _this.scan();
        } else {
          //request permission here
          permissions.requestPermission(permissions.CAMERA, permissionSuccess, permissionError);
        }
      });
    },

    scan: function scan() {
      var _this3 = this;

      var options = { formats: "CODE_128" };
      var onError = function onError(error) {
        return _this3.get("messageBox").alert("Scanning failed: " + error);
      };
      var onSuccess = function onSuccess(res) {
        if (!res.cancelled) {
          _this3.set("value", res.text);
        }
      };
      cordova.plugins.barcodeScanner.scan(onSuccess, onError, options);
    },

    actions: {
      toggleMenu: function toggleMenu() {
        this.toggleProperty("showMenu");
      },

      scanBarcode: function scanBarcode() {
        this.checkPermissionAndScan();
      },

      printBarcode: function printBarcode() {
        var _this4 = this;

        var loadingView = getOwner(this).lookup("component:loading").append();
        new _goodcityUtilsAjaxPromise["default"]("/packages/print_barcode", "POST", this.get("session.authToken"), { package_id: this.get("packageId") })["catch"](function (xhr) {
          if (xhr.status !== 200) {
            var errors = xhr.responseText;
            try {
              errors = _ember["default"].$.parseJSON(xhr.responseText).errors;
            } catch (err) {
              console.log(err);
            }
            _this4.get("messageBox").alert(errors);
          } else {
            throw xhr;
          }
        }).then(function (data) {
          _this4.set("value", data["inventory_number"]);
        })["finally"](function () {
          return loadingView.destroy();
        });
      },

      enterBarcode: function enterBarcode() {
        this.set("bardcodeReadonly", false);
      }
    }
  });
});