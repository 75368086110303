define("goodcity/models/package", ["exports", "ember-data", "goodcity/computed/foreign-key", "ember"], function (exports, _emberData, _goodcityComputedForeignKey, _ember) {

  var attr = _emberData["default"].attr,
      hasMany = _emberData["default"].hasMany,
      belongsTo = _emberData["default"].belongsTo;

  exports["default"] = _emberData["default"].Model.extend({
    availableQuantity: attr("number"),
    onHandQuantity: attr("number"),
    dispatchedQuantity: attr("number"),
    designatedQuantity: attr("number"),
    receivedQuantity: attr("number"),
    quantity: _ember["default"].computed.alias("availableQuantity"),
    length: attr("number"),
    width: attr("number"),
    height: attr("number"),
    notes: attr("string"),
    notesZhTw: attr("string"),
    state: attr("string", { defaultValue: "expecting" }),
    state_event: attr("string"),
    receivedAt: attr("date"),
    rejectedAt: attr("date"),
    createdAt: attr("date"),
    updatedAt: attr("date"),
    item: belongsTo("item", { async: false }),
    packageType: belongsTo("package_type", { async: false }),
    designation: belongsTo("designation", { async: true }),
    location: belongsTo("location", { async: false }),
    donorCondition: belongsTo("donor_condition", { async: false }),
    ordersPackages: hasMany("orders_package", { async: true }),
    packagesLocations: hasMany("packages_location", { async: true }),
    offerId: attr("number"),
    inventoryNumber: attr("string"),
    grade: attr("string"),
    sentOn: attr("date"),
    designationId: attr("number"),
    favouriteImageId: attr("number"),
    packageSetId: attr("number"),
    allowWebPublish: attr("boolean"),
    packagesLocationsAttributes: attr(),
    valueHkDollar: attr("string"),

    donorConditionId: _ember["default"].computed.foreignKey("donorCondition.id"),

    isReceived: _ember["default"].computed.equal("state", "received"),

    packageName: _ember["default"].computed("packageType", function () {
      return this.get("packageType.name");
    }),

    changedNotes: _ember["default"].computed.alias("notes"),

    packageTypeId: _ember["default"].computed.foreignKey("packageType.id"),

    packageTypeObject: _ember["default"].computed("packageType", function () {
      var obj = this.get("packageType").getProperties("id", "name", "isItemTypeNode");
      obj.id = obj.packageTypeId = parseInt(obj.id, 10);
      return obj;
    }),

    dimensions: _ember["default"].computed("width", "height", "length", function () {
      var res = "";
      var append = function append(val) {
        if (val) {
          res += !res ? val : " x " + val;
        }
      };
      append(this.get("width"));
      append(this.get("height"));
      append(this.get("length"));
      return !res ? "" : res + "cm";
    }),

    displayImageUrl: _ember["default"].computed("favouriteImage", function () {
      return this.getWithDefault("favouriteImage.thumbImageUrl", "");
    }),

    images: hasMany("image", {
      async: false
    }),

    packageImages: _ember["default"].computed.alias("images"),

    favouriteImage: _ember["default"].computed("images.@each.favourite", function () {
      var images = this.get("images");

      return images.findBy("favourite") || images.sortBy("id").get("firstObject");
    }),

    hasOneDesignatedPackage: _ember["default"].computed("ordersPackages.@each.quantity", "ordersPackages.@each.state", "ordersPackages.[]", function () {
      var designatedOrdersPackages = this.get("ordersPackages").filterBy("state", "designated");
      return designatedOrdersPackages.get("length") > 1 || designatedOrdersPackages.get("length") === 0 ? false : designatedOrdersPackages[0];
    }),

    hasOneDispatchedPackage: _ember["default"].computed("ordersPackages.@each.quantity", "ordersPackages.@each.state", "ordersPackages.[]", function () {
      var dispatchedOrdersPackages = this.get("ordersPackages").filterBy("state", "dispatched");
      return dispatchedOrdersPackages.get("length") > 1 || dispatchedOrdersPackages.get("length") === 0 ? false : dispatchedOrdersPackages[0];
    }),

    hasAllPackagesDispatched: _ember["default"].computed("dispatchedQuantity", "onHandQuantity", function () {
      return this.get("onHandQuantity") === 0 && this.get("dispatchedQuantity") > 0;
    }),

    hasAllPackagesDesignated: _ember["default"].computed("designatedQuantity", "availableQuantity", function () {
      return this.get("availableQuantity") === 0 && this.get("designatedQuantity") > 0;
    }),

    designatedOrdersPackages: _ember["default"].computed("ordersPackages.@each.quantity", "ordersPackages.@each.state", "ordersPackages.[]", function () {
      return this.get("ordersPackages").filterBy("state", "designated");
    }),

    dispatchedOrdersPackages: _ember["default"].computed("ordersPackages.@each.quantity", "ordersPackages.@each.state", "ordersPackages.[]", function () {
      return this.get("ordersPackages").filterBy("state", "dispatched");
    }),

    dispatchedItemCount: _ember["default"].computed("ordersPackages.@each.quantity", function () {
      return this.get("ordersPackages").filterBy("state", "dispatched").length;
    }),

    cancelledItemCount: _ember["default"].computed("ordersPackages.@each.quantity", function () {
      return this.get("ordersPackages").filterBy("state", "cancelled").length;
    }),

    hasSingleLocation: _ember["default"].computed("packagesLocations.[]", "packagesLocations.@each.quantity", function () {
      return _ember["default"].isEqual(this.get("packagesLocations.length"), 1);
    }),

    firstLocationName: _ember["default"].computed("packagesLocations.[]", "packagesLocations.@each.quantity", function () {
      return this.get("packagesLocations.firstObject.location.name");
    }),

    hasMultiLocations: _ember["default"].computed("packagesLocations.[]", "packagesLocations.@each.quantity", function () {
      return this.get("packagesLocations.length") > 1;
    })
  });
});