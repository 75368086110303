define('goodcity/routes/delivery/drop_off_schedule', ['exports', 'goodcity/routes/delivery/verify_offer_state'], function (exports, _goodcityRoutesDeliveryVerify_offer_state) {
  exports['default'] = _goodcityRoutesDeliveryVerify_offer_state['default'].extend({

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (model.get('schedule') && model.get("wasDropOff")) {
        var selectedSlot = model.get('schedule.slot').toString();
        var timeslot = controller.get('slots').filterBy('id', selectedSlot).get('firstObject');
        controller.set('selectedDate', model.get('schedule.scheduledAt'));
        controller.set('selectedId', timeslot);
      } else {
        controller.set('selectedDate', null);
        controller.set('selectedId', null);
      }
    }

  });
});