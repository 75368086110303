define("goodcity/components/radio-text-input", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].TextField.extend({
    tagName: "input",
    type: "text",
    attributeBindings: ["name", "id", "value", 'disabled', 'placeholder'],
    disabled: false,

    click: function click() {
      _ember["default"].$(this.element).closest("li").find("input[type='radio']").prop('checked', true);
    }
  });
});