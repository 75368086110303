define("goodcity/controllers/review_offer/donor_details", ["exports", "ember", "goodcity/config/environment", "goodcity/mixins/async_tasks"], function (exports, _ember, _goodcityConfigEnvironment, _goodcityMixinsAsync_tasks) {
  exports["default"] = _ember["default"].Controller.extend(_goodcityMixinsAsync_tasks["default"], {
    donor: null,
    currentOffer: null,
    offersCount: _ember["default"].computed.alias("model.length"),
    goodcityNumber: _goodcityConfigEnvironment["default"].APP.GOODCITY_NUMBER,
    internetCallStatus: _ember["default"].inject.controller(),

    displayCompanyOptions: false,
    displayAltPhoneOptions: false,
    displayDonorMobileOptions: false,
    displayDonorOptions: false,

    stickyNote: {
      showCallToAction: true
    },

    districts: _ember["default"].computed(function () {
      return this.store.peekAll("district").sortBy("name");
    }),

    showNoteCallToAction: _ember["default"].computed("currentOffer.notes", "stickyNote.showCallToAction", function () {
      var note = this.get("currentOffer.notes");
      return !note && this.get("stickyNote.showCallToAction");
    }),

    stickNoteChanged: _ember["default"].computed("currentOffer.notes", function () {
      var changes = this.get("currentOffer").changedAttributes().notes;
      return changes && changes.some(function (it) {
        return it;
      });
    }),

    displayNumber: _ember["default"].computed("donor.mobile", function () {
      var donor = this.get("donor");
      if (!donor) {
        return "";
      }

      var num = donor.get("mobile").replace(/\+852/, "");
      return num.length > 4 ? num.substr(0, 4) + " " + num.substr(4) : num;
    }),

    donorOffers: _ember["default"].computed("model", function () {
      return this.get("model").rejectBy("id", this.get("currentOffer.id"));
    }),

    receivedOffers: _ember["default"].computed("model", function () {
      return this.get("model").filterBy("isReceived", true).length;
    }),

    actions: {
      setOfferDistrict: function setOfferDistrict(district) {
        var _this = this;

        this.runTask(function () {
          var offer = _this.get('currentOffer');
          offer.set('district', district);
          return offer.save();
        });
      },

      toggleOptions: function toggleOptions(optionName) {
        var _this2 = this;

        var optionNames = ["displayCompanyOptions", "displayDonorMobileOptions", "displayAltPhoneOptions", "displayDonorOptions"];
        optionNames.forEach(function (item) {
          if (item !== optionName && _this2.get(item)) {
            _this2.toggleProperty(item);
          }
        });
        this.toggleProperty(optionName);
      },

      removeCompany: function removeCompany() {
        var offer = this.get("currentOffer");
        offer.set("companyId", null);
        offer.set("company", null);
        return offer.save();
      },

      removeContact: function removeContact() {
        var offer = this.get("currentOffer");
        offer.set("createdById", null);
        offer.set("createdBy", null);
        return offer.save();
      },

      hideNoteCallToAction: function hideNoteCallToAction() {
        this.set("stickyNote.showCallToAction", false);
      },
      showNoteCallToAction: function showNoteCallToAction() {
        this.set("stickyNote.showCallToAction", true);
      },
      saveStickyNote: function saveStickyNote() {
        var _this3 = this;

        if (!this.get("stickNoteChanged")) {
          return;
        }

        this.runTask(function () {
          var offer = _this3.get("currentOffer");
          return offer.save();
        }).then(function () {
          _this3.notifyPropertyChange("stickNoteChanged");
        });
      }
    }
  });
});