define("goodcity/routes/my_notifications", ["exports", "goodcity/routes/authorize"], function (exports, _goodcityRoutesAuthorize) {
  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.on();
    },

    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);
      controller.off();

      if (isExiting) {
        controller.set("notifications", []);
        controller.set("hasLoadedReadMessages", false);
      }
    }
  });
});