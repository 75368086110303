define("goodcity/components/date-picker", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {

  function isValidDate(d) {
    return d && d instanceof Date && !isNaN(d);
  }

  exports["default"] = _ember["default"].TextField.extend({
    tagName: "input",
    classNames: "pickadate",
    attributeBindings: ["name", "type", "value", "id", "required", "pattern", "placeholder", "minDate", "maxDate"],

    model: _ember["default"].computed({
      get: function get() {
        return this.get("_model");
      },
      set: function set(k, val) {
        if (!isValidDate(val)) {
          val = null;
        }
        this.set("_model", val);
        this.set("value", val ? moment(val).format("ddd MMM D") : "");
        return val;
      }
    }),

    didInsertElement: function didInsertElement() {
      var cmp = this;

      _ember["default"].run.scheduleOnce("afterRender", this, function () {
        _ember["default"].$(this.element).pickadate({
          format: "ddd mmm d",
          monthsFull: moment.months(),
          monthsShort: moment.monthsShort(),
          weekdaysShort: moment.weekdaysShort(),
          clear: false,
          today: false,
          close: false,

          onClose: function onClose() {
            _ember["default"].$(document.activeElement).blur();
          },

          onOpen: function onOpen() {
            var date = cmp.get("model");
            this.set("val", date ? date : null);
            var minDate = cmp.get("minDate");
            var maxDate = cmp.get("maxDate");

            this.set("min", minDate || "");
            this.set("max", maxDate || "");
          },

          onSet: function onSet() {
            // Support for callback
            var onSelect = cmp.get("onSelect");
            var date = this.get("val") || this.get("select") && this.get("select").obj;
            if (isValidDate(date) && _lodash["default"].isFunction(onSelect)) {
              onSelect(date);
            }
          }
        });
      });
    }
  });
});