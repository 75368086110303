define('goodcity/components/message-box', ['exports', 'ember', 'goodcity/templates/components/message-box'], function (exports, _ember, _goodcityTemplatesComponentsMessageBox) {
  exports['default'] = _ember['default'].Component.extend({

    layout: _goodcityTemplatesComponentsMessageBox['default'],
    message: "",
    btn1Text: "",
    btn1Callback: function btn1Callback() {},
    btn2Text: "",
    btn2Callback: function btn2Callback() {},
    displayCloseLink: false,
    router: _ember['default'].inject.service('-routing'),

    isVisible: false,

    close: function close() {
      if (this.get("isVisible")) {
        this.set("isVisible", false);
      }
    },

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.router.addObserver('currentRouteName', function () {
        return _this.close();
      });
    },

    actions: {
      btn1Click: function btn1Click() {
        var callbackOutput = true;
        if (this.btn1Callback) {
          callbackOutput = this.btn1Callback();
        }
        if (callbackOutput !== false) {
          this.close();
        }
      },

      btn2Click: function btn2Click() {
        if (this.btn2Callback) {
          this.btn2Callback();
        }
        this.close();
      },

      closeModal: function closeModal() {
        this.close();
      }
    }
  });
});