define('goodcity/routes/offer/plan_delivery', ['exports', 'goodcity/routes/authorize'], function (exports, _goodcityRoutesAuthorize) {
  exports['default'] = _goodcityRoutesAuthorize['default'].extend({

    queryParams: {
      modify: false
    },

    beforeModel: function beforeModel(params) {
      var offerId = this.modelFor('offer').get('id');
      var offer = this.store.peekRecord('offer', offerId);

      if (offer) {
        if (offer.get('isScheduled') && !params.queryParams.modify || !(offer.get("isReviewed") || offer.get('isScheduled'))) {
          if (this.get('session.isAdminApp')) {
            this.transitionTo('review_offer.logistics', offer);
          } else {
            this.transitionTo('offer.transport_details', offer);
          }
        }
      } else {
        this.transitionTo("offers");
      }
    }
  });
});