define('goodcity/models/rejection_reason', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

  var attr = _emberData['default'].attr,
      hasMany = _emberData['default'].hasMany;

  exports['default'] = _emberData['default'].Model.extend({
    name: attr('string'),
    items: hasMany('item', { async: false }),

    specialId: _ember['default'].computed('id', function () {
      return this.get("id") + "_reason";
    })
  });
});