define('goodcity/components/go-to-top-button', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    didInsertElement: function didInsertElement() {
      this._super();

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var offset = 300;
        var duration = 300;

        _ember['default'].$('.sticky_title_bar').on('click', '.back', function () {
          window.scrollTo(0, 0);
        });

        _ember['default'].$(window).scroll(function () {
          if (_ember['default'].$(this).scrollTop() > offset) {
            _ember['default'].$('.back-to-top').fadeIn(duration);
          } else {
            _ember['default'].$('.back-to-top').fadeOut(duration);
          }
        });

        _ember['default'].$('.back-to-top').click(function () {
          _ember['default'].$('html, body').animate({ scrollTop: 0 }, duration);
          return false;
        });
      });
    }
  });
});