define('goodcity/components/loading', ['exports', 'ember', 'goodcity/templates/loading'], function (exports, _ember, _goodcityTemplatesLoading) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _goodcityTemplatesLoading['default'],
    classNames: ["loading-indicator"],
    messageBox: _ember['default'].inject.service(),
    logger: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    timer: null,
    prompt: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var timer = _ember['default'].run.later(function () {
        _this.get("logger").error(new Error(_this.get("i18n").t("loading_timeout_error")));

        var cancelCallback = function cancelCallback() {
          _this.destroy();
          window.location.reload();
        };

        var continueCallback = function continueCallback() {
          if (!_this.get("isDestroyed")) {
            _ember['default'].$(document).off("cancel-loading-timer");
            _this.didInsertElement.call(_this);
          }
        };

        var view = _this.get("messageBox").custom(_this.get("i18n").t("loading_timeout"), _this.get("i18n").t("cancel"), continueCallback, _this.get("i18n").t("okay"), cancelCallback, false);

        if (view) {
          // we already have a black overlay from loading screen, so prevent this one making it darker
          view.on("didInsertElement", function () {
            view.$(".reveal-modal-bg").css("background-color", "transparent");
          });

          _this.set("prompt", view);
        }
      }, 30000);

      this.set("timer", timer);
    },

    willDestroyElement: function willDestroyElement() {
      _ember['default'].run.cancel(this.get("timer"));
      var view = this.get("prompt");
      if (view) {
        this.get("prompt").destroy();
      }
    }
  });
});