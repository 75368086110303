define("goodcity/components/radio-button", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    tagName: "input",
    type: "radio",
    attributeBindings: ["name", "type", "value", "checked", "labelText", "disabled"],
    disabled: false,

    click: function click() {
      this.set("selection", this.$().val());
    },

    checked: _ember["default"].computed('selection', function () {

      // This block added for setting selection of reject item options.
      if (_ember["default"].$.trim(this.labelText).length > '0' && this.get('selection.isController')) {
        this.set("selection", '-1');
      }

      return this.get("value") === this.get("selection");
    }),

    onInit: _ember["default"].on('init', function () {
      if (this.get("value") === this.get("selection")) {
        this.set("checked", true);
      }
    })
  });
});