define("goodcity/components/canned-content", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    isClicked: false,

    actions: {
      handleClick: function handleClick() {
        this.toggleProperty("isClicked");
      }
    }
  });
});