define("goodcity/serializers/shareable", ["exports", "ember", "lodash", "ember-data/serializers/json-api"], function (exports, _ember, _lodash, _emberDataSerializersJsonApi) {

  function addPolymorphismSupport(attributes) {
    if (attributes) {
      var resource_type = attributes.resource_type;
      var resource_id = attributes.resource_id;

      attributes[resource_type.toLowerCase() + "_id"] = resource_id;
    }
  }

  exports["default"] = _emberDataSerializersJsonApi["default"].extend({
    keyForAttribute: function keyForAttribute(attr) {
      return _ember["default"].String.underscore(attr);
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var records = Array.isArray(payload.data) ? payload.data : [payload.data];

      _lodash["default"].map(records, "attributes").forEach(addPolymorphismSupport);

      return this._super.apply(this, arguments);
    },

    serialize: function serialize(snapshot, options) {
      return {
        resource_id: snapshot.attr("resourceId"),
        resource_type: snapshot.attr("resourceType"),
        allow_listing: snapshot.attr("allowListing"),
        expires_at: snapshot.attr("expiresAt"),
        notes: snapshot.attr("notes"),
        notes_zh_tw: snapshot.attr("notesZhTw")
      };
    },

    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      if (snapshot.record.get("isNew") || snapshot.changedAttributes()[key]) {
        this._super.apply(this, arguments);
      }
    }
  });
});