define("goodcity/routes/review_offer/share/chat", ["exports", "goodcity/routes/message_base"], function (exports, _goodcityRoutesMessage_base) {
  exports["default"] = _goodcityRoutesMessage_base["default"].extend({
    model: function model(_ref) {
      var user_id = _ref.user_id;
      var offerResponse;
      return regeneratorRuntime.async(function model$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this.set("userId", user_id);
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.store.query("offerResponse", {
              offer_response: { user_id: user_id, offer_id: this.modelFor("offer").id }
            }));

          case 3:
            offerResponse = context$1$0.sent;
            return context$1$0.abrupt("return", this.store.query("message", {
              messageable_type: "OfferResponse",
              messageable_id: offerResponse.content[0].id
            }));

          case 5:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    setupController: function setupController(controller, recipient) {
      this._super(controller, recipient);
      controller.set("recipient", recipient);
      controller.set("recipientId", this.get("userId"));
      controller.set("offer", this.modelFor("offer"));
    }
  });
});