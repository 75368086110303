define("goodcity/routes/terms_and_conditions", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({
    renderTemplate: function renderTemplate() {
      var controllerName = this.get("session.isAdminApp") ? "offers" : "application";

      this.render(); // default template
      this.render('appMenuList', {
        into: 'terms_and_conditions',
        outlet: 'appMenuList',
        controller: controllerName
      });
    }
  });
});