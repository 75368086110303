define("goodcity/routes/review_item", ["exports", "goodcity/routes/authorize"], function (exports, _goodcityRoutesAuthorize) {
  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    editItemRequest: "",

    beforeModel: function beforeModel() {
      var previousRoutes = this.router.router.currentHandlerInfos;
      var previousRoute = previousRoutes && previousRoutes.pop();
      if (previousRoute) {
        var editItemRequest = ["review_offer.items", "review_offer.receive"].indexOf(previousRoute.name) >= 0;
        this.set("editItemRequest", editItemRequest);
      }
    },

    model: function model(params) {
      return this.store.findRecord("item", params.item_id);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("existingPackageType", model.get("packageType"));

      if (this.get("editItemRequest")) {
        var itemDetails = {
          donorConditionId: model.get("donorConditionId"),
          donorDescription: model.get("donorDescription")
        };
        controller.set("formData", itemDetails);
      }
    }
  });
});