define("goodcity/controllers/offer/gogovan_charges", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({

    i18n: _ember["default"].inject.service(),

    info: _ember["default"].computed(function () {
      var chargesInfo = this.get("i18n").t("gogovan_charges.info").string;
      return "<div>" + chargesInfo.replace(/\n\n/g, "</div><div>") + "</div>";
    })

  });
});