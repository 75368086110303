define("goodcity/routes/review_item/accept", ["exports", "goodcity/routes/authorize"], function (exports, _goodcityRoutesAuthorize) {
  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.notifyPropertyChange("itemTypeId");
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        var reviewItem = controller.get("reviewItem");
        if (!reviewItem.get("isPackageTypeChanged")) {
          reviewItem.set("model.packageType", reviewItem.get("existingPackageType"));
        }
      }
    }
  });
});