define('goodcity/components/notification-bar', ['exports', 'ember', 'goodcity/config/environment'], function (exports, _ember, _goodcityConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({

    store: _ember['default'].inject.service(),
    isCordovaApp: _goodcityConfigEnvironment['default'].cordova.enabled,

    animateNotification: _ember['default'].observer('currentController.model.[]', function () {
      var box = _ember['default'].$(".contain-to-grid.notification");
      var notification = this.get("currentController").retrieveNotification();

      if (!notification) {
        box.hide();
        return;
      }

      if (!this.get("isCordovaApp") && 'Notification' in window) {
        this.desktopNotification(notification);
      }

      if (box.is(":hidden")) {
        box.slideDown();
        _ember['default'].$(".sticky_title_bar").animate({
          top: '5%'
        }, 1000);
        _ember['default'].run.later(this, this.removeNotification, notification, 6000);
      }
    }).on("didInsertElement"),

    removeNotification: function removeNotification(notification) {
      var controller = this.get("currentController");
      if (controller) {
        var remove = function remove() {
          controller.get("model").removeObject(notification);
        };
        var newNotification = controller.retrieveNotification(1);
        if (newNotification) {
          remove();
          _ember['default'].run.later(this, this.removeNotification, newNotification, 6000);
        } else {
          _ember['default'].$(".contain-to-grid.notification").slideUp(1000, remove);
          _ember['default'].$(".sticky_title_bar").animate({
            top: '0'
          }, 1000);
        }
      }
    },

    desktopNotification: function desktopNotification(data) {
      if (Notification.permission === "granted") {
        var text = data.message;
        if (data.category === "message") {
          var user = this.get('store').peekRecord('user', data.author_id);
          text = "New " + data.category + " from " + user.get('firstName') + " " + user.get('lastName') + "\n" + data.message;
        }
        this.sendDesktopNotification(text);
      }
    },

    sendDesktopNotification: function sendDesktopNotification(text) {
      var notification = new Notification('Goodcity Admin', {
        icon: 'assets/images/icon.png',
        body: text,
        tag: 'soManyNotification'
      });
      //'tag' handles muti tab scenario i.e when multiple tabs are open then only
      // only one notification is sent

      notification.onclick = function () {
        parent.focus();
        window.focus(); //just in case, older browsers
        this.close();
      };
      setTimeout(notification.close.bind(notification), 5000);
    }

  });
});