define("goodcity/routes/application", ["exports", "ember", "goodcity/mixins/preload_data", "goodcity/utils/polyfill"], function (exports, _ember, _goodcityMixinsPreload_data, _goodcityUtilsPolyfill) {
  var getOwner = _ember["default"].getOwner;

  var getString = function getString(obj, path) {
    var val = (0, _goodcityUtilsPolyfill.__get)(obj, path);
    return val && typeof val === "string" ? val : null;
  };

  exports["default"] = _ember["default"].Route.extend(_goodcityMixinsPreload_data["default"], {
    cordova: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    isErrPopUpAlreadyShown: false,
    isOfflineErrAlreadyShown: false,
    logger: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    isalreadyLoggedinShown: false,

    _loadDataStore: function _loadDataStore() {
      var _this2 = this;

      return this.preloadData(true)["catch"](function (error) {
        if (error.status === 0 || error.errors && error.errors[0].status === "0") {
          _this2.transitionTo("offline");
        } else {
          _this2.handleError(error);
        }
      })["finally"](function () {
        // don't know why but placing this before preloadData on iPhone 6 causes register_device request to fail with status 0
        if (_this2.session.get("isLoggedIn")) {
          _this2.get("cordova").appLoad();
        }
      });
    },

    isUnAuthenticatedPath: function isUnAuthenticatedPath(currentPath) {
      return currentPath.indexOf("login") >= 0 || currentPath.indexOf("authenticate") >= 0 || currentPath.indexOf("pics") >= 0 || currentPath.indexOf("terms_and_conditions") >= 0;
    },

    init: function init() {
      var _this = this;
      var storageHandler = function storageHandler(object) {
        var currentPath = window.location.href;
        var authToken = window.localStorage.getItem("authToken");
        if (!authToken && window.location.pathname !== "/" && window.location.pathname !== "/register" && !object.isUnAuthenticatedPath(currentPath)) {
          object.store.unloadAll("user_profile");
          object.session.clear();
          object.store.unloadAll();
          window.location.href = "/";
        } else if (object.get("isalreadyLoggedinShown") && authToken && !(currentPath.indexOf("offer") >= 0) && object.isUnAuthenticatedPath(currentPath)) {
          object.set("isalreadyLoggedinShown", true);
          object.get("messageBox").alert("Logged in from another window, press ok to refresh.", function () {
            window.location.reload();
          });
        }
      };
      window.addEventListener("storage", function () {
        storageHandler(_this);
      }, false);
    },

    checkSafariPrivateBrowser: function checkSafariPrivateBrowser() {
      var localStrg = window.localStorage;
      try {
        localStrg.test = "isSafariPrivateBrowser";
      } catch (e) {
        this.get("messageBox").alert(this.get("i18n").t("QuotaExceededError"));
      }
      if (localStrg) {
        localStrg.removeItem("test");
      }
    },

    beforeModel: function beforeModel() {
      var _this3 = this;

      var transition = arguments.length <= 0 || arguments[0] === undefined ? [] : arguments[0];

      var language;
      this.checkSafariPrivateBrowser();
      if (transition.queryParams.ln) {
        language = transition.queryParams.ln === "zh-tw" ? "zh-tw" : "en";
        this.set("session.language", language);
      }

      language = this.session.get("language") || "en";
      moment.locale(language);
      this.set("i18n.locale", language);
      _ember["default"].onerror = window.onerror = function (error) {
        return _this3.handleError(error);
      };
      return this._loadDataStore();
    },

    afterModel: function afterModel() {
      var _this4 = this;

      if (this.get("session.isAdminApp")) {
        this.loadStaticData(true)["catch"](function (error) {
          if (error.status === 0 || error.errors && error.errors[0].status === "0") {
            _this4.transitionTo("offline");
          } else {
            _this4.handleError(error);
          }
        });
      }
    },

    renderTemplate: function renderTemplate() {
      this.render(); // default template

      this.render("notifications", {
        // the template to render
        into: "application", // the template to render into
        outlet: "notifications", // the name of the outlet in that template
        controller: "notifications" // the controller to use for the template
      });

      if (this.get("session.isAdminApp")) {
        this.render("notification_link", {
          into: "application",
          outlet: "notification_link",
          controller: "notification_link"
        });

        this.render("internet_call_status", {
          into: "application",
          outlet: "internet_call_status",
          controller: "internet_call_status"
        });
      }
    },

    offlineError: function offlineError(reason) {
      var _this5 = this;

      if (!this.get("isOfflineErrAlreadyShown")) {
        this.set("isOfflineErrAlreadyShown", true);
        this.get("messageBox").alert(this.get("i18n").t("offline_error"), function () {
          _this5.set("isOfflineErrAlreadyShown", false);
        });
        if (!reason.isAdapterError) {
          this.get("logger").error(reason);
        }
      }
    },

    quotaExceededError: function quotaExceededError(reason) {
      this.get("logger").error(reason);
      this.get("messageBox").alert(this.get("i18n").t("QuotaExceededError"));
    },

    getErrorMessage: function getErrorMessage(reason) {
      var defaultMessage = this.get("i18n").t("unexpected_error");

      return getString(reason, "errors.0.detail.message") || getString(reason, "errors.0.message") || getString(reason, "errors.0.title") || getString(reason, "errors.0") || getString(reason, "message") || getString(reason, "error") || defaultMessage;
    },

    showErrorPopup: function showErrorPopup(reason) {
      var _this6 = this;

      this.get("logger").error(reason);
      if (!this.get("isErrPopUpAlreadyShown")) {
        this.set("isErrPopUpAlreadyShown", true);
        this.get("messageBox").alert(this.getErrorMessage(reason), function () {
          _this6.set("isErrPopUpAlreadyShown", false);
        });
      }
    },

    notFoundError: function notFoundError(reason, status) {
      this.get("logger").error(reason);
      this.get("messageBox").alert(this.get("i18n").t(status + "_error"));
    },

    accessDeniedError: function accessDeniedError(reason) {
      var _this7 = this;

      this.get("logger").error(reason);
      this.get("messageBox").alert(this.get("i18n").t("403_error"), function () {
        return _this7.controllerFor("application").send("logMeOut");
      });
    },

    unauthorizedError: function unauthorizedError() {
      if (this.session.get("isLoggedIn")) {
        this.controllerFor("application").send("logMeOut");
      }
    },

    handleError: function handleError(reason) {
      try {
        var status;
        try {
          status = parseInt(reason.errors[0].status, 10);
        } catch (err) {
          status = reason.status;
        }

        if (!window.navigator.onLine) {
          this.offlineError(reason);
        } else if (reason.name === "QuotaExceededError") {
          this.quotaExceededError(reason);
        } else if (status === 401) {
          this.unauthorizedError();
        } else if (status === 404) {
          this.notFoundError(reason, status);
        } else if (status === 403) {
          this.accessDeniedError(reason);
        } else if (status === 0) {
          // status 0 means request was aborted, this could be due to connection failure
          // but can also mean request was manually cancelled
          this.get("messageBox").alert(this.get("i18n").t("offline_error"));
        } else if (reason.name === "NotFoundError" && reason.code === 8) {
          return false;
        } else {
          this.showErrorPopup(reason);
        }
      } catch (err) {
        console.log(err);
      }
    },

    actions: {
      setLang: function setLang(language) {
        this.session.set("language", language);
        window.location.reload();
      },
      loading: function loading() {
        var _this8 = this;

        if (this._loadingView) {
          return;
        }
        this._loadingView = getOwner(this).lookup("component:loading").append();
        this.router.one("didTransition", function () {
          _this8._loadingView.destroy();
          _this8._loadingView = null;
        });
      },
      // this is hopefully only triggered from promises from routes
      // so in this scenario redirect to home for 404
      error: function error(reason) {
        var _this9 = this;

        try {
          var errorStatus = parseInt(reason.status || reason.errors && reason.errors[0].status, 10);
          if ([403, 404].indexOf(errorStatus) >= 0) {
            this.get("messageBox").alert(this.get("i18n").t(errorStatus + "_error"), function () {
              return _this9.transitionTo("/");
            });
          } else {
            this.handleError(reason);
          }
        } catch (err) {
          console.log(err);
        }
      },

      willTransition: function willTransition() {
        _ember["default"].run.next(function () {
          // before transitioning close all foundation-dialog box
          _ember["default"].$(".reveal-modal").foundation("reveal", "close");

          // remove joyride-popup if not assigned for page
          if ($(".joyride-list").length === 0) {
            _ember["default"].$(".joyride-tip-guide").remove();
          }
        });
      }
    }
  });
});