define('goodcity/controllers/delivery/thank_offer', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    delivery: _ember['default'].inject.controller(),
    offer: _ember['default'].inject.controller(),

    contact: _ember['default'].computed('model', {
      get: function get() {
        var deliveryId = this.get('delivery.model.id');
        return this.store.peekRecord('delivery', deliveryId).get("contact");
      },
      set: function set(key, value) {
        return value;
      }
    }),

    actions: {
      done: function done() {
        var offerId = this.get('offer.model.id');
        if (this.get("session.isAdminApp")) {
          this.transitionToRoute('review_offer.logistics', offerId);
        } else {
          this.transitionToRoute('offer.transport_details', offerId);
        }
      }
    }
  });
});