define("goodcity/routes/my_account", ["exports", "goodcity/routes/authorize"], function (exports, _goodcityRoutesAuthorize) {
  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    renderTemplate: function renderTemplate() {
      this.render(); // default template
      this.render("appMenuList", {
        into: "my_account",
        outlet: "appMenuList",
        controller: "offers"
      });
    },

    model: function model() {
      return Ember.RSVP.hash({
        user: this.store.peekRecord("user", this.session.get("currentUser.id"))
      });
    }
  });
});