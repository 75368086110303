define("goodcity/controllers/review_offer/share/index", ["exports", "ember", "goodcity/mixins/async_tasks", "goodcity/models/shareable", "goodcity/config/environment"], function (exports, _ember, _goodcityMixinsAsync_tasks, _goodcityModelsShareable, _goodcityConfigEnvironment) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports["default"] = _ember["default"].Controller.extend(_goodcityMixinsAsync_tasks["default"], {
    parent: _ember["default"].inject.controller("review_offer.share"),
    offer: _ember["default"].computed.alias("parent.offer"),
    offerShareable: _ember["default"].computed.alias("parent.offerShareable"),
    shareables: _ember["default"].computed.alias("parent.shareables"),
    offerService: _ember["default"].inject.service(),
    sharingService: _ember["default"].inject.service(),
    charitiesWebsiteURL: _goodcityConfigEnvironment["default"].BROWSE_APP_HOST_URL,

    allMessages: _ember["default"].computed(function () {
      return this.get("store").peekAll("message");
    }),

    messageThreads: _ember["default"].computed("offer.id", "offer.createdById", "allMessages.[]", "allMessages.length", "allMessages.@each.{senderId,recipientId}", function () {
      var _this = this;

      var offerResponse = this.get("store").peekAll("offerResponse").filterBy("offerId", this.get("offer.id"));

      return offerResponse.uniq().map(function (uid) {
        var messages = _this.get("allMessages").sortBy("createdAt").filter(function (m) {
          return m.get("messageableType") === "OfferResponse" && m.get("messageableId") === uid.id;
        });

        var lastMessage = messages.get("lastObject");

        return {
          userId: uid.get("userId"),
          user: _this.get("store").peekRecord("user", uid.get("userId")),
          lastMessage: lastMessage,
          unreadCount: messages.reduce(function (sum, m) {
            return sum + (m.get("isUnread") ? 1 : 0);
          }, 0),
          organisation: _this.organisationOf(uid.get("userId"))
        };
      });
    }),

    stopSharingAt: _ember["default"].computed("offerShareable.expiresAt", {
      get: function get() {
        return this.get("offerShareable.expiresAt");
      },
      set: function set(_, value) {
        return value;
      }
    }),

    isOfferShareableLinkAvailable: _ember["default"].computed("offerShareable", "stopSharingAt", "offer.id", function () {
      return this.get("store").peekAll("shareable").filterBy("offerId", this.get("offer.id")).shift();
    }),

    isShared: _ember["default"].computed("isOfferShareableLinkAvailable", "stopSharingAt", "offerShareable", "offer.id", function () {
      var _this2 = this;

      return this.get("store").peekAll("shareable").filter(function (sh) {
        return sh.get("resourceId") == _this2.get("offer.id") && sh.get("active");
      });
    }),

    allowListingEnabled: _ember["default"].computed({
      get: function get() {
        return this.get("offerShareable.allowListing");
      },
      set: function set(_, value) {
        return value;
      }
    }),

    hasSelectedPackages: _ember["default"].computed("packageList", "packageList.length", "packageList.@each.shared", function () {
      return this.getWithDefault("packageList", []).findBy("shared", true);
    }),

    allowSharing: _ember["default"].computed("packageList", "packageList.length", "packageList.@each.shared", "packageList.@each.package.notes.length", "stopSharingAt", function () {
      var packageList = this.getWithDefault("packageList", []);
      return packageList.findBy("shared", true) && packageList.filterBy("shared", true).rejectBy("package.notes").length === 0 && this.get("stopSharingAt");
    }),

    organisationOf: function organisationOf(userId) {
      var ou = this.get("store").peekAll("organisations_user").findBy("userId", userId);

      return ou && ou.get("organisation");
    },

    isPackageShared: function isPackageShared(pkg) {
      return Boolean(this.get("store").peekAll("shareable").filterBy("isPackage").findBy("resourceId", pkg.get("id")));
    },

    buildPackageList: function buildPackageList() {
      var _this3 = this;

      var offer = this.get("offer");
      var packages = this.get("offerService").packagesOf(offer);

      if (packages.length === 0) {
        this.raiseI18n("review_offer.cannot_share_offer_without_packages");
      }

      return packages.map(function (p) {
        return {
          "package": p,
          shared: _this3.isPackageShared(p)
        };
      });
    },

    defaultNotes: function defaultNotes(locale) {
      var _this4 = this;

      var currentLocale = this.get("i18n.locale");
      var saleable = this.get("offer.saleable");

      this.set("i18n.locale", locale);

      var t = function t(k) {
        return _this4.get("i18n").t(k);
      };
      var lines = [saleable ? t("review_offer.sale_allowed") : t("review_offer.sale_not_allowed"), t("review_offer.sharing_notes_default")];

      this.set("i18n.locale", currentLocale);

      return lines.join("\n");
    },

    persistPackageChanges: function persistPackageChanges() {
      return _ember["default"].RSVP.all(this.get("packageList").reduce(function (promises, row) {
        if (row["package"].changedAttributes().notes || row["package"].changedAttributes().notesZhTw) {
          promises.push(row["package"].save());
        }
        return promises;
      }, []));
    },

    persistOfferShareable: function persistOfferShareable(_ref) {
      var allowListing = _ref.allowListing;

      var sharing = this.get("sharingService");
      return sharing.share(_goodcityModelsShareable.SHAREABLE_TYPES.OFFER, this.get("offer.id"), {
        allowListing: allowListing,
        notes: this.get("notesEn"),
        notesZhTw: this.get("notesZh"),
        expiresAt: this.get("stopSharingAt")
      });
    },

    persistPackageShareables: function persistPackageShareables() {
      var sharing = this.get("sharingService");
      return _ember["default"].RSVP.all(this.get("packageList").reduce(function (promises, row) {
        return [].concat(_toConsumableArray(promises), [row.shared ? sharing.share(_goodcityModelsShareable.SHAREABLE_TYPES.PACKAGE, row["package"].get("id")) : sharing.unshare(_goodcityModelsShareable.SHAREABLE_TYPES.PACKAGE, row["package"].get("id"))]);
      }, []));
    },

    unshareAll: function unshareAll() {
      return regeneratorRuntime.async(function unshareAll$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.get("sharingService").unshareOffer());

          case 2:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    actions: {
      goToStockUserPage: function goToStockUserPage(userId) {
        var finalUrl = undefined;

        if (this.get("isMobileApp") && cordova.platformId === "android") {
          // jshint ignore:line
          finalUrl = "android-app://hk.goodcity.stockstaging/https/" + _goodcityConfigEnvironment["default"].APP.STOCK_ANDROID_APP_HOST_URL + "/users/" + userId;
          window.open(finalUrl, "_system");
        } else {
          finalUrl = _goodcityConfigEnvironment["default"].APP.STOCK_APP_HOST_URL + "/users/" + userId;
          window.open(finalUrl, "_blank");
        }
      },

      openChat: function openChat(recipientId) {
        this.replaceRoute("review_offer.share.chat", recipientId);
      },

      startEdit: function startEdit() {
        var _this5 = this;

        this.modalCatch(function () {
          var shareable = _this5.get("offerShareable");
          _this5.set("showZhNotes", false);
          _this5.set("notesEn", shareable ? shareable.get("notes") : _this5.defaultNotes("en"));
          _this5.set("notesZh", shareable ? shareable.get("notesZhTw") : _this5.defaultNotes("zh-tw"));
          _this5.set("packageList", _this5.buildPackageList());
          _this5.set("showEditor", true);
        });
      },

      cancelEdit: function cancelEdit() {
        this.get("offerService").packagesOf(this.get("offer")).forEach(function (p) {
          return p.rollbackAttributes();
        });
        this.set("packageList", []);
        this.set("showEditor", false);
      },

      applyChanges: function applyChanges() {
        var _this6 = this;

        var listed = this.get("allowListingEnabled");

        this.runTask(function callee$1$0() {
          return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
            while (1) switch (context$2$0.prev = context$2$0.next) {
              case 0:
                context$2$0.next = 2;
                return regeneratorRuntime.awrap(_ember["default"].RSVP.all([this.persistPackageChanges(), this.persistOfferShareable({ allowListing: listed }), this.persistPackageShareables()]));

              case 2:

                this.set("showEditor", false);

              case 3:
              case "end":
                return context$2$0.stop();
            }
          }, null, _this6);
        });
      },

      deleteSharing: function deleteSharing() {
        var _this7 = this;

        this.runTask(function callee$1$0() {
          return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
            while (1) switch (context$2$0.prev = context$2$0.next) {
              case 0:
                context$2$0.next = 2;
                return regeneratorRuntime.awrap(this.get("sharingService").unshareOffer(this.get("offer")));

              case 2:
              case "end":
                return context$2$0.stop();
            }
          }, null, _this7);
        });
        this.set("showEditor", false);
      },

      toggleSelectAllPackages: function toggleSelectAllPackages() {
        var pkgs = this.get("packageList");
        var on = Boolean(pkgs.findBy("shared", false));

        pkgs.forEach(function (p) {
          return _ember["default"].set(p, "shared", on);
        });
      }
    }
  });
});