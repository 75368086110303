define('goodcity/controllers/delivery/cancel_booking', ['exports', 'ember', 'goodcity/config/environment'], function (exports, _ember, _goodcityConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({

    transportController: _ember['default'].inject.controller('offer/transport_details'),

    canCancel: _ember['default'].computed.alias('model.gogovanOrder.isCancelled'),
    driverContact: _ember['default'].computed.alias('model.gogovanOrder.driverMobile'),
    gogovanContact: _goodcityConfigEnvironment['default'].APP.GOGOVAN_CONTACT,
    isCancel: true,

    actions: {
      cancelBooking: function cancelBooking() {
        if (this.get('canCancel')) {
          this.get('transportController').send('removeDelivery', this.get('model'));
        }
      }
    }
  });
});