define("goodcity/routes/offline", ["exports", "ember"], function (exports, _ember) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Route.extend({
    actions: {
      try_again: function try_again() {
        var currentUrl = getOwner(this).lookup("router:main").get("url");
        if (currentUrl === "/offline") {
          this.transitionTo("/");
        } else {
          window.location.reload();
        }
      }
    }
  });
});