define("goodcity/services/logger", ["exports", "ember", "goodcity/config/environment"], function (exports, _ember, _goodcityConfigEnvironment) {
  exports["default"] = _ember["default"].Service.extend({
    session: _ember["default"].inject.service(),
    rollbar: _ember["default"].inject.service(),

    notifyErrorCollector: function notifyErrorCollector(reason) {
      var currentUser = this.get("session.currentUser");
      var userName = currentUser ? currentUser.get("fullName") : "anonymous";
      var userId = currentUser ? currentUser.get("id") : "n/a";
      var error = reason instanceof Error || typeof reason !== "object" ? reason : JSON.stringify(reason);
      var environment = _goodcityConfigEnvironment["default"].environment;
      var version = _goodcityConfigEnvironment["default"].APP.VERSION;
      var appSHA = _goodcityConfigEnvironment["default"].APP.SHA;
      var appSharedSHA = _goodcityConfigEnvironment["default"].APP.SHARED_SHA;
      if (currentUser) {
        this.set("rollbar.currentUser", currentUser);
      }
      this.get("rollbar").error(error, {
        id: userId,
        username: userName,
        environment: environment,
        version: version,
        appSHA: appSHA,
        appSharedSHA: appSharedSHA
      });
    },

    error: function error(reason) {
      if (reason.status === 0) return;
      console.info(reason);
      this.notifyErrorCollector(reason);
    }
  });
});