define("goodcity/services/package-service", ["exports", "ember", "goodcity/services/api-base-service"], function (exports, _ember, _goodcityServicesApiBaseService) {

  var ID = function ID(record) {
    switch (typeof record) {
      case "string":
      case "number":
        return record;
      default:
        return record.get("id");
    }
  };

  exports["default"] = _goodcityServicesApiBaseService["default"].extend({
    store: _ember["default"].inject.service(),

    generateInventoryNumber: function generateInventoryNumber() {
      return this.POST("/inventory_numbers");
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.set("isPackageTypeOverlayVisible", false);
    },

    printBarcode: function printBarcode(pkgParams) {
      return this.POST("/packages/print_barcode", pkgParams);
    },

    removeInventoryNumber: function removeInventoryNumber(code) {
      return this.PUT("/inventory_numbers/remove_number", code);
    },

    markMissing: function markMissing(pkg) {
      var _this = this;

      var id = ID(pkg);

      return this.PUT("/packages/" + id + "/mark_missing").then(function (data) {
        _this.get("store").pushPayload(data);
        return _this.get("store").peekRecord("package", id);
      });
    },

    getItemValuation: function getItemValuation(_ref) {
      var donor_condition_id = _ref.donorConditionId;
      var package_type_id = _ref.packageTypeId;
      var grade = _ref.grade;

      return this.GET("/packages/package_valuation", {
        donor_condition_id: donor_condition_id,
        package_type_id: package_type_id,
        grade: grade
      });
    }
  });
});