define("goodcity/components/add-edit-message-overlay", ["exports", "ember", "lodash", "goodcity/mixins/async_tasks"], function (exports, _ember, _lodash, _goodcityMixinsAsync_tasks) {
  exports["default"] = _ember["default"].Component.extend(_goodcityMixinsAsync_tasks["default"], {
    messageService: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      var editMessage, store, message;
      return regeneratorRuntime.async(function didReceiveAttrs$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            editMessage = this.get("messageService.editMessage");
            store = this.get("store");
            message = store.peekRecord("canned_response", editMessage.messageId) || store.createRecord("canned-response");

            this.set("message", message);
            this.set("isEnglish", true);

          case 5:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    canDelete: _ember["default"].computed("message.id", function () {
      return this.get("message.id") && !this.get("message.isPrivate");
    }),

    disableCreateEdit: _ember["default"].computed("message.nameEn", "message.contentEn", function () {
      return !(this.get("message.nameEn") && this.get("message.contentEn"));
    }),

    actions: {
      createOrUpdateCannedMessage: function createOrUpdateCannedMessage() {
        var _this = this;

        this.runTask(this.get("message").save().then(function () {
          if (!_this.get("messageService.editMessage.messageId")) {
            _this.set("messageService.isAddMessageVisible", false);
          }
        }));
      },

      closeOverlay: function closeOverlay() {
        this.set("messageService.isAddMessageVisible", false);
        this.set("messageService.editMessage.language", "en");
        this.set("messageService.editMessage.messageId", "");
      },

      setLanguage: function setLanguage() {
        var lang = arguments.length <= 0 || arguments[0] === undefined ? "en" : arguments[0];

        this.set("isEnglish", lang == "en");
      },

      deleteMessage: function deleteMessage() {
        var _this2 = this;

        var store = this.get("store");
        var message = store.peekRecord("canned_response", this.get("message").id);
        this.runTask(message.destroyRecord().then(function () {
          store.unloadRecord(message);
          _this2.set("messageService.isAddMessageVisible", false);
        }), _goodcityMixinsAsync_tasks.ERROR_STRATEGIES);
      }
    }
  });
});