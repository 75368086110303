define("goodcity/controllers/dashboard/index", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    i18n: _ember["default"].inject.service(),
    filterService: _ember["default"].inject.service(),
    priority: true,
    selfReview: false,

    offersCount: _ember["default"].computed.alias("model.offersCount"),
    pageTitle: _ember["default"].computed(function () {
      return this.get("i18n").t("dashboard.title");
    })
  });
});