define("goodcity/routes/review_item/reject", ["exports", "goodcity/routes/authorize"], function (exports, _goodcityRoutesAuthorize) {
  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    model: function model() {
      var itemId = this.modelFor("review_item").get("id");
      return this.store.peekRecord("item", itemId);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (!model.get("isRejected")) {
        controller.set("selectedId", undefined);
        controller.set("rejectReason", undefined);
        controller.set("rejectMsg", "");
      }
    }
  });
});