define("goodcity/services/image-service", ["exports", "goodcity/services/api-base-service", "lodash"], function (exports, _goodcityServicesApiBaseService, _lodash) {
  exports["default"] = _goodcityServicesApiBaseService["default"].extend({
    createImage: function createImage(imageableRecord, cloudinaryId) {
      var _this = this;

      var params = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      var modelName = imageableRecord.get("constructor.modelName");

      return this.POST("/images", {
        image: {
          imageable_type: _lodash["default"].upperFirst(_lodash["default"].camelCase(modelName)),
          imageable_id: imageableRecord.get("id"),
          cloudinary_id: cloudinaryId,
          favourite: _lodash["default"].get(params, "favourite", false)
        }
      }, { persist: true }).then(function (data) {
        var id = Number(data["image"]["id"]);
        var img = _this.get("store").peekRecord("image", id);

        imageableRecord.getWithDefault("images", []).pushObject(img);

        return img;
      });
    }
  });
});