define("goodcity/controllers/review_offer/share/chat", ["exports", "goodcity/controllers/message_base_controller"], function (exports, _goodcityControllersMessage_base_controller) {
  exports["default"] = _goodcityControllersMessage_base_controller["default"].extend({
    displayCannedMessages: true,

    offerResponse: Ember.computed("allMessages.[]", "allMessages.@each.recipientId", "recipientId", function () {
      var _this = this;

      return this.store.peekAll("offerResponse").filter(function (m) {
        return m.get("userId") == _this.get("recipientId") && m.get("offerId") == _this.get("offer.id");
      });
    }),

    messages: Ember.computed("allMessages.[]", "allMessages.@each.recipientId", "offerResponse", "recipientId", function () {
      var offerResponse = this.get("offerResponse");
      return this.get("allMessages").filterBy("messageableId", offerResponse.get("firstObject").id);
    }),
    actions: {
      leave: function leave() {
        this.replaceRoute("review_offer.share");
      },
      sendMessage: function sendMessage() {
        var _this2 = this;

        // To hide soft keyboard
        Ember.$("textarea").trigger("blur");
        var offerResponse = this.get("offerResponse");
        this.set("inProgress", true);
        var values = this.getProperties("body", "isPrivate");
        values.messageableId = offerResponse.get("firstObject").id;
        values.messageableType = "OfferResponse";
        values.createdAt = new Date();
        values.sender = this.store.peekRecord("user", this.get("session.currentUser.id"));

        values.recipientId = this.get("recipientId");

        var message = this.store.createRecord("message", values);
        message.save().then(function () {
          _this2.set("body", "");
        })["catch"](function (error) {
          _this2.store.unloadRecord(message);
          throw error;
        })["finally"](function () {
          return _this2.set("inProgress", false);
        });

        Ember.$("body").animate({ scrollTop: Ember.$(document).height() }, 1000);
      }
    }
  });
});