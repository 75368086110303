define("goodcity/services/session", ["exports", "ember", "goodcity/computed/local-storage", "goodcity/config/environment"], function (exports, _ember, _goodcityComputedLocalStorage, _goodcityConfigEnvironment) {
  exports["default"] = _ember["default"].Service.extend({
    authToken: _ember["default"].computed.localStorage(),
    otpAuthKey: _ember["default"].computed.localStorage(),
    isLoggedIn: _ember["default"].computed.notEmpty("authToken"),
    language: _ember["default"].computed.localStorage(),
    seenTour: _ember["default"].computed.localStorage(),
    store: _ember["default"].inject.service(),
    backLinkPath: _ember["default"].computed.localStorage(),

    currentUser: _ember["default"].computed(function () {
      var store = this.get("store");
      return store.peekAll("user_profile").get("firstObject") || null;
    }).volatile(),

    isAdminApp: _ember["default"].computed(function () {
      return _goodcityConfigEnvironment["default"].APP.NAME === "admin.goodcity";
    }),

    loggedInUser: _ember["default"].computed(function () {
      return this.get("store").peekRecord("user", this.get("currentUser.id"));
    }),

    isDonorApp: _ember["default"].computed("isAdminApp", function () {
      return this.get("isAdminApp") === false;
    }),

    clear: function clear() {
      this.set("authToken", null);
      this.set("otpAuthKey", null);
    }
  });
});