define('goodcity/components/init-foundation', ['exports', 'ember', 'goodcity/config/environment'], function (exports, _ember, _goodcityConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({

    foundation: null,

    currentClassName: _ember['default'].computed("className", function () {
      return this.get("className") ? '.' + this.get('className') : document;
    }),

    click: function click() {
      _ember['default'].run.later(function () {
        if ($('.off-canvas-wrap.move-right')[0]) {
          _goodcityConfigEnvironment['default'].cordova.enabled ? $('body').css({ 'position': 'fixed', 'width': '100%' }) : $('body').css('overflow', 'hidden');
        } else {
          _goodcityConfigEnvironment['default'].cordova.enabled ? $('body').css({ 'position': 'inherit', 'width': 'inherit' }) : $('body').css('overflow', 'auto');
        }
      }, 100);
    },

    didInsertElement: function didInsertElement() {
      var className = this.get("currentClassName");
      var _this = this;

      this._super();

      _ember['default'].run.debounce(this, function () {
        var clientHeight = $(window).height();
        $('.inner-wrap').css('min-height', clientHeight);
      }, 1000);

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var initFoundation = _ember['default'].$(className).foundation({
          offcanvas: { close_on_click: true }
        });
        _this.set("foundation", initFoundation);
      });
    }

    // TODO: Breaks sometime on menu-bar
    // willDestroyElement() {
    //   this.get("foundation").foundation("destroy");
    // }

  });
});