define("goodcity/components/package-select-list", ["exports", "ember", "goodcity/components/select-list"], function (exports, _ember, _goodcityComponentsSelectList) {
  exports["default"] = _goodcityComponentsSelectList["default"].extend({
    layoutName: "components/select-list",
    content: null,
    selectedValue: null,
    pkg: null,
    index: null,
    didUpdatedOnce: false,

    didRender: function didRender() {
      this._super.apply(this, arguments);
      if (!this.get("didUpdatedOnce") && this.get("pkg") !== null && this.get("pkg.item.packageType") !== null) {
        var packageType = this.get("pkg.packageType") || this.get("pkg.item.packageType");
        if (this.isPackageTypeChanged(packageType)) {
          this.set("pkg.notes", packageType.get("name"));
        }
        this.set("pkg.packageType", packageType);
        _ember["default"].$("textarea#" + this.get("index")).val(this.get("pkg.notes"));
        this.set("didUpdatedOnce", false);
      }
    },

    isPackageTypeChanged: function isPackageTypeChanged(packageType) {
      return this.get("pkg.notes") === packageType.get("name") || this.get("pkg.notes").trim().length === 0 || this.get("pkg.packageType.name") !== packageType.get("name");
    },

    didUpdate: function didUpdate() {
      this._super.apply(this, arguments);
      this.set("pkg.packageType", this.get("pkg.packageType"));
      this.set("didUpdatedOnce", true);
    },

    actions: {
      change: function change() {
        var changeAction = this.get("on-change");
        var selectedIndex = this.$("select").prop("selectedIndex");
        var content = this.get("content").toArray();
        if (this.get("prompt")) {
          content = [{ name: null }].concat(content);
        }
        var selectedValue = content[selectedIndex];
        if (this.index !== null && selectedValue.name !== null) {
          selectedValue.set("indexOfChild", this.get("index"));
          var availablePkg = this.get("pkg");
          var name = selectedValue.get("name");
          _ember["default"].$("textarea#" + this.get("index")).val(name);
          selectedValue.set("indexOfChild", availablePkg.id);
        }
        this.set("selectedValue", selectedValue);
        changeAction(selectedValue);
      }
    }
  });
});