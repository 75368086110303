define('goodcity/models/gogovan_order', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

  var attr = _emberData['default'].attr,
      belongsTo = _emberData['default'].belongsTo;

  exports['default'] = _emberData['default'].Model.extend({
    name: attr('string'),
    mobile: attr('string'),

    bookingId: attr('number'),
    status: attr('string'),
    pickupTime: attr('date'),
    slot: attr('string'),
    districtId: attr('number'),
    territoryId: attr('number'),
    offerId: attr('number'),
    gogovanOptionId: attr('number'),
    completedAt: attr('date'),

    needEnglish: attr('boolean'),
    needCart: attr('boolean'),
    needCarry: attr('boolean'),
    needOver6ft: attr('boolean'),
    removeNet: attr('string'),

    baseFee: attr('string'),
    totalFee: attr('string'),
    needEnglishFee: attr('string'),
    needCartFee: attr('string'),
    removeNetFee: attr('string'),

    price: attr('number'),
    driverName: attr('string'),
    driverMobile: attr('string'),
    driverLicense: attr('string'),
    ggvUuid: attr('string'),
    delivery: belongsTo('delivery', { async: false }),
    isDiscountAvailable: false,
    couponDiscount: 0,

    i18n: _ember['default'].inject.service(),

    isPending: _ember['default'].computed.equal("status", "pending"),
    isActive: _ember['default'].computed.equal("status", "active"),
    isCompleted: _ember['default'].computed.equal("status", "completed"),
    isCancelled: _ember['default'].computed.equal("status", "cancelled"),
    isPickedUp: _ember['default'].computed.or("isActive", "isCompleted"),
    nonCompleted: _ember['default'].computed.or("isActive", "isPending"),

    ggvOrderStatus: _ember['default'].computed("isActive", "isCompleted", function () {
      if (this.get("isActive")) {
        return this.get("i18n").t("offer.offer_details.is_gogovan_confirm").string;
      } else if (this.get("isCompleted")) {
        return this.get("i18n").t("offer.offer_details.driver_completed").string;
      } else {
        return this.get("i18n").t("offer.offer_details.is_gogovan_order").string;
      }
    })
  });
});