define("goodcity/controllers/my_notifications", ["exports", "ember", "lodash", "goodcity/utils/ajax-promise"], function (exports, _ember, _lodash, _goodcityUtilsAjaxPromise) {
  var computed = _ember["default"].computed;
  exports["default"] = _ember["default"].Controller.extend({
    messagesUtil: _ember["default"].inject.service("messages"),
    subscriptions: _ember["default"].inject.controller(),
    store: _ember["default"].inject.service(),
    logger: _ember["default"].inject.service(),

    hasLoadedReadMessages: false,
    displayMessages: true,
    showUnread: true,
    notifications: [],

    on: function on() {
      // When a new message arrives, we move it to the top
      this.get("subscriptions").on("create:message", this, this.onNewNotification);
      this.get("subscriptions").on("update:message", this, this.onUpdateNotification);
    },

    off: function off() {
      this.get("subscriptions").off("create:message", this, this.onNewNotification);
      this.get("subscriptions").off("update:message", this, this.onUpdateNotification);
    },

    onUpdateNotification: function onUpdateNotification(_ref) {
      var id = _ref.id;

      var store = this.get("store");
      var msg = store.peekRecord("message", id);
      var notif = this.get("notifications").findBy("key", this.buildMessageKey(msg));

      if (notif) {
        notif.set("unreadCount", null);
      }
    },

    onNewNotification: function onNewNotification(_ref2) {
      var id = _ref2.id;

      var store = this.get("store");
      var msg = store.peekRecord("message", id);
      var messageableId = msg.get("messageableId");
      var notifications = this.get("notifications");

      if (!messageableId) {
        return;
      }

      var notif = notifications.findBy("key", this.buildMessageKey(msg));
      var isMessageByCurrentUser = this.get("session.currentUser.id") === msg.data["senderId"];

      if (notif) {
        // Update existing one
        notifications.removeObject(notif);
        if (!isMessageByCurrentUser) {
          msg.set("unreadCount", +notif.get("unreadCount") + 1);
        }
        notif.get("messages").addObject(msg);
      } else {
        // Create new one
        if (!isMessageByCurrentUser) {
          msg.set("unreadCount", 1);
        }
        notif = this.messagesToNotification([msg]);
      }

      notifications.insertAt(0, notif);
    },

    loadIfAbsent: function loadIfAbsent(modelName, id) {
      var store = this.get("store");
      var cachedRecord = store.peekRecord(modelName, id);
      if (cachedRecord) {
        return cachedRecord;
      }

      return store.findRecord(modelName, id);
    },

    /**
     * Creates a single notification out of multiple messages
     *
     * @param {*} messages
     * @returns
     */
    messagesToNotification: function messagesToNotification(messages) {
      var _this = this;

      var props = ["id", "itemId", "offerId", "sender", "createdAt", "isPrivate"];

      var item = undefined,
          offer = undefined,
          offerResponse = undefined;
      var lastMessage = messages.sortBy("id").get("lastObject");
      var messageableType = _lodash["default"].camelCase(lastMessage.get("messageableType"));
      var recordId = lastMessage.get("messageableId");
      var response = this.loadIfAbsent(messageableType, recordId);

      if (messageableType === "offerResponse") {
        offerResponse = response;

        _ember["default"].run.later(function () {
          var presentOffer = _this.get("store").peekRecord("offer", offerResponse.get("offerId"));

          if (presentOffer) {
            if (!presentOffer.get("createdById")) {
              _this.get("store").unloadRecord(presentOffer); // Removing offer recieved in serializers of offer Response
              notification.offerResponse.offer = _this.get("store").findRecord("offer", offerResponse.get("offerId"));
            } else {
              notification.offerResponse.offer = presentOffer;
            }
          }
        }, 2000);
      }

      if (messageableType === "offer") {
        offer = response;
      }

      if (messageableType === "item") {
        item = response;
      }

      var notification = _ember["default"].Object.create(lastMessage.getProperties(props));
      notification.setProperties({
        key: this.buildMessageKey(lastMessage),
        item: item,
        messages: messages,
        offerResponse: offerResponse,
        isSingleMessage: computed.equal("unreadCount", 1),
        isThread: computed.not("isSingleMessage"),
        offer: offer,
        recipientId: this.getRecipientId(lastMessage),
        text: computed("messages.[]", function () {
          return this.get("messages").sortBy("id").get("lastObject.parsedBody");
        }),
        unreadCount: computed("messages.@each.unreadCount", "messages.[]", {
          get: function get() {
            var lastMessage = this.get("messages").sortBy("id").get("lastObject");
            return lastMessage.get("unreadCount");
          },
          set: function set(key, value) {
            return value;
          }
        })
      });
      return notification;
    },

    /**
     * Transform offers into "notifications" object with more UI-friendly properties
     */
    buildNotifications: function buildNotifications(messages) {
      var _this2 = this;

      var groupedMessages = messages.filter(function (msg) {
        return _this2.get("showUnread") ? msg.get("isUnread") : true;
      });

      return _lodash["default"].chain(groupedMessages).groupBy(function (m) {
        return _this2.buildMessageKey(m);
      }).map(function (msgs) {
        return _this2.messagesToNotification(msgs);
      }).value();
    },

    /**
     * Injects API JSON into the store and returns a list of models
     *
     * @param {Object} data
     * @returns {Message[]}
     */
    toMessageModels: function toMessageModels(data) {
      var _this3 = this;

      this.get("store").pushPayload(data);
      return data.messages.map(function (_ref3) {
        var id = _ref3.id;

        return _this3.get("store").peekRecord("message", id);
      });
    },

    buildMessageKey: function buildMessageKey(msg) {
      return [msg.get("isPrivate") ? "private" : "public", msg.get("messageableType") || "-", msg.get("messageableId") || "-", this.getRecipientId(msg) || "-"].join("/");
    },

    getRecipientId: function getRecipientId(msg) {
      if (msg.get("isPrivate")) {
        return null;
      }

      if (msg.get("recipientId")) {
        // Case: staff is sending the message
        return msg.get("recipientId");
      }

      // Case: user is sending the message
      return msg.get("senderId");
    },

    actions: {
      /**
       * Loads a page of Message Notifications
       * Used by the infinite list
       *
       * @param {*} pageNo
       * @returns
       */
      loadMoreMessages: function loadMoreMessages(pageNo) {
        var _this4 = this;

        var state = this.get("showUnread") ? "unread" : "";

        var params = {
          page: pageNo,
          state: state,
          messageable_type: ["Offer", "Item", "OfferResponse"]
        };

        return new _goodcityUtilsAjaxPromise["default"]("/messages/notifications", "GET", this.get("session.authToken"), params).then(function (data) {
          return _this4.toMessageModels(data);
        }).then(function (messages) {
          var notifications = _lodash["default"].chain(messages).groupBy(function (m) {
            return _this4.buildMessageKey(m);
          }).map(function (o) {
            return _this4.buildNotifications(o);
          }).flatten().value();

          _this4.get("notifications").addObjects(notifications);
          return notifications;
        });
      },

      view: function view(notification) {
        var messageId = notification.get("id");
        var message = this.store.peekRecord("message", messageId);

        var route = this.get("messagesUtil").getRoute(message);

        if (!route) {
          return;
        }

        if (message.get("messageableType") === "Item") {
          route[1] = message.get("item.offer.id");
        } else if (message.get("messageableType") === "OfferResponse") {
          route[1] = notification.offerResponse.get("offerId");
        }
        this.transitionToRoute.apply(this, route);
      },

      markThreadRead: function markThreadRead(notification) {
        if (notification.unreadCount === 1) {
          var message = this.store.peekRecord("message", notification.id);
          this.get("messagesUtil").markRead(message);
          notification.set("unreadCount", 0);
        } else {
          this.send("view", notification);
        }
      },

      toggleShowUnread: function toggleShowUnread() {
        this.set("displayMessages", false);
        this.get("notifications").clear();
        _ember["default"].run.later(this, function () {
          var showUnread = !this.get("showUnread");
          this.set("showUnread", showUnread);
          this.set("displayMessages", true);
        });
      },

      markAllRead: function markAllRead() {
        var _this5 = this;

        this.get("messagesUtil").markAllRead().then(function () {
          _this5.get("notifications").forEach(function (n) {
            n.set("unreadCount", 0);
          });
        })["catch"](function (e) {
          _this5.get("logger").error(e);
        });
      }
    }
  });
});