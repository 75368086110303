define("goodcity/components/focus-textfield", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].TextField.extend({
    tagName: "input",
    type: "text",
    attributeBindings: ["name", "id", "value", 'placeholder'],
    cordova: _ember["default"].inject.service(),

    iosItemTypeSearchPage: _ember["default"].computed(function () {
      return this.get("cordova").isIOS() && _ember["default"].$(".fixed_item_type_search").length > 0;
    }),

    scrollToStart: function scrollToStart() {
      _ember["default"].$(".fixed_item_type_search").css({ "position": "absolute" });
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    focusOut: function focusOut() {
      if (this.get("iosItemTypeSearchPage")) {
        _ember["default"].$(".fixed_item_type_search").css({ "position": "fixed" });
      }
    },

    didInsertElement: function didInsertElement() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.$().focus();
      if (this.get("iosItemTypeSearchPage")) {
        this.element.addEventListener('touchstart', this.scrollToStart);
      }
    },

    willDestroyElement: function willDestroyElement() {
      if (this.get("iosItemTypeSearchPage")) {
        this.element.addEventListener('touchstart', this.scrollToStart);
      }
    }

  });
});