define("goodcity/components/canned-messages-overlay", ["exports", "ember", "lodash"], function (exports, _ember, _lodash) {
  exports["default"] = _ember["default"].Component.extend({
    messageService: _ember["default"].inject.service(),
    displayResults: true,
    store: _ember["default"].inject.service(),
    onSearchTextChange: _ember["default"].observer("searchText", function () {
      _ember["default"].run.debounce(this, this.reloadResults, 500);
    }),

    reloadResults: function reloadResults() {
      var _this = this;

      this.set("displayResults", false);
      _ember["default"].run.debounce(this, function () {
        return _this.set("displayResults", true);
      }, 500);
    },

    hasSearchText: _ember["default"].computed("searchText", function () {
      return this.get("searchText") && this.get("searchText").trim().length;
    }),

    refreshDisplayResult: _ember["default"].observer("messageService.isAddMessageVisible", function () {
      if (!this.get("messageService.isAddMessageVisible")) {
        this.reloadResults();
      }
    }),

    actions: {
      loadMoreCannedMessages: function loadMoreCannedMessages() {
        var params = { searchText: this.get("searchText") };
        return this.get("store").query("canned_response", params);
      },

      openProFormaOverlay: function openProFormaOverlay() {
        this.set("messageService.isProFormaMessageVisible", true);
      },

      setCannedResponse: function setCannedResponse(text) {
        if (!text) return;
        var onSelect = this.getWithDefault("onSelect", _lodash["default"].noop);
        onSelect(text);
        this.set("open", false);
      },

      clearSearch: function clearSearch() {
        this.set("searchText", "");
      },

      cancel: function cancel() {
        this.set("open", false);
      }
    }
  });
});