define("goodcity/utils/polyfill", ["exports"], function (exports) {
  /**
   * A polyfill for lodash.get
   *
   * @param {Object} obj
   * @param {String} path
   * @param {String} defaultValue
   */
  var __get = function __get(obj, path, defaultValue) {
    if (typeof path !== "string") {
      throw Error("Invalid argument passed");
    }
    var _path = path.split(".").filter(function (key) {
      return key.length;
    });
    var value = _path.reduce(function (acc, key) {
      return acc && acc[key];
    }, obj);
    return value || defaultValue;
  };
  exports.__get = __get;
});