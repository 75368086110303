define("goodcity/models/package_type", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {

  var attr = _emberData["default"].attr,
      hasMany = _emberData["default"].hasMany,
      belongsTo = _emberData["default"].belongsTo;

  exports["default"] = _emberData["default"].Model.extend({
    name: attr("string"),
    otherTerms: attr("string"),
    code: attr("string"),
    isItemTypeNode: attr("boolean", { defaultValue: false }),
    visibleInSelects: attr("boolean", { defaultValue: false }),
    defaultChildPackages: attr("string"),
    otherChildPackages: attr("string"),

    location: belongsTo("location", { async: false }),
    packages: hasMany("package", { inverse: "packageType" }),
    packagesCount: _ember["default"].computed.alias("packages.length"),

    defaultChildPackagesList: function defaultChildPackagesList() {
      return this._getPackages(this, this.get("defaultChildPackages"));
    },

    otherChildPackagesList: function otherChildPackagesList() {
      return this._getPackages(this, this.get("otherChildPackages"));
    },

    allChildPackagesList: function allChildPackagesList() {
      return this.defaultChildPackagesList().concat(this.otherChildPackagesList());
    },

    _getPackages: function _getPackages(model, packageNames) {
      var array = (packageNames || "").split(",").filter(Boolean);
      var packages = [];
      var allPackageTypes = model.store.peekAll("packageType");
      array.forEach(function (type) {
        allPackageTypes.filter(function (pkg) {
          return pkg.get("code") === type ? packages.push(pkg) : "";
        });
      });
      return packages;
    }
  });
});