define("goodcity/routes/review_offer/donor_details", ["exports", "goodcity/routes/authorize", "ember"], function (exports, _goodcityRoutesAuthorize, _ember) {
  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    currentDonor: null,
    currentOffer: null,

    model: function model() {
      var _this = this;

      var offerPreload = this.modelFor("reviewOffer");
      return _ember["default"].RSVP.resolve(offerPreload).then(function (currentOffer) {
        if (!currentOffer) {
          return;
        }
        var donor = currentOffer.get("donor");
        _this.set("currentDonor", donor);
        _this.set("currentOffer", currentOffer);

        if (donor) {
          return _this.store.query("offer", {
            created_by_id: donor.get("id"),
            states: ["donor_non_draft"],
            summarize: "true"
          }, {
            adapterOptions: { reload: true }
          });
        }
        return _ember["default"].A([]);
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("donor", this.get("currentDonor"));
      controller.set("currentOffer", this.get("currentOffer"));
      ["displayCompanyOptions", "displayAltPhoneOptions", "displayDonorMobileOptions", "displayDonorOptions"].forEach(function (item) {
        controller.set(item, false);
      });
    },

    afterModel: function afterModel(model) {
      if (!model) {
        this.transitionTo("dashboard");
      }
    }
  });
});