define("goodcity/components/holiday-calender", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].TextField.extend({
    tagName: "input",
    classNames: "pickadate",
    attributeBindings: ["name", "type", "value", "id", "required", "pattern", "placeholder", "allHolidays"],

    _getValidDate: function _getValidDate(selectedDate) {
      var today = new Date();
      var currentDate = new Date();
      var selected = selectedDate;
      currentDate.setHours(0, 0, 0, 0);
      selected.setHours(0, 0, 0, 0);
      return currentDate > selected ? today : selectedDate;
    },

    isDateEqual: function isDateEqual(date) {
      var selected = this.get("selection");
      selected.setHours(0, 0, 0, 0);
      date.setHours(0, 0, 0, 0);
      return selected.getTime() === date.getTime();
    },

    didInsertElement: function didInsertElement() {
      var _this = this;
      var setting = false;

      _ember["default"].run.scheduleOnce("afterRender", this, function () {
        _ember["default"].$(".pickadate").pickadate({
          format: "ddd mmm d",
          monthsFull: moment.months(),
          monthsShort: moment.monthsShort(),
          weekdaysShort: moment.weekdaysShort(),
          disable: [1, 2],
          clear: false,
          today: false,
          close: false,
          min: moment().toDate(),

          onClose: function onClose() {
            var _this2 = this;

            _ember["default"].$(document.activeElement).blur();
            if (setting) {
              return;
            }
            var date = this.get("select") && this.get("select").obj;

            if (date) {
              _this.set("selection", date);
              setting = true;
              _ember["default"].run.next(function () {
                _this2.set("select", new Date(date), { format: "ddd mmm d" });
                setting = false;
              });
            } else {
              _this.set("selection", "");
            }
          },

          onStart: function onStart() {
            var date = _this.get("selection");
            if (date) {
              date = _this._getValidDate(date);
              this.set("select", new Date(date), { format: "ddd mmm d" });
            }
          },

          onOpen: function onOpen() {
            var list = _this.get("allHolidays");
            var holidays_array = [1, 2];
            var selected = _this.get("selection").toString().length;

            if (list) {
              var holidays_count = list.length;
              for (var i = holidays_count - 1; i >= 0; i--) {
                var date = new Date(list[i].get("holiday"));

                if (selected === 0 || !_this.isDateEqual(date)) {
                  var date_array = [];
                  date_array.push(date.getFullYear());
                  date_array.push(date.getMonth());
                  date_array.push(date.getDate());
                  holidays_array.push(date_array);
                }
              }
            }

            this.set("disable", holidays_array);
          }
        });

        closeOnClick();
      });

      function closeOnClick() {
        _ember["default"].$(".picker__holder").click(function (e) {
          if (e.target !== this) {
            return;
          }
          _ember["default"].$("[id$=selectedDate]").trigger("blur");
        });
      }
    }
  });
});