define("goodcity/controllers/companies/edit", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    messageBox: _ember["default"].inject.service(),

    isInvalidCompanyName: _ember["default"].computed("model.company.name", function () {
      return this.get("model.company.name").trim().length === 0;
    }),

    actions: {
      back: function back() {
        this.get("model.company").rollbackAttributes();
        this.transitionToRoute("review_offer.donor_details", this.get("model.id"));
      },

      updateCompany: function updateCompany() {
        var _this = this;

        if (this.get("isInvalidCompanyName")) {
          return;
        }

        var company = this.get("model.company");
        var offer = this.get("model");
        company.set("name", this.get("model.company.name"));
        company.set("crmId", this.get("model.company.crmId"));
        company.set("updatedById", this.get("session.currentUser.id"));

        company.save().then(function () {
          _this.transitionToRoute("review_offer.donor_details", offer.get("id"));
        })["catch"](function (_ref) {
          var errors = _ref.errors;

          throw errors[0]["detail"];
        });
      }
    }
  });
});