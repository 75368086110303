define('goodcity/models/user_profile', ['exports', 'ember', 'ember-data', 'goodcity/models/addressable'], function (exports, _ember, _emberData, _goodcityModelsAddressable) {

  var attr = _emberData['default'].attr;

  exports['default'] = _goodcityModelsAddressable['default'].extend({
    firstName: attr('string'),
    lastName: attr('string'),
    mobile: attr('string'),
    donationAmount: attr('string'),
    donationDate: attr('date'),

    userRoles: _emberData['default'].hasMany('userRoles', { async: false }),

    roles: _ember['default'].computed('userRoles.[]', function () {
      var roles = [];
      if (this.get('userRoles.length')) {
        this.get('userRoles').forEach(function (userRole) {
          roles.push(userRole.get('role'));
        });
        return roles;
      }
    }),

    roleNames: _ember['default'].computed('roles', function () {
      if (this.get('roles.length')) {
        return this.get('roles').getEach('name');
      }
    }),

    permissionNames: _ember['default'].computed('roles', function () {
      var permissionNames = [];
      if (this.get('roles.length')) {
        this.get('roles').forEach(function (role) {
          role.get('rolePermissions').forEach(function (rolePermision) {
            permissionNames.push(rolePermision.get('permission.name'));
          });
        });
        return permissionNames;
      }
    }),

    isReviewer: _ember['default'].computed('roleNames', function () {
      if (this.get('roleNames.length')) {
        return this.get('roleNames').indexOf('Reviewer') >= 0;
      }
    }),

    isSupervisor: _ember['default'].computed('roleNames', function () {
      if (this.get('roleNames.length')) {
        return this.get('roleNames').indexOf('Supervisor') >= 0;
      }
    }),

    canManageUsers: _ember['default'].computed('permissionNames', function () {
      if (this.get('permissionNames.length')) {
        return this.get('permissionNames').indexOf('can_manage_users') >= 0;
      }
    }),

    canManageHolidays: _ember['default'].computed('permissionNames', function () {
      if (this.get('permissionNames.length')) {
        return this.get('permissionNames').indexOf('can_manage_holidays') >= 0;
      }
    }),

    isDonor: _ember['default'].computed.empty('roleNames'),
    // isDonor: Ember.computed.empty("permission.name"),
    // isStaff: Ember.computed.notEmpty("permission.name"),
    // isReviewer: Ember.computed.equal("permission.name", "Reviewer"),
    // isSupervisor: Ember.computed.equal("permission.name", "Supervisor"),

    mobileWithCountryCode: _ember['default'].computed('mobile', function () {
      return this.get('mobile') ? "+852" + this.get('mobile') : "";
    }),

    fullName: _ember['default'].computed('firstName', 'lastName', function () {
      return this.get('firstName') + " " + this.get('lastName');
    })
  });
});