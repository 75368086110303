define('goodcity/routes/delivery/verify_offer_state', ['exports', 'goodcity/routes/authorize'], function (exports, _goodcityRoutesAuthorize) {
  exports['default'] = _goodcityRoutesAuthorize['default'].extend({

    backClick: false,

    queryParams: {
      placeOrder: false,
      backClick: false
    },

    beforeModel: function beforeModel(params) {
      var offerId = this.modelFor('offer').get('id');
      var offer = this.store.peekRecord('offer', offerId);
      this.set("backClick", params.queryParams.backClick);

      if (offer.get('isScheduled') && !params.queryParams.placeOrder) {
        if (this.get('session.isAdminApp')) {
          this.transitionTo('review_offer.logistics', offer);
        } else {
          this.transitionTo('offer.transport_details', offer);
        }
      }
    }
  });
});