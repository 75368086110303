define("goodcity/controllers/receive_package", ["exports", "ember", "lodash", "goodcity/utils/ajax-promise", "goodcity/mixins/async_tasks"], function (exports, _ember, _lodash, _goodcityUtilsAjaxPromise, _goodcityMixinsAsync_tasks) {
  exports["default"] = _ember["default"].Controller.extend(_goodcityMixinsAsync_tasks["default"], {
    // ----- Services -----
    messageBox: _ember["default"].inject.service(),
    cordova: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    packageService: _ember["default"].inject.service(),
    printerService: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),

    // ----- Arguments -----
    queryParams: ["isUnplannedPackage"],
    isUnplannedPackage: false,
    inputInventory: false,
    autoGenerateInventory: true,
    displayInventoryOptions: false,
    isAllowedToPublish: false,
    watchErrors: true,
    isAndroidDevice: false,
    displayError: false,
    // ----- Aliases -----
    "package": _ember["default"].computed.alias("model"),

    item: _ember["default"].computed.alias("model.item"),
    description: _ember["default"].computed.alias("package.notes"),
    reviewOfferController: _ember["default"].inject.controller("review_offer"),
    selectedCondition: _ember["default"].computed.alias("model.donorCondition"),

    // ----- Computed Properties -----
    donorConditions: _ember["default"].computed(function () {
      return this.get("store").peekAll("donor_condition").sortBy("id");
    }),

    grades: _ember["default"].computed(function () {
      var i18n = this.get("i18n");
      return [{ name: i18n.t("receive_package.grade_a"), id: "A" }, { name: i18n.t("receive_package.grade_b"), id: "B" }, { name: i18n.t("receive_package.grade_c"), id: "C" }, { name: i18n.t("receive_package.grade_d"), id: "D" }];
    }),

    allAvailablePrinter: _ember["default"].computed(function () {
      return this.get("printerService").allAvailablePrinters();
    }),

    selectedPrinterDisplay: _ember["default"].computed("selectedPrinterId", function () {
      var printerId = this.get("selectedPrinterId");
      if (printerId) {
        var printer = this.store.peekRecord("printer", printerId);
        return { name: printer.get("name"), id: printer.id };
      }
    }),

    selectedGrade: _ember["default"].computed("model", function () {
      var grade = this.get("model.grade");
      return this.get("grades").filterBy("id", grade).get("firstObject");
    }),

    showPublishItemCheckBox: _ember["default"].computed("packageForm.quantity", "package.receivedQuantity", function () {
      this.set("isAllowedToPublish", false);
      return +this.get("packageForm.quantity") === 1;
    }),

    offer: _ember["default"].computed("model", function () {
      return this.get("store").peekRecord("offer", this.get("package.offerId"));
    }),

    identifyDevice: _ember["default"].on("init", function () {
      var isAndroidDevice = this.get("cordova").isAndroid();
      this.set("isAndroidDevice", isAndroidDevice);
    }),

    location: _ember["default"].computed("locationId", function () {
      return this.store.peekRecord("location", this.get("locationId"));
    }),

    locationId: _ember["default"].computed("package", function () {
      return this.get("package.location.id") || this.get("package.packageType.location.id");
    }),

    locations: _ember["default"].computed(function () {
      return this.store.peekAll("location");
    }),

    packageForm: _ember["default"].computed("package", {
      get: function get() {
        var pkg = this.get("package");
        return {
          quantity: pkg.get("receivedQuantity"),
          length: pkg.get("length"),
          width: pkg.get("width"),
          height: pkg.get("height"),
          labels: pkg.get("receivedQuantity")
        };
      }
    }),

    isInvalidQuantity: _ember["default"].computed("packageForm.quantity", function () {
      return this.get("packageForm.quantity") <= 0;
    }),

    isInvalidaLabelCount: _ember["default"].computed("packageForm.labels", function () {
      var labelCount = this.get("packageForm.labels");
      return !labelCount || Number(labelCount) < 0;
    }),

    isMultipleCountPrint: _ember["default"].computed("packageForm.labels", function () {
      return this.isValidLabelRange({ startRange: 1 });
    }),

    isInvalidPrintCount: _ember["default"].computed("packageForm.labels", function () {
      return this.isValidLabelRange({ startRange: 0 });
    }),

    printLabelCount: _ember["default"].computed("packageForm.labels", function () {
      return Number(this.get("packageForm.labels"));
    }),

    isInvalidDimension: _ember["default"].computed("packageForm.length", "packageForm.width", "packageForm.height", function () {
      var _get = this.get("packageForm");

      var length = _get.length;
      var width = _get.width;
      var height = _get.height;

      var dimensionsCount = _lodash["default"].filter([length, width, height], function (item) {
        return Number(item) <= 0;
      }).length;
      return _lodash["default"].inRange(dimensionsCount, 1, 3);
    }),

    disableReceiveButton: _ember["default"].computed("isInvalidPrintCount", "isInvalidaLabelCount", "locationId", "isInvalidQuantity", "inventoryNumber", "isInvalidDimension", "packageForm.labels", function () {
      return this.get("isInvalidQuantity") || !this.get("isInvalidPrintCount") || this.get("isInvalidaLabelCount") || this.get("isInvalidDimension") || !this.get("inventoryNumber") || !this.get("packageForm.labels") || !this.get("locationId");
    }),

    // ----- Helpers -----
    verifyInventoryNumber: function verifyInventoryNumber(value) {
      return (/^[A-Z]{0,1}[0-9]{5,6}(Q[0-9]*){0,1}$/i.test(value)
      );
    },

    resetInputs: function resetInputs() {
      this.setProperties({
        "packageForm.length": "",
        "packageForm.width": "",
        "packageForm.height": "",
        // prettier-ignore
        "isAllowedToPublish": ""
      });
    },

    isValidLabelRange: function isValidLabelRange(_ref) {
      var startRange = _ref.startRange;

      var labelCount = Number(this.get("packageForm.labels"));
      return _lodash["default"].inRange(labelCount, startRange, 301);
    },

    redirectToReceiveOffer: function redirectToReceiveOffer() {
      this.transitionToRoute("review_offer.receive");
    },

    receivePackageParams: function receivePackageParams() {
      var pkgData = this.get("packageForm");
      var inventoryNumber = this.get("inventoryNumber");
      var pkg = this.get("package");
      var locationId = this.get("locationId.id") || this.get("locationId");
      if (locationId) {
        var location = this.get("store").peekRecord("location", locationId);
        pkg.set("location", location);
      }
      pkg.set("state", "received");
      pkg.set("state_event", "mark_received");
      pkg.set("receivedQuantity", pkgData.quantity);
      pkg.set("length", pkgData.length);
      pkg.set("width", pkgData.width);
      pkg.set("height", pkgData.height);
      pkg.set("notes", this.get("description"));
      pkg.set("inventoryNumber", inventoryNumber);
      pkg.set("grade", this.get("selectedGrade.id"));
      pkg.set("allowWebPublish", this.get("isAllowedToPublish"));
      pkg.set("donorCondition", this.get("selectedCondition"));
      pkg.set("valueHkDollar", this.get("valueHkDollar"));
      return pkg;
    },

    cancelPackageOptions: function cancelPackageOptions() {
      var _this = this;

      var i18n = this.get("i18n");
      this.get("messageBox").custom(i18n.t("items.remove_item.confirmation_message"), i18n.t("items.remove_item.yes"), function () {
        var pkg = _this.get("package");
        var item = pkg.get("item");
        _this.runTask(_this.removeInventoryNumber().then(function () {
          var image = item.get("images.firstObject");
          if (image) {
            image.destroyRecord().then(function () {
              _this.deleteItem();
            });
          } else {
            _this.deleteItem();
          }
        })["catch"](function () {
          return _this.send("pkgUpdateError", pkg);
        }));
      }, i18n.t("items.remove_item.no"), null);
    },

    // ----- Ajax Request Methods -----
    removeInventoryAndRollbackAttr: function removeInventoryAndRollbackAttr() {
      var _this2 = this;

      var pkg = this.get("package");
      this.runTask(this.removeInventoryNumber().then(function () {
        pkg.rollbackAttributes();
        _this2.redirectToReceiveOffer();
      })["catch"](function () {
        return _this2.send("pkgUpdateError", pkg);
      }));
    },

    removeInventoryNumber: function removeInventoryNumber() {
      return this.get("packageService").removeInventoryNumber({
        code: this.get("inventoryNumber")
      });
    },

    generateInventoryNumber: function generateInventoryNumber() {
      return regeneratorRuntime.async(function generateInventoryNumber$(context$1$0) {
        var _this3 = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.runTask(this.get("packageService").generateInventoryNumber().then(function (data) {
              return _this3.set("inventoryNumber", data.inventory_number);
            })));

          case 2:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    deleteItem: function deleteItem() {
      var _this4 = this;

      var item = this.get("package.item");
      this.runTask(item.destroyRecord().then(function () {
        return _this4.redirectToReceiveOffer();
      }));
    },

    printBarcode: function printBarcode() {
      var _this5 = this;

      var packageId = this.get("package.id");
      var labels = this.get("packageForm.labels");
      this.get("packageService").printBarcode({
        package_id: packageId,
        labels: labels,
        tag: "admin"
      })["catch"](function (error) {
        _this5.get("messageBox").alert(error.responseJSON.errors && error.responseJSON.errors[0]);
      });
    },

    calculateValuation: function calculateValuation() {
      var model, valueHkDollar;
      return regeneratorRuntime.async(function calculateValuation$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            model = this.get("model");
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.get("packageService").getItemValuation({
              donorConditionId: this.get("selectedCondition.id"),
              grade: this.get("selectedGrade.id"),
              packageTypeId: model.get("packageType.id")
            }));

          case 3:
            valueHkDollar = context$1$0.sent;

            this.set("valueHkDollar", valueHkDollar.value_hk_dollar);

          case 5:
          case "end":
            return context$1$0.stop();
        }
      }, null, this);
    },

    // ----- Actions -----
    actions: {
      clearDescription: function clearDescription() {
        this.set("description", "");
      },

      changeGrade: function changeGrade(grade) {
        return regeneratorRuntime.async(function changeGrade$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              this.set("selectedGrade", grade);

              context$1$0.next = 3;
              return regeneratorRuntime.awrap(this.calculateValuation());

            case 3:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      changeDonorCondition: function changeDonorCondition(condition) {
        return regeneratorRuntime.async(function changeDonorCondition$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              this.set("selectedCondition", condition);

              context$1$0.next = 3;
              return regeneratorRuntime.awrap(this.calculateValuation());

            case 3:
            case "end":
              return context$1$0.stop();
          }
        }, null, this);
      },

      toggleInventoryOptions: function toggleInventoryOptions() {
        this.toggleProperty("displayInventoryOptions");
      },

      setPrinterValue: function setPrinterValue(value) {
        var printerId = value.id;
        this.set("selectedPrinterId", printerId);
        this.get("printerService").updateUserDefaultPrinter(printerId);
      },

      autoGenerateInventoryNumber: function autoGenerateInventoryNumber() {
        this.set("inventoryNumber", "");
        this.set("inputInventory", false);
        this.set("autoGenerateInventory", true);
        this.set("displayInventoryOptions", false);
        this.generateInventoryNumber();
      },

      deleteAutoGeneratedNumber: function deleteAutoGeneratedNumber() {
        var _this6 = this;

        this.runTask(this.removeInventoryNumber().then(function () {
          _this6.set("inventoryNumber", "");
        }));
      },

      editInventoryNumber: function editInventoryNumber() {
        this.send("deleteAutoGeneratedNumber");
        this.set("inventoryNumber", "");
        this.set("inputInventory", true);
        this.set("autoGenerateInventory", false);
        this.set("displayInventoryOptions", false);
      },

      assignImageToPackage: function assignImageToPackage() {
        var itemId = this.get("package.item.id");
        this.transitionToRoute("package.image_editor", this.get("package"));
      },

      moveBack: function moveBack() {
        if (this.get("isUnplannedPackage")) {
          this.cancelPackageOptions();
        } else {
          this.removeInventoryAndRollbackAttr();
        }
      },

      receivePackage: function receivePackage() {
        var _this7 = this;

        var pkg = this.receivePackageParams();

        this.runTask(pkg.save().then(function () {
          if (_this7.get("isMultipleCountPrint")) {
            _this7.printBarcode();
          }
          pkg.set("packagesLocationsAttributes", {});
          _this7.redirectToReceiveOffer();
          _ember["default"].run.scheduleOnce("afterRender", _this7, function () {
            return _this7.get("reviewOfferController").set("displayCompleteReceivePopup", _this7.get("offer.readyForClosure"));
          });
        })["catch"](function () {
          pkg.rollbackAttributes();
          _this7.send("pkgUpdateError", pkg);
        }));
      },

      pkgUpdateError: function pkgUpdateError(pkg) {
        var errorMessage = pkg.get("errors.firstObject.message") || pkg.get("adapterError.errors.firstObject.detail.errors.firstObject");

        if (errorMessage) {
          pkg.rollbackAttributes();
          this.get("messageBox").alert(errorMessage);
        }
      }
    }
  });
});