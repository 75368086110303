define("goodcity/components/offer-filters", ["exports", "ember", "lodash", "goodcity/services/filter-service"], function (exports, _ember, _lodash, _goodcityServicesFilterService) {

  // --- Helpers

  function setFilter(filter, val) {
    if (_ember["default"].$("#" + filter)[0]) {
      _ember["default"].$("#" + filter)[0].checked = val;
    }
  }

  function checkFilter(filter) {
    setFilter(filter, true);
  }

  function uncheckFilter(filter) {
    setFilter(filter, false);
  }

  function isChecked(filter) {
    if (_ember["default"].$("#" + filter)[0]) {
      return _ember["default"].$("#" + filter)[0].checked;
    }
  }

  function startOfDay(date) {
    return moment(date).startOf("day").toDate();
  }

  function endOfDay(date) {
    return moment(date).endOf("day").toDate();
  }

  var STATE = "state";
  var TIME = "time";
  var UNKNOWN = "unknown";

  // --- Component

  exports["default"] = _ember["default"].Component.extend({
    i18n: _ember["default"].inject.service(),
    isSharedSelected: false,
    filterService: _ember["default"].inject.service(),

    selectedTimeRange: {
      preset: "",
      after: null,
      before: null
    },

    allOfferStateFilters: _ember["default"].computed(function () {
      return _lodash["default"].values(_goodcityServicesFilterService.STATE_FILTERS);
    }),

    offerStateFilters: _ember["default"].computed("allOfferStateFilters.[]", function () {
      // Separate out "showPriority" filter as it has some different css properties than others
      return _lodash["default"].without(this.get("allOfferStateFilters"), _goodcityServicesFilterService.STATE_FILTERS.PRIORITY, _goodcityServicesFilterService.STATE_FILTERS.PUBLISHED, _goodcityServicesFilterService.STATE_FILTERS.EXPIREDSHAREABLES);
    }),

    presetTimeKeys: _ember["default"].computed(function () {
      return _lodash["default"].keys(this.get("filterService.offerTimeRangePresets"));
    }),

    filterContext: _ember["default"].computed("applyStateFilter", function () {
      if (this.get("applyStateFilter")) {
        return STATE;
      }
      return UNKNOWN;
    }),

    // Marks filters as selected depending on pre-selected set of filters
    didInsertElement: function didInsertElement() {
      var _this = this;

      var service = this.get("filterService");
      var context = this.get("filterContext");

      switch (context) {
        case STATE:
          service.get("offerStateFilters").forEach(checkFilter);
          _ember["default"].run.once("afterRender", function () {
            _this.set("isSharedSelected", Boolean(isChecked("nonExpiredPublishedOffers")));
          });
        case TIME:
          var _service$get = service.get("offerTimeRange"),
              preset = _service$get.preset,
              after = _service$get.after,
              before = _service$get.before;

          return this.set("selectedTimeRange", {
            preset: preset,
            after: preset ? null : after,
            before: preset ? null : before
          });
      }
    },

    didRender: function didRender() {
      this.set("isSharedSelected", Boolean(isChecked("nonExpiredPublishedOffers")));
    },

    // Adds applied filters to localStorage as an array and redirects
    applyFilter: function applyFilter(filters, name) {
      var filterService = this.get("filterService");
      var appliedFilters = filters.filter(isChecked);
      filterService.set(name, appliedFilters);
      this.navigateAway();
    },

    applyTimeFilters: function applyTimeFilters() {
      var _get = this.get("selectedTimeRange");

      var preset = _get.preset;
      var after = _get.after;
      var before = _get.before;

      this.get("filterService").setOfferTimeRange(preset || { after: after, before: before });
      this.navigateAway();
    },

    navigateAway: function navigateAway() {
      this.get("router").transitionTo("search");
    },

    uncheckAll: function uncheckAll(filterType) {
      this.get(filterType).forEach(uncheckFilter);
      this.set("isSharedSelected", false);
    },

    clearTimeFilters: function clearTimeFilters() {
      this.set("selectedTimeRange.preset", null);
      this.set("selectedTimeRange.before", null);
      this.set("selectedTimeRange.after", null);
    },

    _setRangeProperty: function _setRangeProperty(prop, date) {
      this.set("selectedTimeRange.preset", null);
      this.set("selectedTimeRange." + prop, date);
    },

    actions: {
      applyFilters: function applyFilters() {
        if (this.get("applyStateFilter")) {
          return this.applyFilter(this.get("allOfferStateFilters"), "offerStateFilters");
        }
        if (this.get("applyTimeFilter")) {
          return this.applyTimeFilters();
        }
      },

      selectTimePreset: function selectTimePreset(presetKey) {
        this.clearTimeFilters();
        this.set("selectedTimeRange.preset", presetKey);
      },

      clearFilters: function clearFilters() {
        if (this.get("applyStateFilter")) {
          return this.uncheckAll("allOfferStateFilters");
        }

        if (this.get("applyTimeFilter")) {
          this.clearTimeFilters();
        }
      },

      setBeforeTime: function setBeforeTime(before) {
        this._setRangeProperty("before", endOfDay(before));
      },

      setAfterTime: function setAfterTime(after) {
        this._setRangeProperty("after", startOfDay(after));
      }
    }
  });
});