define("goodcity/components/receive-menu", ["exports", "ember", "goodcity/utils/ajax-promise", "goodcity/mixins/async_tasks"], function (exports, _ember, _goodcityUtilsAjaxPromise, _goodcityMixinsAsync_tasks) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Component.extend(_goodcityMixinsAsync_tasks["default"], {
    hidden: true,
    packageId: null,
    store: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    packageService: _ember["default"].inject.service(),
    printerService: _ember["default"].inject.service(),
    displayUserPrompt: false,

    isReceived: _ember["default"].computed.equal("package.state", "received"),
    isMissing: _ember["default"].computed.equal("package.state", "missing"),

    isReceivedWithoutInventory: _ember["default"].computed("package", "isReceived", function () {
      return this.get("isReceived") && !this.get("package.inventoryNumber");
    }),

    allowLabelPrint: _ember["default"].computed("isReceived", "package.inventoryNumber", function () {
      return this.get("isReceived") && this.get("package.inventoryNumber");
    }),

    preventEdit: _ember["default"].computed("package.hasAllPackagesDispatched", "package.hasAllPackagesDesignated", function () {
      var pkg = this.get("store").peekRecord("package", this.get("packageId"));
      return pkg.get("hasAllPackagesDispatched") || pkg.get("hasAllPackagesDesignated");
    }),

    offer: _ember["default"].computed("packageId", function () {
      return this.get("store").peekRecord("offer", this.get("package.offerId"));
    }),

    "package": _ember["default"].computed("packageId", function () {
      return this.get("store").peekRecord("package", this.get("packageId"));
    }),

    currentUrl: _ember["default"].computed("packageId", function () {
      return getOwner(this).lookup("router:main").get("url");
    }),

    isFirstReceivingPackage: _ember["default"].computed("package", function () {
      var offerPackages = this.get("offer.packages");
      return offerPackages.get("length") === offerPackages.filterBy("state", "expecting").length && !this.get("offer.isReceiving");
    }),

    updatePackage: function updatePackage(action) {
      var _this = this;

      var loadingView = getOwner(this).lookup("component:loading").append();
      var pkg = this.get("package");
      action(pkg);
      pkg.save().then(function () {
        loadingView.destroy();
        getOwner(_this).lookup("controller:review_offer").set("displayCompleteReceivePopup", _this.get("offer.readyForClosure"));
      })["catch"](function (error) {
        loadingView.destroy();
        var errorMessage = pkg.get("errors.firstObject.message");
        var matchFound = ["Connection error", "Dispatched"].some(function (v) {
          return errorMessage.indexOf(v) >= 0;
        });
        if (matchFound) {
          _this.get("messageBox").alert(pkg.get("errors.firstObject.message"), function () {
            pkg.rollbackAttributes();
          });
        } else {
          pkg.rollbackAttributes();
          throw error;
        }
      });
    },

    i18n: _ember["default"].inject.service(),

    deliveredOptions: _ember["default"].computed(function () {
      return [{ value: "Unknown", name: this.get("i18n").t("mark_received.unknown") }, { value: "Gogovan", name: this.get("i18n").t("mark_received.gogovan") }, {
        value: "Alternate",
        name: this.get("i18n").t("mark_received.crossroads_truck")
      }, {
        value: "Drop Off",
        name: this.get("i18n").t("mark_received.dropped_off")
      }];
    }),

    deliveredBy: _ember["default"].computed("offer.deliveredBy", function () {
      return this.get("offer.deliveredBy");
    }),

    confirmReceivingEvent: null,

    toggleItemClass: function toggleItemClass() {
      var item = this.get("package");
      _ember["default"].$(".receive-options." + item.id).toggleClass("hidden");
      _ember["default"].$(".options-link-open." + item.id).toggleClass("hidden");
    },

    actions: {
      toggle: function toggle(hidden) {
        this.set("hidden", hidden);
        var item = this.get("package");
        var itemOptionsLink = _ember["default"].$(".options-link-open." + item.id)[0];
        var optionsLink = _ember["default"].$(".options-link-open.hidden");
        if (optionsLink.length) {
          _ember["default"].$(".receive-options").not(".hidden").toggleClass("hidden");
          _ember["default"].$(".options-link-open.hidden").toggleClass("hidden");
          return false;
        } else if (itemOptionsLink) {
          this.toggleItemClass();
          return false;
        } else {
          return true;
        }
      },

      checkReceiving: function checkReceiving(event, isEventPerformed) {
        var _this2 = this;

        if (!isEventPerformed) {
          if (this.get("offer.isFinished")) {
            this.get("messageBox").confirm(this.get("i18n").t("review_offer.confirm_receiving_message"), function () {
              return _this2.send("applyReceiving", event, false);
            });
          } else {
            this.send("applyReceiving", event);
          }
        }
      },

      inventorizeReceivedItem: function inventorizeReceivedItem() {
        var _this3 = this;

        this.get("messageBox").confirm(this.get("i18n").t("receive.inventorize_warning"), function () {
          _this3.get("router").transitionTo("receive_package", _this3.get("packageId"));
        });
      },

      applyReceiving: function applyReceiving(event) {
        var allow_event = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

        if (!this.get("isFirstReceivingPackage") && allow_event) {
          return this.send(event);
        }
        this.set("confirmReceivingEvent", event);
        this.set("displayUserPrompt", true);
      },

      confirmReceiving: function confirmReceiving() {
        var _this4 = this;

        var offer = this.get("offer");
        offer.set("deliveredBy", this.get("deliveredBy.value"));
        offer.set("state_event", "start_receiving");
        offer.save()["catch"](function (error) {
          offer.rollbackAttributes();
          throw error;
        }).then(function () {
          return _this4.send(_this4.get("confirmReceivingEvent"));
        });
      },

      missing: function missing() {
        if (!this.get("isMissing")) {
          var pkg = this.get("package");
          this.runTask(this.get("packageService").markMissing(pkg), _goodcityMixinsAsync_tasks.ERROR_STRATEGIES.MODAL);
        }
      },

      receive: function receive() {
        if (!this.get("isReceived")) {
          this.updatePackage(function (p) {
            p.set("inventoryNumber", null);
            p.set("state", "received");
            p.set("state_event", "mark_received");
          });
        }
      },

      receiveInInventory: function receiveInInventory() {
        if (!this.get("isReceived")) {
          this.get("router").transitionTo("receive_package", this.get("packageId"));
        }
      },

      printBarcode: function printBarcode() {
        var _this5 = this;

        this.runTask(function () {
          _this5.get("printerService").printInventoryLabel(_this5.get("packageId")).then(function () {
            _this5.send("toggle", true);
            _ember["default"].$("#printer_message_" + _this5.get("packageId")).css({
              display: "block"
            });
            _ember["default"].run.debounce(_this5, _this5.hidePrinterMessage, 200);
          });
        }, _goodcityMixinsAsync_tasks.ERROR_STRATEGIES.MODAL);
      }
    },

    hidePrinterMessage: function hidePrinterMessage() {
      _ember["default"].$("#printer_message_" + this.get("package.id")).fadeOut(3000);
    }
  });
});