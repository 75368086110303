define("goodcity/utils/promisify", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = promosify;

  /**
   * A helper utility to work with promises in computed property
   * @param {Function} cb
   */

  function promosify() {
    var cb = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

    if (!cb) {
      return;
    }

    return _emberData["default"].PromiseObject.create({
      promise: cb()
    });
  }
});