define("goodcity/models/canned_response", ["exports", "ember-data/model", "ember-data/attr", "ember"], function (exports, _emberDataModel, _emberDataAttr, _ember) {
  exports["default"] = _emberDataModel["default"].extend({
    name: (0, _emberDataAttr["default"])("string"),
    nameEn: (0, _emberDataAttr["default"])("string"),
    nameZhTw: (0, _emberDataAttr["default"])("string"),
    content: (0, _emberDataAttr["default"])("string"),
    contentEn: (0, _emberDataAttr["default"])("string"),
    contentZhTw: (0, _emberDataAttr["default"])("string"),
    guid: (0, _emberDataAttr["default"])("string"),
    messageType: (0, _emberDataAttr["default"])("string"),
    isPrivate: _ember["default"].computed("messageType", function () {
      return this.get("messageType") === "SYSTEM";
    })
  });
});