define('goodcity/controllers/delivery/book_timeslot', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    delivery: _ember['default'].inject.controller(),
    availableSlots: _ember['default'].computed.filterBy('model', 'deliveries.length', 0),
    isSelected: 1,

    actions: {
      assignSchedule: function assignSchedule() {
        var selectedSlot = this.get('isSelected');
        var getSelectedSchedule = this.store.peekRecord('schedule', selectedSlot);
        var scheduleProperties = getSelectedSchedule.getProperties('zone', 'resource', 'scheduledAt', 'slot', 'slotName');
        var schedule = this.store.createRecord('schedule', scheduleProperties);
        var deliveryId = this.get('delivery').get('model.id');
        var delivery = this.store.peekRecord('delivery', deliveryId);
        delivery.set('schedule', schedule);
        this.transitionToRoute('delivery.contact_details', { queryParams: { placeOrder: true } });
      }
    }
  });
});