define("goodcity/utils/records", ["exports", "ember"], function (exports, _ember) {
  // import config from "../config/environment";

  exports["default"] = {
    // this function navigates the relationship tree unloading records unless
    // related record is a taxonomy type or user
    unloadRecordTree: function unloadRecordTree(record) {
      var _this = this;

      var taxonomyTypes = ["territory", "district", "package-type", "donor-condition", "rejection-reason", "permission", "timeslot", "gogovan-transport", "crossroads-transport", "version", "user"];

      // TO_DO FIX ISSUE WITH DELETION
      // var taxonomyTypes = config.APP.PRELOAD_TYPES
      //   .concat(config.APP.PRELOAD_AUTHORIZED_TYPES)
      //   .concat("user")
      //   .map(t => t.replace(/_/, '-'));

      if (!record || record.unloading || taxonomyTypes.indexOf(record.constructor.modelName) !== -1) {
        return;
      }

      record.unloading = true;
      record.eachRelationship(function (name, descriptor) {
        if (descriptor.options.async && !_this.isAsyncRelationshipLoaded(record, name)) {
          return;
        }

        var unload = function unload(r) {
          if (descriptor.kind === "belongsTo") {
            _this.unloadRecordTree(r);
          } else if (descriptor.kind === "hasMany") {
            r.forEach(function (r2) {
              return _this.unloadRecordTree(r2);
            });
          }
        };

        if (descriptor.options.async) {
          _ember["default"].run.next(function () {
            return record.get(name).then(function (r) {
              return unload(r);
            });
          });
        } else {
          try {
            unload(record.get(name));
          } catch (e) {
            throw e;
          }
        }
      });

      _ember["default"].run.next(function () {
        return record.unloadRecord();
      });
    },

    isAsyncRelationshipLoaded: function isAsyncRelationshipLoaded(record, relationshipName) {
      // todo replace use of private api "record._relationships" https://github.com/emberjs/data/issues/2431
      var relatedRecord = record.relationshipFor(relationshipName).inverseRecord;

      // this is the workaround I read in github issue
      if (!relatedRecord) {
        return false;
      }

      // this is the workaround I seem to need from unit test
      var relationshipKind = _ember["default"].get(record.constructor, "relationshipsByName").get(relationshipName).kind;
      if (relationshipKind === "belongsTo") {
        return record.store.hasRecordForId(relatedRecord.constructor.modelName, relatedRecord.id);
      } else {
        throw "NotYetImplemented";
      }
    }
  };
});