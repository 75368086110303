define("goodcity/utils/utility-methods", ["exports", "lodash"], function (exports, _lodash) {
  exports["default"] = {
    arrayExists: function arrayExists(arr) {
      return arr && arr.length;
    },

    exists: function exists(obj) {
      return obj;
    },

    stringifyArray: function stringifyArray(arr) {
      return Array.isArray(arr) ? arr.toString() : "";
    },

    supportsField: function supportsField(record, field) {
      var fields = record.get("constructor.attributes");
      return fields.has(_lodash["default"].camelCase(field));
    }
  };
});