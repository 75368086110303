define('goodcity/controllers/offer/collection_charges', ['exports', 'ember', 'goodcity/config/environment'], function (exports, _ember, _goodcityConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    i18n: _ember['default'].inject.service(),

    info: _ember['default'].computed(function () {
      var emailLink = "<a href='mailto:" + _goodcityConfigEnvironment['default'].APP.CONTACT_EMAIL + "'>" + _goodcityConfigEnvironment['default'].APP.CONTACT_EMAIL + "</a>";
      var infoText = this.get("i18n").t("collection_charges.info", { "email": emailLink });
      return "<div>" + infoText.replace(/\n\n/g, "</div><div>") + "</div>";
    })
  });
});