define('goodcity/components/language-switcher', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    isEnglish: _ember['default'].computed('session.language', function () {
      return this.get('session.language') === 'en';
    }),

    isChinese: _ember['default'].computed('session.language', function () {
      return this.get('session.language') === 'zh-tw';
    }),

    actions: {
      setLanguage: function setLanguage(language) {
        this.set('session.language', language);
        window.location.reload();
      }
    }

  });
});