define("goodcity/components/reject-message-field", ["exports", "ember", "ember-i18n"], function (exports, _ember, _emberI18n) {
  exports["default"] = _ember["default"].Component.extend({
    disabled: false,
    placeholderText: (0, _emberI18n.translationMacro)("reject.message_placeholder"),
    i18n: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),
    attributeBindings: ["value"],

    valueChanged: _ember["default"].observer("rejectMessage", function () {
      this.get("setMessage")(this.get("rejectMessage"));
    }),

    rejectMessage: _ember["default"].computed("selectedId", {
      get: function get() {
        var reasonRecord = this.get("store").peekRecord("rejection_reason", this.get("selectedId"));
        var reason = reasonRecord && reasonRecord.get("name");
        var message = "";

        switch (reason) {
          case this.get("i18n").t("reject.quality").string:
            message = this.get("i18n").t("reject.reject_message") + this.get("i18n").t("reject.quality_message");
            break;
          case this.get("i18n").t("reject.size").string:
            message = this.get("i18n").t("reject.reject_message") + this.get("i18n").t("reject.size_message");
            break;
          case this.get("i18n").t("reject.supply").string:
            message = this.get("i18n").t("reject.supply_message");
            break;
        }

        if (this.get("selectedId") === "-1") {
          message = this.get("i18n").t("reject.reject_message");
        }
        return message;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    actions: {
      clearRejectMessage: function clearRejectMessage() {
        this.set("rejectMessage", "");
      }
    },

    didInsertElement: function didInsertElement() {
      var item = this.get("store").peekRecord("item", this.get("itemId"));
      this.set("rejectMessage", item.get("rejectionComments"));
    }
  });
});