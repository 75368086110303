define('goodcity/models/address', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

  var attr = _emberData['default'].attr,
      belongsTo = _emberData['default'].belongsTo;

  exports['default'] = _emberData['default'].Model.extend({
    flat: attr('string'),
    building: attr('string'),
    street: attr('string'),
    addressType: attr('string'),

    district: belongsTo('district', { async: false }),

    addressableType: attr('string'),
    addressable: belongsTo('addressable', { polymorphic: true, async: false }),

    fullAddress: _ember['default'].computed('flat', 'building', 'street', function () {
      var addressDetails = [this.get('flat'), this.get('building'), this.get('street')];
      addressDetails = _ember['default'].isBlank(addressDetails.compact()) ? [this.get('district.name'), this.get('district.territory.name')] : addressDetails;
      var formattedAddress = addressDetails.join('<br>');
      return new _ember['default'].Handlebars.SafeString(formattedAddress);
    }),

    regionDetails: _ember['default'].computed('flat', 'building', 'street', function () {
      return [this.get('flat'), this.get('building'), this.get('street')].compact().join(" ");
    })
  });
});