define("goodcity/services/cloudinary-utils", ["exports", "ember", "goodcity/config/environment"], function (exports, _ember, _goodcityConfigEnvironment) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports["default"] = _ember["default"].Service.extend({
    generateThumbnailUrl: function generateThumbnailUrl(id, opts) {
      return this.generateUrl(id, _extends({}, opts, {
        width: 300,
        height: 300,
        crop: true
      }));
    },

    generateUrl: function generateUrl(id) {
      var opts = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
      var _opts$angle = opts.angle;
      var angle = _opts$angle === undefined ? 0 : _opts$angle;
      var _opts$crop = opts.crop;
      var crop = _opts$crop === undefined ? false : _opts$crop;
      var width = opts.width;
      var height = opts.height;

      if (!id || id.indexOf("/") === -1) {
        return null;
      }

      if (id.indexOf(_goodcityConfigEnvironment["default"].APP.LONG_TERM_IMAGE_STORAGE_ID_PREFIX) === -1) {
        // Not on Azure so generate Cloudinary storage url
        var version = id.split("/")[0];
        var filename = id.substring(id.indexOf("/") + 1);
        var options = {
          version: version,
          height: height,
          width: width,
          crop: crop === true ? "fill" : "fit",
          flags: "progressive",
          id: id,
          secure: true,
          protocol: "https:"
        };
        if (angle) {
          options["angle"] = angle;
        }
        return _ember["default"].$.cloudinary.url(filename, options);
      } else if (id.indexOf(_goodcityConfigEnvironment["default"].APP.LONG_TERM_IMAGE_STORAGE_ID_PREFIX) === 0) {
        // id begins with config.LONG_TERM_IMAGE_STORAGE_ID_PREFIX (e.g. 'azure-')
        // generate storage url for images that are no longer stored on Cloudinary
        // this ignores most image options except for specific known thumbnails
        var filename = id.substring(id.indexOf(_goodcityConfigEnvironment["default"].APP.LONG_TERM_IMAGE_STORAGE_ID_PREFIX) + 6);
        if (width <= "300") {
          // Use the stored thumbnail. Hardcoded for now
          // 1438323573/default/test_image.jpg -> 1438323573/default/test_image-300x300.jpg
          filename = filename.replace(/\.([^\.]+)$/, "-300x300.$1");
        }
        return _goodcityConfigEnvironment["default"].APP.LONG_TERM_IMAGE_STORAGE_BASE_URL + filename;
      } else {
        return null;
      }
    }
  });
});