define("goodcity/components/inactive-offer", ["exports", "ember", "goodcity/utils/ajax-promise"], function (exports, _ember, _goodcityUtilsAjaxPromise) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Component.extend({
    hidden: true,
    packageId: null,
    store: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    invalidMessage: false,
    displayUserPrompt: false,
    stopSharingAt: null,

    inactiveMessage: _ember["default"].computed(function () {
      return this.get("i18n").t("inactive_offer.message");
    }),

    actions: {
      confirmMarkOfferInactive: function confirmMarkOfferInactive() {
        this.set("displayUserPrompt", true);
      },

      markOfferInactive: function markOfferInactive() {
        var _this = this;

        var inactiveMessage = this.get("inactiveMessage.string") || this.get("inactiveMessage");

        if (_ember["default"].$.trim(inactiveMessage).length === 0) {
          this.set("invalidMessage", true);
          return false;
        }

        var loadingView = getOwner(this).lookup("component:loading").append();

        var offer = this.get("offer");
        var url = "/offers/" + offer.id + "/mark_inactive";

        new _goodcityUtilsAjaxPromise["default"](url, "PUT", this.get("session.authToken"), {
          offer: {
            inactive_message: inactiveMessage,
            sharing_expires_at: this.get("stopSharingAt")
          }
        }).then(function (data) {
          _this.get("store").pushPayload(data);
        })["finally"](function () {
          loadingView.destroy();
          _this.sendAction("toggleAction");
        });
      }
    }
  });
});