define('goodcity/helpers/app-version', ['exports', '@ember/component/helper', 'goodcity/config/environment', 'ember-cli-app-version/utils/regexp'], function (exports, _emberComponentHelper, _goodcityConfigEnvironment, _emberCliAppVersionUtilsRegexp) {
  exports.appVersion = appVersion;

  function appVersion(_) {
    var hash = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

    var version = _goodcityConfigEnvironment['default'].APP.version;
    // e.g. 1.0.0-alpha.1+4jds75hf

    // Allow use of 'hideSha' and 'hideVersion' For backwards compatibility
    var versionOnly = hash.versionOnly || hash.hideSha;
    var shaOnly = hash.shaOnly || hash.hideVersion;

    var match = null;

    if (versionOnly) {
      if (hash.showExtended) {
        match = version.match(_emberCliAppVersionUtilsRegexp.versionExtendedRegExp); // 1.0.0-alpha.1
      }
      // Fallback to just version
      if (!match) {
        match = version.match(_emberCliAppVersionUtilsRegexp.versionRegExp); // 1.0.0
      }
    }

    if (shaOnly) {
      match = version.match(_emberCliAppVersionUtilsRegexp.shaRegExp); // 4jds75hf
    }

    return match ? match[0] : version;
  }

  exports['default'] = (0, _emberComponentHelper.helper)(appVersion);
});