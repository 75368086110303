define("goodcity/services/message-box", ["exports", "ember"], function (exports, _ember) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Service.extend({
    i18n: _ember["default"].inject.service(),

    alert: function alert(message, callback) {
      this.custom(message, this.get("i18n").t("okay"), callback);
    },

    confirm: function confirm(message, callback) {
      this.custom(message, this.get("i18n").t("cancel"), null, this.get("i18n").t("okay"), callback);
    },

    custom: function custom(message, btn1Text, btn1Callback, btn2Text, btn2Callback, displayCloseLink) {
      _ember["default"].$(document).trigger("cancel-loading-timer");
      _ember["default"].$(".loading-indicator").remove();

      var view = getOwner(this).lookup("component:message-box").append();
      view.set("btn1Text", btn1Text);
      view.set("btn1Callback", btn1Callback);
      view.set("btn2Text", btn2Text);
      view.set("btn2Callback", btn2Callback);
      view.set("message", message);
      view.set("displayCloseLink", displayCloseLink);
      view.set("isVisible", true);
    }
  });
});