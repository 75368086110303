define('goodcity/models/item', ['exports', 'ember', 'ember-data', 'goodcity/computed/foreign-key', 'goodcity/config/environment'], function (exports, _ember, _emberData, _goodcityComputedForeignKey, _goodcityConfigEnvironment) {

  var attr = _emberData['default'].attr,
      belongsTo = _emberData['default'].belongsTo,
      hasMany = _emberData['default'].hasMany,
      foreignKey = _ember['default'].computed.foreignKey;

  exports['default'] = _emberData['default'].Model.extend({
    donorDescription: attr('string'),
    state: attr('string'),
    rejectReason: attr('string'),
    rejectionComments: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    packages: hasMany('package', { async: false }),
    messages: hasMany('message', { async: true }),
    images: hasMany('image', { async: true }),
    offer: belongsTo('offer', { async: true }),
    packageType: belongsTo('package_type', { async: false }),
    donorCondition: belongsTo('donor_condition', { async: false }),
    donorConditionId: foreignKey('donorCondition.id'),
    rejectionReason: belongsTo('rejection_reason', { async: false }),
    state_event: attr('string'),

    isAccepted: _ember['default'].computed.equal("state", "accepted"),
    isRejected: _ember['default'].computed.equal("state", "rejected"),
    isDrafted: _ember['default'].computed.equal("state", "draft"),
    appName: _goodcityConfigEnvironment['default'].APP.NAME,

    removePrivateMessage: _ember['default'].observer('messages.[]', 'messages.@each.isPrivate', function () {
      var _this = this;

      if (this.get('appName') === "app.goodcity") {
        this.get("messages").forEach(function (msg) {
          if (msg && msg.get('isPrivate') === undefined) {
            _this.store.unloadRecord(msg);
          }
        });
      }
    }),

    canUpdated: _ember['default'].computed("hasReceivedPackages", "offer.state", function () {
      return !(this.get("hasReceivedPackages") || this.get("offer.isFinished") || this.get('offer.state') === 'receiving');
    }),

    isDraft: _ember['default'].computed('offer.state', function () {
      return this.get('offer.state') === 'draft';
    }),

    isSubmitted: _ember['default'].computed('state', 'offer.state', function () {
      return this.get('state') === 'submitted' && this.get('offer.state') === 'submitted';
    }),

    isUnderReview: _ember['default'].computed('state', 'offer.state', function () {
      return this.get('state') === 'submitted' && this.get('offer.state') === 'under_review';
    }),

    hasReceivedPackages: _ember['default'].computed('packages.@each.state', function () {
      return this.get('packages').filterBy('isReceived', true).length > 0;
    }),

    displayImage: _ember['default'].computed('images.@each.favourite', function () {
      return this.get("images").filterBy("favourite").get("firstObject") || this.get("images").sortBy("id").get("firstObject") || null;
    }),

    nonFavouriteImages: _ember['default'].computed('images.@each.favourite', function () {
      return this.get("images").rejectBy("favourite", true);
    }),

    displayImageUrl: _ember['default'].computed('displayImage', 'displayImage.thumbImageUrl', function () {
      return this.get('displayImage.thumbImageUrl') || "assets/images/default_item.jpg";
    }),

    imageCount: _ember['default'].computed.alias("images.length"),

    // unread messages
    unreadMessages: _ember['default'].computed('messages.@each.state', function () {
      return this.get('messages').filterBy('state', 'unread').sortBy('createdAt');
    }),

    // unread offer-messages by donor
    hasUnreadDonorMessages: _ember['default'].computed('unreadMessages', function () {
      return this.get('unreadMessages').filterBy('isPrivate', false).length > 0;
    }),

    // unread offer-messages by supervisor-reviewer
    hasUnreadPrivateMessages: _ember['default'].computed('unreadMessages', function () {
      return this.get('unreadMessages').filterBy('isPrivate', true).length > 0;
    }),

    unreadMessagesCount: _ember['default'].computed('unreadMessages', function () {
      var count = this.get('unreadMessages').length;
      return count > 0 ? count : null;
    }),

    // last message
    lastMessage: _ember['default'].computed('messages.[]', 'messages.@each.body', function () {
      return this.get('messages').sortBy('createdAt').get('lastObject');
    }),

    // to sort on offer-details page for updated-item and latest-message
    latestUpdatedTime: _ember['default'].computed('lastMessage', function () {
      var value;
      switch (_ember['default'].compare(this.get('lastMessage.createdAt'), this.get('updatedAt'))) {
        case 0:
        case 1:
          value = this.get('lastMessage.createdAt');break;
        case -1:
          value = this.get('updatedAt');break;
      }
      return value;
    }),

    statusBarClass: _ember['default'].computed("state", function () {
      if (this.get("offer.isCancelled")) {
        return "is-closed";
      } else if (this.get("isSubmitted")) {
        return "is-submitted";
      } else if (this.get("isUnderReview")) {
        return "is-under-review";
      } else if (this.get("isAccepted")) {
        return "is-accepted";
      } else if (this.get("isRejected")) {
        return "is-rejected";
      }
    }),

    pageLink: _ember['default'].computed("state", function () {
      return this.get("isRejected") ? 'review_item.reject' : 'review_item.accept';
    })
  });
});