define("goodcity/services/filter-service", ["exports", "ember", "goodcity/utils/time-ranges", "lodash"], function (exports, _ember, _goodcityUtilsTimeRanges, _lodash) {

  // --- Helpers

  var PERSISTENT_VAR = function PERSISTENT_VAR(propName, defaultValue) {
    var deserializeMap = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

    return _ember["default"].computed({
      get: function get() {
        var data = this.get("localStorage").read(propName, defaultValue);
        for (var key in deserializeMap) {
          if (data[key]) {
            data[key] = deserializeMap[key](data[key]);
          }
        }
        return data;
      },
      set: function set(k, value) {
        this.get("localStorage").write(propName, value);
        this.trigger("change");
        return value;
      }
    });
  };

  var STATE_FILTERS = {
    PRIORITY: "priorityOffers",
    PUBLISHED: "nonExpiredPublishedOffers",
    EXPIREDSHAREABLES: "allPublishedOffers",
    NEW: "submitted",
    REVIEWING: "under_review",
    REVIEWED: "reviewed",
    SCHEDULED: "scheduled",
    RECEIVING: "receiving",
    RECEIVED: "received",
    CANCELLED: "cancelled",
    INACTIVE: "inactive"
  };

  exports.STATE_FILTERS = STATE_FILTERS;
  // --- Service

  exports["default"] = _ember["default"].Service.extend(_ember["default"].Evented, {
    localStorage: _ember["default"].inject.service(),
    routing: _ember["default"].inject.service("-routing"),

    offerStateFilters: PERSISTENT_VAR("offerStateFilters", []),

    isPriority: function isPriority() {
      var filters = this.get("offerStateFilters");
      return filters && filters.indexOf(STATE_FILTERS.PRIORITY) >= 0;
    },

    isPublished: function isPublished() {
      var filters = this.get("offerStateFilters");
      return _lodash["default"].includes(filters, STATE_FILTERS.PUBLISHED);
    },

    includeExpiryShareables: function includeExpiryShareables() {
      var filters = this.get("offerStateFilters");
      return _lodash["default"].includes(filters, STATE_FILTERS.EXPIREDSHAREABLES);
    },

    clearOfferStateFilters: function clearOfferStateFilters() {
      this.set("offerStateFilters", []);
    },

    offerSorting: PERSISTENT_VAR("offerSorting", {}),

    init: function init() {
      this._super.apply(this, arguments);
      this.set("openOfferSortOption", false);
    },

    applySortOn: function applySortOn(sort_column) {
      var is_desc = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      this.set("offerSorting", { sort_column: sort_column, is_desc: is_desc });
      this.set("openOfferSortOption", false);
    },

    toggleSortPage: function toggleSortPage() {
      this.toggleProperty("openOfferSortOption");
    },

    clearOfferTimeFilters: function clearOfferTimeFilters() {
      this.setOfferTimeRange(null);
    },

    clearReviewFilters: function clearReviewFilters() {
      this.set("selfReviewFilter", false);
    },

    clearFilters: function clearFilters() {
      this.clearOfferStateFilters();
      this.clearOfferTimeFilters();
      this.clearReviewFilters();
    },

    clearAndApplyStateFilter: function clearAndApplyStateFilter(state, selfReview) {
      var priority = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

      this.clearFilters();
      var states = [];
      if (selfReview) {
        this.set("selfReviewFilter", true);
      }
      if (priority) {
        states.push(STATE_FILTERS.PRIORITY);
      }
      states.push(state);
      this.set("offerStateFilters", states);
      this.get("routing").transitionTo("search");
    },

    hasOfferFilters: _ember["default"].computed("offerStateFilters", function () {
      var timeRange = this.get("offerTimeRange");
      return this.get("offerStateFilters").length > 0;
    }),

    // --- Reviewer filters
    selfReviewFilter: PERSISTENT_VAR("selfReviewFilter", false),

    enableReviewFilter: function enableReviewFilter() {
      this.set("selfReviewFilter", true);
    },

    // --- Offer time filters

    _offerTimeSettings: PERSISTENT_VAR("offerTimeSettings", {}, {
      after: function after(raw) {
        return new Date(raw);
      },
      before: function before(raw) {
        return new Date(raw);
      }
    }),

    offerTimeRangePresets: _ember["default"].computed(function () {
      return _goodcityUtilsTimeRanges["default"];
    }).volatile(),

    /**
     * Saves the time range filter for offer search
     *
     * @param {String|Object} range A time range OR a preset name
     */
    setOfferTimeRange: function setOfferTimeRange(range) {
      if (_lodash["default"].isString(range)) {
        var preset = range;
        this.set("_offerTimeSettings", { preset: preset });
      } else {
        this.set("_offerTimeSettings", {
          preset: null,
          after: _lodash["default"].get(range, "after"),
          before: _lodash["default"].get(range, "before")
        });
      }

      this.notifyPropertyChange("offerTimeRange");
    },

    /**
     * Returns the offer time range filter
     * If a preset was previously selected, it will be re-computed based
     * on the current time.
     *
     * @param {String|Object} range A time range OR a preset name
     */
    offerTimeRange: _ember["default"].computed(function () {
      var _get = this.get("_offerTimeSettings");

      var _get$preset = _get.preset;
      var preset = _get$preset === undefined ? "" : _get$preset;
      var _get$after = _get.after;
      var after = _get$after === undefined ? null : _get$after;
      var _get$before = _get.before;
      var before = _get$before === undefined ? null : _get$before;

      if (preset) {
        return _lodash["default"].extend({ preset: preset }, this.get("offerTimeRangePresets." + preset));
      }
      return { preset: preset, after: after, before: before };
    }).volatile()
  });
});