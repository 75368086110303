define("goodcity/controllers/offer/search_companies", ["exports", "ember", "lodash", "goodcity/utils/ajax-promise"], function (exports, _ember, _lodash, _goodcityUtilsAjaxPromise) {
  exports["default"] = _ember["default"].Controller.extend({
    displayResults: false,
    minSearchTextLength: 3,

    hasSearchText: _ember["default"].computed("searchText", function () {
      return _ember["default"].$.trim(this.get("searchText")).length;
    }),

    onSearchTextChange: _ember["default"].observer("searchText", function () {
      // wait before applying the filter
      _ember["default"].run.debounce(this, this.reloadResults, 500);
    }),

    reloadResults: function reloadResults() {
      this.hideResults();
      _ember["default"].run.debounce(this, this.showResults, 500);
    },

    showResults: function showResults() {
      var _this = this;

      _ember["default"].run(function () {
        _this.set("displayResults", true);
      });
    },

    getSearchQuery: function getSearchQuery() {
      return {
        searchText: this.get("searchText")
      };
    },

    getPaginationQuery: function getPaginationQuery(pageNo) {
      return {
        per_page: 25,
        page: pageNo
      };
    },

    trimQuery: function trimQuery(query) {
      // Remove any undefined values
      return _lodash["default"].pickBy(query, _lodash["default"].identity);
    },

    hideResults: function hideResults() {
      var _this2 = this;

      _ember["default"].run(function () {
        _this2.set("displayResults", false);
      });
    },

    actions: {
      clearSearch: function clearSearch() {
        this.set("searchText", "");
      },

      cancelSearch: function cancelSearch() {
        this.transitionToRoute("review_offer.donor_details", this.get("model.id"));
      },

      loadMoreCompanies: function loadMoreCompanies(pageNo) {
        var params = this.trimQuery(_lodash["default"].merge({}, this.getSearchQuery(), this.getPaginationQuery(pageNo)));
        if (this.get("searchText").length > this.get("minSearchTextLength")) {
          return this.store.query("company", params);
        }
        this.hideResults();
      },

      goToDetails: function goToDetails(companyId) {
        var _this3 = this;

        var offerId = this.get("model.id");
        var offerParams = {
          company_id: companyId
        };
        new _goodcityUtilsAjaxPromise["default"]("/offers/" + offerId, "PUT", this.get("session.authToken"), {
          offer: offerParams
        }).then(function (data) {
          _this3.store.pushPayload(data);
          _this3.transitionToRoute("review_offer.donor_details", offerId);
        });
      }
    }
  });
});