define("goodcity/routes/offer", ["exports", "goodcity/routes/authorize", "ember"], function (exports, _goodcityRoutesAuthorize, _ember) {
  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    offerService: _ember["default"].inject.service(),

    model: function model(params) {
      if (params.offer_id) {
        return this.get("offerService").fetchOffer(params.offer_id, {
          include_organisations_users: "true",
          exclude_messages: "true"
        });
      }
    },
    setupController: function setupController(controller, model) {
      controller.set("model", this.modelFor("offer"));
    }
  });
});