define("goodcity/components/full-name-input", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].TextField.extend({
    tagName: "input",
    type: "text",
    name: "userName",
    attributeBindings: ["name", "type", "id", "value", 'required', 'pattern'],

    i18n: _ember["default"].inject.service(),

    didInsertElement: function didInsertElement() {
      var user = this.attrs.user.value;
      var translatedName = this.get("i18n").t("full_name", { firstName: user.get('firstName'), lastName: user.get('lastName') });
      this.set('value', translatedName);
    }
  });
});